function initClientLogoUploader() {
  if (document.getElementById('client-logo-field')) {
    const inpFile = document.getElementById('client-logo-field');
    const img = document.getElementById('client-logo-img');
    const previewContainer = document.getElementById('client-logo-preview-container');
    const logo = document.getElementById('client-logo');

    inpFile.addEventListener('change', function() {
      const file = this.files[0];
      if (file) {
        previewContainer.classList.remove('d-none');
        img.classList.remove('d-none');
        img.src = URL.createObjectURL(file);
        logo.classList.add('d-none');
      }
    })
  }
}

window.addEventListener('turbolinks:load', () => {
  initClientLogoUploader();
})
