import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default (props) => {
  const doneClass = props.done ? 'done' : '';

  return (
    <div className="item">
      {props.done ? (
        <div className={`item-pointer ${doneClass}`}>
          <FontAwesomeIcon icon={['fas', 'check']}/>
        </div>
      ) : (
        <div className={`item-pointer ${doneClass}`}>
          <span>{props.number}</span>
        </div>
      )}
      <div className="item-label">{props.label}</div>
    </div>
  );
};
