import { makeObservable, observable, action } from 'mobx';

export class YearsSelectionStore {
  availableYears = YearsSelectionStore.generateRange();
  selectedYear = this.availableYears[0];

  constructor() {
    makeObservable(this, {
      availableYears: observable,
      selectedYear: observable,
      selectYear: action.bound,
    });
  }

  selectYear(selectedYear) {
    this.selectedYear = this.availableYears.find((year) => year === selectedYear);
  }

  static generateRange() {
    const startYear = (new Date()).getFullYear();
    const endYear = 2017;

    return Array.from({ length: (startYear - endYear) + 1 }, (_, i) => endYear + i).reverse();
  }
}
