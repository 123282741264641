import React from 'react';
import TreeSelect from '../tree-select.jsx';
import Chart from '../chart.jsx';
import EditSteps from '../chart-edit/edit-steps.jsx';
import TopPanel from '../chart-edit/top-panel.jsx';
import BottomPanel from '../chart-edit/bottom-panel.jsx';
import ViewTypesModal from '../chart-edit/view-types-modal.jsx';
import { inject, observer } from 'mobx-react';
import { Redirect } from 'react-router-dom';

@inject('chartsStore')
@observer
export default class ChartEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nameInvalid: false,
      redirect: false,
    };
  }

  componentDidMount() {
    if (this.props.match.params.id === undefined) {
      this.props.chartsStore.createNewChart();
    } else {
      this.props.chartsStore.loadChart(this.props.chartsStore.client.id, this.props.match.params.id);
    }
  }

  validateForm = () => {
    if (!this.props.chartsStore.isLastEditStep) {
      return true;
    }

    if (this.props.chartsStore.chartObject.name === '') {
      this.setState({ nameInvalid: true });

      return false;
    }
    this.setState({ nameInvalid: false });

    return true;
  };

  redirect = () => {
    this.setState({ redirect: true });
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.props.chartsStore.mainUrl} />;
    }

    if (this.props.chartsStore.chartObject === null) {
      return (
        <div>Loading...</div>
      );
    }

    return (
      <>
        <div className="row">
          <h1>Add new chart</h1>
        </div>
        <div className="row">
          <div className="col-4">
            <TreeSelect/>
          </div>
          <div className="col-8">
            <div className="row">
              <div className="col-12">
                <EditSteps/>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <TopPanel nameInvalid={this.state.nameInvalid}/>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Chart/>
              </div>
            </div>
            <div className="row my-3">
              <div className="col-12">
                <BottomPanel validateForm={this.validateForm} redirect={this.redirect}/>
              </div>
            </div>
          </div>
        </div>
        <ViewTypesModal/>
      </>
    );
  }
}
