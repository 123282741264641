import { reportsAccountGroupsPath } from 'helpers/routes.js.erb';

// TODO: Check using selectize and refactoring.
function AccountsGroupMultiselect () {
  $('#reports_vendor_account_groups_ids').selectize({
    valueField: 'id',
    labelField: 'name',
    searchField: 'name',
    options: [],
    plugins: ['remove_button'],
    create: false,
    delimiter: ',',
    maxItems: null,
    highlight: false,
    render: {
      option(item, escape) {
        return `<div class="container-fluid">
                  <div class="row">
                    <div class="col-sm">
                      ${escape(item.name)}
                    </div>
                  </div>
                </div>`;
      },
    },
    load(query, callback) {
      if (!query.length) return callback();

      // TODO: Use Axios and process promise.
      return $.ajax({
        url: reportsAccountGroupsPath({ format: 'json' }),
        type: 'GET',
        data: { query },
        error() { callback(); },
        success(res) { callback(res); },
      });
    },
  });
}

export default AccountsGroupMultiselect;
