import React from 'react';
import ReactDiffViewer, { DiffMethod } from 'react-diff-viewer';
import Axios from 'axios';

import { inject, observer } from 'mobx-react';

import { Modal } from 'components/ui-kit/modal/modal';
import { ModalHeader } from 'components/ui-kit/modal/modal-header';
import { structureJsonToText } from 'common/charts-helper';
import { dataDiffAPIV1ClientChartPath } from 'helpers/routes.js.erb';

@inject('chartsStore')
@observer
export default class DiffModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      leftText: '',
      rightText: '',
    };
  }

  componentDidMount() {
    this.loadData(); // TODO: Set state when promice was resolved.
  }

  loadData = async () => {
    const { chartsStore } = this.props;

    const url = dataDiffAPIV1ClientChartPath(
      chartsStore.client.id,
      chartsStore.chartObject.id,
    );

    const response = await Axios.get(url);

    // TODO: Use https://www.npmjs.com/package/humps for camelize responce.
    const leftText = structureJsonToText(response.data.current_structure);
    const rightText = structureJsonToText(response.data.old_structure);

    this.setState({ leftText, rightText });
  };

  render() {
    const { leftText, rightText } = this.state;

    const customStyle = {
      titleBlock: {
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: '1.3rem',
      },
    };

    const modalBody = (
      <ReactDiffViewer
        oldValue={leftText}
        newValue={rightText}
        splitView={true}
        hideLineNumbers={true}
        showDiffOnly={true}
        compareMethod={DiffMethod.LINES}
        leftTitle="Old structure"
        rightTitle="New structure"
        styles={customStyle}
      />
    );

    return (
      <Modal
        modalId={this.props.modalId}
        large={true}
        header={<ModalHeader title="QBO structure difference" showCloseButton={true}/>}
        body={modalBody}
      />
    );
  }
}
