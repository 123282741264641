import { Form, Input, DatePicker, InputNumber, Button } from 'antd';
import React, { useEffect, useRef } from 'react';
import { TypeFields } from '../stores/schedule-types';
import moment from 'moment';

export const ScheduleForm = ({ scheduleType, onSubmit, item }) => {
  const formRef = useRef(null);
  const fields = scheduleType
    .fields
    .map((f) => TypeFields.find((tf) => tf.name === f))
    .filter((f) => !f.calculated);

  useEffect(() => {
    formRef?.current.resetFields();
  }, [item]);

  return (
    <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={onSubmit} ref={formRef}>
      {fields.map((field, index) => {
        let input = <></>;
        let initialValue = item ? item[field.name] : null;

        switch (field.type) {
          case 'text':
            input = <Input/>;
            break;
          case 'money':
            input = <InputNumber
              formatter={(v) => `$ ${v}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(v) => v.replace(/\$\s?|(,*)/g, '')}
            />;
            break;
          case 'date':
            input = <DatePicker/>;
            initialValue = initialValue == null ? null : moment(initialValue);
            break;
          case 'number':
            input = <InputNumber/>;
            break;
          default:
            throw new Error(`Unknown field type: ${field.type}`);
        }

        return (
           <Form.Item label={field.title} name={field.name} key={index} initialValue={initialValue}>
             { input }
           </Form.Item>
        );
      })
      }

      <Form.Item>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};
