import { action, computed, makeObservable, observable } from 'mobx';
import axios from 'axios';
import moment from 'moment';
import { apiV1RAndDReportsPath } from 'helpers/routes.js.erb';
import consumer from '../../../action-cable/consumer';

export class RAndDReportsStore {
  isLoading = false;
  isGenerationInProgress = true;
  reports = [];
  date = moment().utc().startOf('year').subtract(1, 'year');
  wsSubscription = null;
  userRoles = null;

  constructor(notificationStore, userRoles) {
    this.notificationStore = notificationStore;
    this.userRoles = userRoles;

    makeObservable(this, {
      reports: observable,
      date: observable,
      isLoading: observable,
      wsSubscription: observable,
      isGenerationInProgress: observable,
      load: action.bound,
      setReports: action,
      setDate: action,
      generate: action.bound,
      toggleLoading: action,
      initWsSubscription: action,
      destroyWsSubscription: action.bound,
      appendReport: action,
      toggleGenerationStatus: action,
      isAllowedToGenerate: computed,
    });
  }

  async load() {
    this.toggleLoading();

    const { data } = await axios.get(apiV1RAndDReportsPath());

    this.setReports(data);
    this.initWsSubscription();
    this.toggleGenerationStatus();
    this.toggleLoading();
  }

  initWsSubscription() {
    const ctx = this;

    this.wsSubscription = consumer.subscriptions.create({
      channel: 'RAndDReportStatusChannel',
    }, {
      received(report) {
        ctx.appendReport(report);
      },
    });
  }

  destroyWsSubscription() {
    consumer.subscriptions.remove(this.wsSubscription);
  }

  async generate() {
    const { data } = await axios.post(apiV1RAndDReportsPath(), {
      report: {
        year: this.date.year(),
      },
    });

    this.notificationStore.create({
      header: 'Success',
      message: 'Report queued for generation!',
      type: 'success',
    });

    this.appendReport(data);
  }

  setReports(reports) {
    this.reports = reports;
  }

  setDate(date) {
    this.date = date;
  }

  toggleGenerationStatus() {
    const [lastReport] = this.reports;

    this.isGenerationInProgress = (lastReport?.status === 'In Progress' || lastReport?.status === 'Pending');
  }

  appendReport(report) {
    const existingReport = this.reports.find((r) => r.id === report.id);

    if (existingReport) {
      Object.assign(existingReport, report);
    } else {
      this.reports = [report, ...this.reports];
    }

    this.toggleGenerationStatus();
  }

  toggleLoading() {
    this.isLoading = !this.isLoading;
  }

  get isAllowedToGenerate() {
    return this.date?.isValid() && this.isGenerationInProgress === false && this.isLoading === false;
  }
}
