import axios from 'axios';
import Chart from 'chart.js';

import { ChartColorGenerator } from './chart-color-generator';
import { chartDataAPIV1ClientChartsPath } from 'helpers/routes.js.erb';

Chart.defaults.global.defaultFontFamily = "'Lato', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif";

const THOUSANDS_REGEX = /\B(?=(\d{3})+(?!\d))/g;

export async function loadChartData(url, dateRange) {
  const urlObj = new URL(url);

  urlObj.searchParams.append('start_date', dateRange.startDate);
  urlObj.searchParams.append('end_date', dateRange.endDate);

  const requestResult = await axios.get(urlObj.href);

  return requestResult.data;
}

Chart.elements.Rectangle.prototype.draw = function () {
  const { ctx } = this._chart;
  const vm = this._view;

  let left; let right; let top; let bottom;

  const { borderWidth } = vm;

  let { cornerRadius } = this._chart.config.options;

  if (!cornerRadius) {
    cornerRadius = 0;
  }

  left = vm.x - vm.width / 2;
  right = vm.x + vm.width / 2;
  top = vm.y;
  bottom = vm.base;

  ctx.beginPath();
  ctx.fillStyle = vm.backgroundColor;
  ctx.strokeStyle = vm.borderColor;
  ctx.lineWidth = borderWidth;

  ctx.moveTo(left, bottom);

  const width = right - left;
  const height = bottom - top;
  const x = left;
  const y = top;

  if (height === 0) {
    return;
  }

  let radius = cornerRadius;
  const yScale = this._chart.scales['y-axis-0'];
  const zeroLine = yScale._gridLineItems[yScale.zeroLineIndex];

  let realHeight = 0;

  if (height > 0) {
    realHeight = zeroLine.y1 - y;
  } else {
    realHeight = y - zeroLine.y1;
  }

  if (radius >= width / 2) {
    radius = width / 2;
  }

  if (radius > realHeight) {
    radius = Math.abs(height);
  }

  if (height < 0) {
    // Negative values
    ctx.moveTo(x + radius, y);
    ctx.lineTo(x + width - radius, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y - radius);
    ctx.lineTo(x + width, zeroLine.y1);
    ctx.lineTo(x, zeroLine.y1);
    ctx.lineTo(x, y - radius);
    ctx.quadraticCurveTo(x, y, x + radius, y);
  } else {
    // Positive Value
    ctx.moveTo(x + radius, y);
    ctx.lineTo(x + width - radius, y);
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
    ctx.lineTo(x + width, zeroLine.y1);
    ctx.lineTo(x, zeroLine.y1);
    ctx.lineTo(x, y + radius);
    ctx.quadraticCurveTo(x, y, x + radius, y);
  }

  ctx.fill();
};

export function createChartJs(canvasContext, options, data) {
  return new Chart(canvasContext, {
    type: 'bar',
    data: {
      labels: data.months,
      datasets: data.datasets,
    },
    options: {
      animation: false,
      devicePixelRatio: 4,
      scales: {
        yAxes: [{
          ticks: {
            callback(value) {
              return `$${value.toString().replace(THOUSANDS_REGEX, ',')}`;
            },
            suggestedMin: 0,
            maxTicksLimit: 7,
          },
          stacked: options.stacked,
          gridLines: {
            drawOnChartArea: true,
            color: '#E2E2E2',
            borderDash: [10],
            zeroLineWidth: 2,
            zeroLineColor: '#E2E2E2',
          },
        }],
        xAxes: [
          {
            gridLines: {
              display: false,
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          label(tooltipItem, data) {
            const datasetLabel = data.datasets[tooltipItem.datasetIndex].label;

            return `${datasetLabel} $${parseInt(tooltipItem.value).toFixed(2)}`;
          },
        },
      },
      cornerRadius: 8,
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
  });
}

export async function createChartJsWithData(chartObject, clientId, canvasContext, startDate = null, endDate = null) {
  const dateRange = {
    startDate: startDate || halfYearAgoDate(),
    endDate: endDate || previousMonth(),
  };

  const data = await generateChartData(chartObject, dateRange, clientId);

  return {
    chart: createChartJs(canvasContext, chartObject.options, data),
    data,
  };
}

export function generateRgbaColors(color) {
  return {
    bg: `rgba(${color}, 1)`,
  };
}

export function createDataSet(dataPath, data, color, isStacked, type) {
  const colors = generateRgbaColors(color);

  const pathArray = dataPath.split('/');

  return {
    label: pathArray[pathArray.length - 1],
    data: data.map((d) => d.value),
    backgroundColor: colors.bg,
    borderColor: colors.bg,
    borderWidth: 4,
    fill: false,
    type,
    stack: (isStacked ? 1 : undefined),
  };
}

export function yearAgoDate() {
  const date = new Date();
  const beginningOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
  beginningOfMonth.setFullYear(date.getFullYear() - 1);

  return beginningOfMonth;
}

export function halfYearAgoDate() {
  const date = new Date();
  const beginningOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);

  addMonths(beginningOfMonth, -6);

  return beginningOfMonth;
}

export function previousMonth() {
  const date = new Date();
  const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);

  addMonths(endOfMonth, -1);

  return endOfMonth;
}

function addMonths(date, months) {
  date.setMonth(date.getMonth() + months);

  return date;
}

export function structureJsonToText(jsonObj) {
  let text = '';

  for (const el of jsonObj) {
    text += structureNodeToText(el, 0);
  }

  return text;
}

function structureNodeToText(node, level) {
  let childText = '';

  if (node.children) {
    for (const ch of node.children) {
      childText += structureNodeToText(ch, level + 1);
    }
  }

  let result = '• '.repeat(level);
  result += `${node.label}\n`;
  result += childText;

  return result;
}

export function notificationColor(level) {
  switch (level) {
    case 'info':
      return 'info';
    case 'critical':
      return 'danger';
    case 'warning':
      return 'warning';
    default:
      return 'primary';
  }
}

export async function generateChartData(chartObject, dateRange, clientId) {
  const data = {
    months: [],
    datasets: [],
  };

  const colorsGenerator = new ChartColorGenerator();

  for (const dataType of chartObject.selected_categories) {
    const params = {
      ...dateRange,
      path: dataType,
    };
    const path = chartDataAPIV1ClientChartsPath(clientId, params);
    const requestResult = await axios.get(path);

    const newData = requestResult.data;
    data.datasets.push(
      createDataSet(
        dataType,
        newData,
        colorsGenerator.getNextColor(),
        chartObject.options.stacked,
        chartObject.options.dataViewTypes[dataType],
      ),
    );

    if (newData.length > 0) {
      data.months = newData.map((d) => d.date);
    }
  }

  return data;
}
