import SuggestionsMultiselect from "../shared/suggestions_multiselect";
import AccountsGroupMultiselect from "./accounts_group_multiselect";
import { clientSearchClientsPath } from 'helpers/routes.js.erb';

// TODO: REWORK!!!

window.addEventListener('turbolinks:before-cache', function() {
  $('[data-selectize]').each(function() {
    this.selectize.destroy()
  });
});

document.addEventListener("turbolinks:load", function() {
  $("form.data-async-account-group[data-remote]").on("ajax:success", hideGroupsModal);
});

function hideGroupsModal() {
  $('#accountGroupsModal').modal('hide');
}

function vendorKeywordSuggestion(query) {
  return('/general_ledger/suggestions?query=' + query + '&field=vendor.words_only_suggestion')
}

function vendorSuggestion(query) {
  return('/general_ledger/suggestions?query=' + query + '&field=vendor.suggestion')
}

function descriptionSuggestion(query) {
  return('/general_ledger/suggestions?query=' + query + '&field=description.words_only_suggestion')
}

function accountSuggestion(query) {
  return('/general_ledger/suggestions?query=' + query + '&field=account.words_only_suggestion')
}

function transactionTypeSuggestion(query) {
  return('/general_ledger/suggestions?query=' + query + '&field=transaction_type.suggestion')
}

function clientNameSuggestion(query) {
  return('/clients/client_search?query=' + query)
}

window.addEventListener('turbolinks:load', function() {
  if($('#reports_vendor_keywords').length > 0 ) {
    new SuggestionsMultiselect('#reports_vendor_keywords', vendorKeywordSuggestion, true);
  }

  if($('#reports_vendor_client_ids').length > 0) {
    $('#reports_vendor_client_ids').selectize({
      create: false,
      valueField: 'id',
      labelField: 'name',
      searchField: 'name',
      placeholder: 'Search client by name',
      options: [],
      plugins: ['remove_button'],
      delimiter: ',',
      maxItems: null,
      highlight: false,
      render: {
        option(item, escape) {
          return (
            `<div class='row'>
                <div class='col-sm'>
                  ${escape(item.name)}
                </div>
            </div>`
          );
        },
      },
      load(query, callback) {
        if (!query.length) return callback();
        $.ajax({
          url: clientSearchClientsPath({ query }),
          type: 'GET',
          error() {
            callback();
          },
          success(res) {
            callback(res);
          },
        });
      },
    });
  }

  if($('#reports_vendor_names').length > 0 ) {
    new SuggestionsMultiselect('#reports_vendor_names', vendorSuggestion);
  }

  if($('#reports_vendor_descriptions').length > 0) {
    new SuggestionsMultiselect('#reports_vendor_descriptions', descriptionSuggestion, true);
  }

  if($('#reports_vendor_descriptions_to_exclude').length > 0) {
    new SuggestionsMultiselect('#reports_vendor_descriptions_to_exclude', descriptionSuggestion, true);
  }

  if($('#reports_vendor_accounts').length > 0) {
    new SuggestionsMultiselect('#reports_vendor_accounts', accountSuggestion, true);
  }

  if($('#reports_vendor_accounts_to_exclude').length > 0) {
    new SuggestionsMultiselect('#reports_vendor_accounts_to_exclude', accountSuggestion, true);
  }

  if($('#reports_vendor_transaction_types').length > 0) {
    new SuggestionsMultiselect('#reports_vendor_transaction_types', transactionTypeSuggestion);
  }

  if($('#reports_account_group_accounts').length > 0) {
    new SuggestionsMultiselect('#reports_account_group_accounts', accountSuggestion, true);
  }


  if($('#reports_vendor_account_groups_ids').length > 0) {
    new AccountsGroupMultiselect($('#reports_vendor_account_groups_ids'));
  }


  $('.vendors-datepicker').flatpickr({
    minDate: new Date("January 1, 2000"),
    dateFormat: "Y-m-d"
  });

  $('#vendors-report-form').on('keypress keyup', function (e) {
    var keyCode = e.keyCode || e.which;

    if (keyCode === 13) {
      e.preventDefault();
      return false;
    }
  });

  $('[data-toggle="tooltip"]').tooltip();
});
