import React from 'react';
import { Button, Space, Table } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const MappingsTable = ({ items, onEdit, onDelete }) => {
  const columns = [
    {
      title: 'Account name',
      dataIndex: 'accountName',
      key: 'accountName',
    },
    {
      title: 'Schedule type',
      dataIndex: 'scheduleType',
      key: 'scheduleType',
    },
    {
      title: 'Actions',
      width: 150,
      render: (_, item) => <Space>
        <Button icon={<FontAwesomeIcon icon={['far', 'edit']}/>} onClick={() => onEdit(item)}/>
        <Button icon={<FontAwesomeIcon icon={['far', 'trash-alt']}/>} onClick={() => onDelete(item)}/>
      </Space>,
    },
  ];

  return (
    <Table columns={columns} dataSource={items}/>
  );
};
