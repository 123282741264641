import consumer from 'action-cable/consumer';
import LoaderImage from './images/ajax-loader.gif';
import { DropZoneActiveStorage } from './common/drop-zone/drop-zone-active-storage';

const generateText = (text, loader, className) => {
  const loaderImageTag = `<img src=${LoaderImage} alt="Loader"/>`;
  const innerText = loader ? `${text} ${loaderImageTag}` : text;

  return `<p id=${className}>${innerText}</p>`;
};

const displayMessage = (text, loader, className) => {
  const element = document.getElementById(className);

  element.outerHTML = generateText(text, loader, className);
};

const receiveImportReviewers = (data) => {
  let message;

  switch (data.status) {
    case 'error':
      message = 'The reviewers import failed. Please refresh the page and try again.';
      break;
    case 'in_progress':
      message = 'Importing Reviewers';
      break;
    case 'done':
      message = 'Reviewers imported successfully';
      break;
    case 'invalid_client_id':
      message = `Couldn't find client with id: ${data.id}. Please check file, and try again.`;
      break;
    case 'invalid_user_email':
      message = `Couldn't find user with email: ${data.email}. Please check file, and try again.`;
      break;
    default:
      message = 'Something wrong, please try again';
  }

  displayMessage(message, data.status === 'in_progress', 'reviewers_import_status');
};

const receiveImportVcFirms = (data) => {
  console.log(data);

  let message;

  switch (data.status) {
    case 'error':
      message = 'The import failed. Please refresh the page and try again.';
      break;
    case 'in_progress':
      message = 'Importing VC Firms';
      break;
    case 'done':
      message = 'VC Firms imported successfully';
      break;
    default:
      message = 'Something wrong, please try again';
  }

  displayMessage(message, data.status === 'in_progress', 'vc_firms_import_status');
};

const initReviewersStatusTracking = () => {
  consumer.subscriptions.create({
    channel: 'ReviewersImportStatusChannel',
  },
  {
    received: (data) => receiveImportReviewers(data),
  });
};

const initVcFirmsStatusTracking = () => {
  consumer.subscriptions.create({
    channel: 'VcFirmsExportStatusChannel',
  },
  {
    received: (data) => receiveImportVcFirms(data),
  });
};

function initReviewersUploader(wrapper) {
  const maxFiles = 1;
  const acceptedFiles = '.csv, application/csv';

  if (document.getElementById(wrapper)) {
    new DropZoneActiveStorage(wrapper, maxFiles, acceptedFiles).init();
  }
}

function initVcFirmsUploader(wrapper) {
  const maxFiles = 1;
  const acceptedFiles = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

  if (document.getElementById(wrapper)) {
    new DropZoneActiveStorage(wrapper, maxFiles, acceptedFiles).init();
  }
}

window.addEventListener('turbolinks:load', () => {
  initReviewersUploader('csv-file-uploader');
  initVcFirmsUploader('vc-excel-uploader');
  initReviewersStatusTracking();
  initVcFirmsStatusTracking();

  $('.vc-firm-select').selectize({
    delimiter: ',',
    persist: false,
    create(input) {
      return {
        value: input,
        text: input,
      };
    },
  });

  $('.vc-firm-round-select').selectize();
});
