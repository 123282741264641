import React from 'react';
import CheckboxTree from 'react-checkbox-tree';
import Axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { inject, observer } from 'mobx-react';
import { chartTypesAPIV1ClientChartsPath } from 'helpers/routes.js.erb';

@inject('chartsStore')
@observer
export default class TreeSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: ['Profit and Loss', 'Balance Sheet'],
      nodes: [],
    };

    this.icons = {
      check: <FontAwesomeIcon className="rct-icon rct-icon-check" icon={['far', 'check-square']} />,
      uncheck: <FontAwesomeIcon className="rct-icon rct-icon-uncheck" icon={['far', 'square']} />,
      expandClose: <FontAwesomeIcon className="rct-icon rct-icon-expand-close" icon="chevron-down" />,
      expandOpen: <FontAwesomeIcon className="rct-icon rct-icon-expand-open" icon="chevron-up" />,
      parentClose: <FontAwesomeIcon className="rct-icon rct-icon-parent-close" icon="folder" />,
      parentOpen: <FontAwesomeIcon className="rct-icon rct-icon-parent-open" icon="folder-open" />,
    };
  }

  async componentDidMount() {
    const { chartsStore } = this.props;
    const path = chartTypesAPIV1ClientChartsPath(chartsStore.client.id);
    const response = await Axios.get(path);

    this.setState({ nodes: response.data });
  }

  onCheck = (checked) => {
    this.props.chartsStore.chartObject.selected_categories = checked;
  };

  onExpand = (expanded) => {
    this.setState({ expanded });
  };

  render() {
    return (
      <div className="category-tree">
        <CheckboxTree
          disabled={this.props.chartsStore.editStep > 0}
          expandDisabled={this.props.chartsStore.editStep > 0}
          checked={this.props.chartsStore.chartObject.selected_categories}
          expanded={this.state.expanded}
          icons={this.icons}
          nodes={this.state.nodes}
          onCheck={this.onCheck}
          onExpand={this.onExpand}
          noCascade={true}
          showNodeIcon={false}
        />
      </div>
    );
  }
}
