import React from 'react';

const FieldToggle = (props) => {
  const isTrueSet = (props.value === 'true' || props.value === 'Yes' || props.value === true);

  return (
    <div
      className={`pb-2 d-flex flex-column ${props.className ? props.className : ''}`}
      data-test-form-field={props.name}
    >
      <span className="text bold pb-1" data-test-form-field-name>{props.name}</span>
      <div className="custom-control custom-switch">
        <input readOnly type="checkbox"
          className="custom-control-input"
          id={`customSwitch-${props.columnName}`}
          data-test-input="checkbox"
          name={props.columnName} checked={isTrueSet} />
        <label className="custom-control-label"
          htmlFor={`customSwitch-${props.columnName}`}
          onClick={props.handleChange}>
          No / Yes
        </label>
      </div>
    </div>
  );
};

export default FieldToggle;
