import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { VendorsTotalStore } from '../stores/vendors-total.store';
import { Button, Table } from 'antd';
import { toJS } from 'mobx';
import { Redirect } from 'react-router-dom';
import { Notification } from '../../ui-kit/notification';

const { Column } = Table;

export const VendorsTotal = observer(({ clientId, selectedYear, notificationStore, navigationStore }) => {
  const [store] = useState(() => new VendorsTotalStore(clientId, notificationStore, navigationStore));

  useEffect(() => {
    async function init() {
      store.setYear(selectedYear);
      await store.loadVendors();
    }

    init();
    store.expandedRowKeys = [];
  }, [selectedYear]);

  if (store.redirectUrl) {
    const { redirectUrl } = store;

    return <Redirect to={redirectUrl}/>;
  }

  const rowSelection = {
    selectedRowKeys: store.selectedRowToUpdate,
    onChange: (selectedRowKey) => {
      store.selectTransaction(selectedRowKey);
    },
  };

  const expandedTable = (vendor) => {
    const columns = [
      { title: 'Date', dataIndex: 'date', className: 'test-rnd-vendor-trx-date' },
      { title: 'Account', dataIndex: 'account', className: 'test-rnd-vendor-trx-account' },
      { title: 'Type', dataIndex: 'type', className: 'test-rnd-vendor-trx-type' },
      { title: 'Description', dataIndex: 'description', className: 'test-rnd-vendor-trx-description' },
      { title: 'Amount', dataIndex: 'amount', className: 'test-rnd-vendor-trx-amount' },
    ];

    const data = [];
    const transactions = store.transactions[vendor];

    if (transactions) {
      transactions.forEach((el) => {
        const { date, account, type, description, amount, md5_hash } = el;
        data.push({ key: md5_hash, date, account, type, description, amount });
      });
    }

    return <Table
      loading={store.loading}
      rowSelection={rowSelection}
      columns={columns}
      dataSource={data}
      pagination={false}
      size='small'
      bordered
      rowClassName='test-rnd-vendor-transactions'
      footer={() => <Button
        disabled={
          (store.selectedRowToUpdate.length === 0
            || (store.selectedRowToUpdate.length !== 0
              && !store.selectedRowToUpdate.every((md5Hash) => (store.transactions[vendor]
                ? store.transactions[vendor].some((row) => row.md5_hash === md5Hash)
                : false))
            )
          )
        }
        type="primary"
        className='test-rnd-fast-create-tab-btn'
        onClick={() => store.fastTabCreate(vendor)}
      >
        Create Tab
      </Button>}
    />;
  };

  return (
    <>
      <Notification {...notificationStore.data} onClose={notificationStore.cleanup}/>
      <Table
        rowKey={(record) => record.name}
        sticky
        loading={store.loading}
        pagination={false}
        dataSource={toJS(store.vendors)}
        expandedRowKeys={store.expandedRowKeys}
        expandable={{
          onExpand: (expended, record) => store.loadTransactions(record.name, expended),
          expandedRowRender: (record) => expandedTable(record.name),
        }}
        rowClassName='test-rnd-vendor'
      >
        <Column className='test-rnd-vendor-name' title='Name' dataIndex='name'/>
        <Column
          className='test-rnd-vendor-total'
          title='Total'
          render={(total) => `$${total}`}
          dataIndex='total'
        />
      </Table>
    </>
  );
});
