import consumer from '../action-cable/consumer';

class MdAndASchedules {
  constructor(mdAndAId) {
    this.mdAndAId = mdAndAId;
  }

  init = () => {
    consumer.subscriptions.create({
      channel: 'MdAndAScheduleStatusChannel',
      md_and_a_id: this.mdAndAId,
    },
    {
      received: function (data) {
        const mdAndASchedule = JSON.parse(data.object);

        if (mdAndASchedule.status !== '' || mdAndASchedule.status !== 'txs_sync_in_progress') {
          return window.location.reload();
        }

        const wrapper = $(`[data-schedule-id=${mdAndASchedule.id}]`);

        if (wrapper.length) {
          const status = wrapper.find('[data-schedule-status]');
          $('.spinner').removeClass('hidden');

          status.html(mdAndASchedule.status_message);
        }
      },
    });
  };
}

window.addEventListener('turbolinks:load', function () {
  const mdAndAId = $('#schedule_md_and_a_id');

  if (mdAndAId.length) {
    const statusTracking = new MdAndASchedules(mdAndAId.val());
    statusTracking.init();
  }
});
