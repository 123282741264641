import React from 'react';
import { inject } from 'mobx-react';
import ChartPreview from '../chart-preview.jsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import NotificationTooltip from '../notification-tooltip.jsx';

@inject('chartsStore')
export default class Cards extends React.Component {
  constructor(props) {
    super(props);
  }

  editButton = (chart, mainLocation, userCanUpdate) => {
    if (userCanUpdate) {
      return (
        <>
          <div className="col-auto">
            <button type="button" className="btn btn-primary">
              <FontAwesomeIcon icon={['fas', 'pencil-alt']} className="mr-2"/>
              <Link to={`${mainLocation}/${chart.id}/edit`}>Edit</Link>
            </button>
          </div>
        </>
      )
    }
  }

  deleteButton = (chart, userCanDelete) => {
    if (userCanDelete) {
      return (
        <>
           <div className="col-auto">
            <button type="button" className="btn btn-danger" onClick={() => this.props.deleteChart(chart)}>
              <FontAwesomeIcon icon={['fas', 'trash-alt']} className="mr-2"/>
              Delete
            </button>
          </div>
        </>
      )
    }
  }

  editButtons = (chart, mainLocation, userCanUpdate, userCanDelete) => {
    return (
       <div className="row">
          {this.editButton(chart, mainLocation, userCanUpdate)}
          {this.deleteButton(chart, userCanDelete)}
      </div>
    )
  };

  render() {
    const mainLocation = this.props.chartsStore.mainUrl;
    const userRoles = this.props.roles;
    const userCanUpdate = userRoles['reporting']?.includes('update');
    const userCanDelete = userRoles['reporting']?.includes('delete');
    return (
      <div className="row">
        {this.props.charts.map((chart) => {
          const isDefault = chart.default_chart_id !== null;

          return (
            <div className="col-4 my-2" key={chart.id}>
              <div className="card shadow-default">
                <div className="card-body">
                  <div className="row justify-content-between link">
                    <div className="col-auto">
                      <Link to={`${mainLocation}/${chart.id}/`}>{chart.name}</Link>
                    </div>
                    {chart.active_notifications.length > 0
                      && <div className="col-auto">
                        <NotificationTooltip chart={chart}/>
                      </div>
                    }
                    {isDefault
                      && <div className="col-auto">
                        <span className="badge badge-secondary">Default chart</span>
                      </div>
                    }
                  </div>
                  <div className="row">
                    <ChartPreview chart={chart} showLegend={false} showName={false}/>
                  </div>
                  { !isDefault && this.editButtons(chart, mainLocation, userCanUpdate, userCanDelete) }
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );
  }
}
