import consumer from '../action-cable/consumer';

class MdAndAVarianceStatusTracking {
  constructor(clientId) {
    this.clientId = clientId
  }

  init = () => {
    consumer.subscriptions.create({
      channel: 'MdAndAVarianceAnalysisStatusChannel',
      client_id: this.clientId
    },
    {
      received: function (_) {
        window.location.reload();
      },
    });
  };

}

window.addEventListener('turbolinks:load', function () {
  const clientField = $('#md_and_a_variance_client_id');

  if (clientField.length) {
    const statusTracking = new MdAndAVarianceStatusTracking(clientField.val());
    statusTracking.init();
  }
});
