import React from 'react';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import 'flatpickr/dist/plugins/monthSelect/style.css';

export default class MonthPicker extends React.Component {
  constructor(props) {
    super(props);
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    this.inputRef.current.flatpickr({
      minDate: this.props.minDate,
      maxDate: this.props.maxDate,
      defaultDate: this.props.defaultDate,
      onChange: this.monthChangeHandler,
      plugins: [
        new monthSelectPlugin({
          dateFormat: 'M Y',
        }),
      ],
    });
  }

  componentWillUnmount() {
    this.inputRef.current.flatpickr().destroy();
  }

  monthChangeHandler = (selectedDates) => {
    this.props.onMonthChanged(selectedDates[0]);
  };

  render() {
    return (
      <input type="text" className={`form-control month-select-datapicker ${this.props.className}`} ref={this.inputRef}/>
    );
  }
}
