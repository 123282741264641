import 'styles/application';

import 'core-js/stable';
import 'regenerator-runtime';
import 'popper.js';
import 'bootstrap';
import 'selectize';
import '@fortawesome/fontawesome-free/js/all.min';
import 'select2';
import 'bootstrap-select';
import 'trix';
import '@rails/actiontext';
import 'flatpickr';

import toastr from 'toastr';

import 'account_analysis';
import 'axios-pending-requests-helper';
import 'xhr-pending-requests-helper';
import 'individual_client_statistic';
import 'packets';
import 'tax_papers';
import 'vendor1099_reviews';
import 'venture_debt_intro_emails';
import 'variance-analysis/pdf-status-tracking';
import 'variance-analysis/vendor-transactions';
import 'client-page/qbo-status-trackings';
import 'md_and_a/datepicker';
import 'md_and_a/version-status-tracking';
import 'md_and_a/md-and-a-variance-status-tracking';
import 'md_and_a/md-and-a-schedules';
import 'md_and_a/md-and-a-tiers';
import 'components/charts/charts-page';
import 'plaid/report-settings-duration';
import 'plaid/plaid_link';
import 'plaid/plaid_items';
import 'common/copy-text';
import 'reports/accounts_group_multiselect';
import 'reports/vendors';
import 'shared/suggestions_multiselect';
import 'components/financial-model/financial-model';
import 'financial-model/financial-model-results';
import 'common/date-time-pickers';
import 'kruze-keeper/rule-keeper';
import 'kruze-keeper/rule-keeper-status-tracker';
import 'notes_and_questions/notes_and_questions';
import 'common/color-generator';
import 'notifications/notifications';
import 'financial_bundle';
import 'import_files';
import 'components/person-of-contacts/index';
import 'components/r-and-d/show';
import 'components/excel-packets/index';
import 'components/fee-increase-reports/index';
import 'components/shortcuts/index';
import 'client-page/logo-uploader';
import 'rewards';
import 'sentry_init.js.erb';
import 'client-page/clients.js';
import 'clients_report_export';
import 'users';
import 'components/schedule-items/index';
import 'components/schedule-account-mappings/index';
import 'components/tax-opt-out-info/edit';
import 'datepicker';
import 'components/mcp_budget/show';
import 'components/versions/index';
import 'components/graphql_subscriptions/index';
import 'components/r_and_d_reports/index';

require('jquery');

require('@rails/ujs').start();
require('@rails/activestorage').start();
require('turbolinks').start();

require.context('images', true);

window.jQuery = $;
window.$ = $;

$.fn.selectpicker.Constructor.BootstrapVersion = '4';

toastr.options = {
  closeButton: true,
  newestOnTop: true,
  progressBar: true,
  positionClass: 'toast-top-right',
  timeOut: '10000',
  extendedTimeOut: '20000',
};

const showAlerts = () => {
  $('.flash-alert').each((i, el) => {
    const { dataset } = el;
    const { severity } = dataset;
    const flashType = severity === 'alert' ? 'error' : 'info';

    toastr[flashType](dataset.message);
  });
};

window.addEventListener('turbolinks:load', () => {
  showAlerts();

  $('.selectpicker').selectpicker();
});

// Prevent attaching files to trix editor
window.addEventListener('trix-file-accept', (event) => {
  if (event.file) {
    event.preventDefault();
  }
});
