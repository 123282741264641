import { clientPath, richClientSearchClientsPath } from 'helpers/routes.js.erb';

window.addEventListener('turbolinks:load', () => {
  $('#client-search-autocomplete').selectize({
    create: false,
    valueField: 'id',
    labelField: 'name',
    searchField: ['name', 'contacts'],
    placeholder: 'Search client by name/FKA/DBA/LCN or contacts',
    options: [],
    onChange(value) {
      const url = clientPath(value);
      window.location.assign(url);
    },
    render: {
      option(item, escape) {
        return (
          `<div class='search-option' data-test-rich-client-search-option>
            <span class='rich-client-search-name' data-test-rich-client-search-name>${escape(item.name)}</span>
            <span class='rich-client-search-contacts' data-test-rich-client-search-contacts>${escape(item.contacts)}</span>
          </div>`
        );
      },
    },
    load(query, callback) {
      if (!query.length) return callback();
      $.ajax({
        url: richClientSearchClientsPath({ query }),
        type: 'GET',
        error() {
          callback();
        },
        success(res) {
          callback(res);
        },
      });
    },
  });

  if ($('.js-equity-management-platform').length > 0) {
    $('.js-equity-management-platform').select2({
      theme: 'bootstrap',
      tags: true,
      allowClear: true,
      placeholder: 'Select or enter new value',
    });
  }
});
