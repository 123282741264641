import { action, computed, makeObservable, observable } from 'mobx';
import axios from 'axios';
import moment from 'moment';
import { apiV1ClientExcelPacketsPath } from 'helpers/routes.js.erb';
import consumer from '../../../action-cable/consumer';

export class ExcelPacketsStore {
  isLoading = false;
  isGenerationInProgress = true;
  packets = [];
  startDate = moment().subtract(1, 'year').startOf('month');
  endDate = moment().subtract(1, 'month').endOf('month');
  order = null;
  wsSubscription = null;
  userRoles = null;

  constructor(clientId, notificationStore, userRoles, isQboOutdated) {
    this.clientId = clientId;
    this.notificationStore = notificationStore;
    this.userRoles = userRoles;
    this.isQboOutdated = isQboOutdated;

    makeObservable(this, {
      packets: observable,
      startDate: observable,
      endDate: observable,
      order: observable,
      isLoading: observable,
      wsSubscription: observable,
      isGenerationInProgress: observable,
      load: action,
      setPackets: action,
      setDates: action,
      setOrder: action,
      generate: action.bound,
      toggleLoading: action,
      initWsSubscription: action,
      destroyWsSubscription: action.bound,
      appendPacket: action,
      toggleGenerationStatus: action,
      isAllowedToGenerate: computed,
    });
  }

  async load() {
    this.toggleLoading();

    if (this.isQboOutdated) {
      this.notificationStore.create({
        header: 'QBO connection is outdated.',
        message: 'Please reconnect this client to be able to generate Excel packets.',
        type: 'error'
      });
    }

    const { data } = await axios.get(apiV1ClientExcelPacketsPath(this.clientId));

    this.setPackets(data);
    this.initWsSubscription();
    this.toggleGenerationStatus();
    this.toggleLoading();
  }

  initWsSubscription() {
    const ctx = this;

    this.wsSubscription = consumer.subscriptions.create({
      channel: 'ExcelPacketStatusChannel',
      client_id: this.clientId,
    }, {
      received(packet) {
        ctx.appendPacket(packet);
      },
    });
  }

  destroyWsSubscription() {
    consumer.subscriptions.remove(this.wsSubscription);
  }

  async generate() {
    const { data } = await axios.post(apiV1ClientExcelPacketsPath(this.clientId), {
      excel_packet: {
        start_date: this.startDate.format(),
        end_date: this.endDate.format(),
        alphabet_order_enabled: this.order,
      },
    });

    this.notificationStore.create({
      header: 'Success',
      message: 'Packet queued for generation!',
      type: 'success',
    });

    this.appendPacket(data);
  }

  setPackets(packets) {
    this.packets = packets;
  }

  toggleGenerationStatus() {
    const lastPacket = this.packets[0];

    this.isGenerationInProgress = (lastPacket?.status === 'In Progress' || lastPacket?.status === 'Pending');
  }

  appendPacket(packet) {
    const packetExists = this.packets.find((existingPacket) => existingPacket.id === packet.id);

    if (packetExists) {
      this.packets = this.packets.map((existingPacket) => {
        if (existingPacket.id === packet.id) {
          return packet;
        }

        return existingPacket;
      });
    } else {
      this.packets = [packet, ...this.packets];
    }

    this.toggleGenerationStatus();
  }

  setDates(dates) {
    const [startDate, endDate] = dates || [];

    this.startDate = startDate;
    this.endDate = endDate;
  }

  setOrder(event) {
    this.order = event.target.checked;
  }

  toggleLoading() {
    this.isLoading = !this.isLoading;
  }

  get isAllowedToGenerate() {
    return (this.startDate?.isValid() && this.endDate?.isValid())
      && this.isGenerationInProgress === false
      && this.isLoading === false;
  }
}
