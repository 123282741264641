import ReactDOM from 'react-dom';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { VersionsStore } from './stores/versions.store';
import { Table, Select, Button, Space, DatePicker, Input, Tree, Empty } from 'antd';
import { NotificationStore } from '../common-stores/notification.store';
import { Notification } from '../ui-kit/notification';

const VersionsIndex = observer(({ store, notificationStore }) => {
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const dateFormat = 'MM-DD-YYYY';
  const columns = [
    { title: 'ID', dataIndex: 'id', key: 'id' },
    { title: 'Created at', dataIndex: 'createdAt', key: 'createdAt' },
    { title: 'Last update', dataIndex: 'updatedAt', key: 'updatedAt' },
  ];

  const header = (
    <Space>
      <Select
        showSearch
        placeholder='Select a model name'
        style={{ minWidth: 250 }}
        placement='bottomLeft'
        optionFilterProp="children"
        onChange={(value) => store.setModel(value)}
        filterOption={(input, option) => (
          option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        )}
      >
        {store.models.map((model) => <Option value={model} key={model}>{model}</Option>)}
      </Select>
      <RangePicker
        format={dateFormat}
        defaultValue={[store.startDate, store.endDate]}
        onChange={(dates) => store.setDates(dates)}
      />
      <Input
        placeholder='Item id'
        onChange={(e) => store.setItemId(e.target.value)}
      />
      <Button
        type='primary'
        disabled={!store.modelName}
        onClick={() => store.loadItems()}
      >
        Find variants
      </Button>
    </Space>
  );

  const tree = (changes) => {
    const data = JSON.parse(changes);

    return data.length > 0 ? <Tree showLines treeData={data}/> : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  };

  const expandedRowRender = (id) => {
    const expandedColumns = [
      { title: 'ID', dataIndex: 'id', key: 'id' },
      { title: 'Time of change', dataIndex: 'createdAt', key: 'createdAt' },
      { title: 'Event', dataIndex: 'event', key: 'event' },
      { title: 'User', dataIndex: 'user', key: 'user' },
      { title: 'Changes', dataIndex: 'changes', key: 'changes', render: (changes) => tree(changes) },
    ];
    const versions = store.versions[id];

    return <Table
      loading={store.loading}
      dataSource={versions}
      columns={expandedColumns}
      pagination={true}
    >
    </Table>;
  };

  return <>
    <Notification {...notificationStore.data} onClose={notificationStore.cleanup}/>
    <Table
      loading={store.loading}
      title={() => header}
      columns={columns}
      dataSource={store.items}
      expandedRowKeys={store.expandedRowKeys}
      expandable={{
        onExpand: (expended, record) => store.loadVersions(record.id, expended),
        expandedRowRender: (record) => expandedRowRender(record.id),
      }}
    >
    </Table>
  </>;
});

window.addEventListener('turbolinks:load', async () => {
  const root = document.getElementById('versions-page');
  const models = document.getElementById('versions-models')?.value.split(' ');

  if (root && models) {
    const notificationStore = new NotificationStore();
    const store = new VersionsStore(models, notificationStore);

    ReactDOM.render(
      <VersionsIndex store={store} notificationStore={notificationStore}/>,
      root,
    );
  }
});
