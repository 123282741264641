function sendAjaxRequest() {
  const $qualifierInput = $(this);
  const $dataQualifierWrapper = $qualifierInput.closest('[data-qualifier-wrapper]');
  const $qualifierDisplay = $dataQualifierWrapper.find('[data-qualifier-display-field]');
  const $scoreInput = $dataQualifierWrapper.find('[data-qualifier-score-input]');

  $.ajax({
    method: 'POST',
    url: $('[data-rule-set-form]').attr('data-qualifier-score-path'),
    data: {
      qualifier: $qualifierInput.val(),
      vendor_name: $qualifierInput.attr('data-vendor-name'),
    },
    success(data) {
      $qualifierDisplay.html(`${data.score}%`);
      $qualifierDisplay.attr('class', (`${data.new_display_class_name} col-12`));
      $scoreInput.val(data.score);
    },
  });
}

function initQualifierScoreUpdate($el) {
  const qualifierInputs = $el || $('[data-qualifier-input]');

  qualifierInputs.on('input', _.debounce(sendAjaxRequest, 400));
  qualifierInputs.blur(sendAjaxRequest);
}

function initTransactionsForVendor() {
  $('[data-load-transaction]').on('click', function(e) {
    e.preventDefault();

    const $loadButton = $(this);

    if ($loadButton.attr('data-loaded') === 'true') {
      $loadButton.closest('tr').next('.js-transactions-row').find('[data-transactions-wrapper]').remove();
      $loadButton.html('Show Transactions');
      $loadButton.attr('data-loaded', false);
      $loadButton.parents('tr:first').removeClass('js-transactions-shown');
    } else {
      loadTransactions($loadButton);
    }
  });
}

function loadTransactions(currentButton) {
  const $bodyContainer = currentButton.parents('tr').next('.js-transactions-row').find('td');
  const $collapseTemplate = $('[data-transactions-template]').clone();

  $collapseTemplate.removeAttr('data-transactions-template');
  $collapseTemplate.attr('data-transactions-wrapper', true);

  $.ajax({
    method: 'POST',
    url: currentButton.attr('data-load-transaction-path'),
    data: {
      vendor_name: currentButton.attr('data-vendor-name'),
    },
    success(transactions) {
      $collapseTemplate.html('');
      $collapseTemplate.html(formatTransactions(transactions.data));
      $bodyContainer.append($collapseTemplate);
      $collapseTemplate.removeClass('hidden');
      currentButton.parents("tr:first").addClass('js-transactions-shown');
      currentButton.attr('data-loaded', true);
      currentButton.html('Hide Transactions');
    },
  });
}

function formatTransactions(transactions) {
  return transactions.map(function(transaction) {
    if (transaction.description) {
      return `<div data-test-transaction="true">
        <span class="transaction-description">${transaction.description}</span>
        <span class="transaction-date">@ ${transaction.date}</span>
        <span class="transaction-account">@ ${transaction.account}</span>
        </div>`
    }
  }).join('');
}

function initAccountSelect() {
  $('.js-account-select').on('click', function(e) {
    e.preventDefault();

    const $link = $(this);
    $.ajax({
      method: 'POST',
      url: $link.attr('data-load-accounts-path'),
      data: {
        rule_index: $link.attr('data-rule-index'),
        selected_account: $link.html()
      },
      success(accountSelect) {
        const $accountWrapper = $link.parent('.account');

        $accountWrapper.html(accountSelect);
        $accountWrapper.find('[data-select-2]').select2({});
      },
    });
  });
}

function initAddQualifier() {
  $('[data-add-qualifier-button]').on('click', function (e) {
    e.preventDefault();

    const $addBtn = $(this);
    const $lastQualifierEl = $addBtn.closest('.qualifiers-container').find('[data-qualifier-wrapper]:last');
    $.ajax({
      method: 'POST',
      url: $('[data-rule-set-form]').attr('data-add-qualifier-path'),
      data: {
        rule_index: $addBtn.attr('data-rule-index'),
        qualifier_index: parseInt($lastQualifierEl.attr('data-qualifier-index')) + 1,
        vendor_name: $addBtn.closest('[data-vendor-name]').attr('data-vendor-name')
      },
      success(qualifierEl) {
        $lastQualifierEl.after(qualifierEl);
        const $insertedQualifierWrapper = $addBtn.closest('.qualifiers-container')
          .find('[data-qualifier-wrapper]:last');

        initQualifierScoreUpdate($insertedQualifierWrapper.find('[data-qualifier-input]'));
        initRemoveQualifier($insertedQualifierWrapper.find('[data-remove-qualifier-button]'));
      },
    });
  });
}

function initRemoveQualifier(el) {
  const removeButton = el || $('[data-remove-qualifier-button]');

  removeButton.on('click', function(e) {
    e.preventDefault();

    $(this).parents('[data-qualifier-wrapper]').remove();
  });
}

function initOriginTooltip() {
  $('[data-qualifier-origin-tooltip="true"]').tooltip();
}

window.addEventListener('turbolinks:load', () => {
  $('.rule-keeper-datapicker').flatpickr({
    minDate: new Date('January 1, 2000'),
    dateFormat: 'Y-m-d',
  });

  const updatePage = document.getElementById('keeper-rule-edit-page');

  if (updatePage) {
    $(window).keydown((event) => {
      if (event.keyCode === 13) {
        event.preventDefault();

        return false;
      }
    });

    $('[data-keeper-select2]').select2();

    initTransactionsForVendor();
    initQualifierScoreUpdate();
    initAccountSelect();
    initAddQualifier();
    initRemoveQualifier();
    initOriginTooltip();
  }
});
