import { makeObservable, action, observable } from 'mobx';
import { apiV1ClientRAndDTabsPath } from 'helpers/routes.js.erb';
import axios from 'axios';

export class TabsNavigationStore {
  tabs = [];

  constructor(clientId) {
    this.clientId = clientId;

    makeObservable(this, {
      tabs: observable,
      loadTabs: action,
      updateTabs: action.bound,
      deleteTab: action.bound,
      add: action.bound,
    });
  }

  async loadTabs() {
    const response = await axios.get(apiV1ClientRAndDTabsPath(this.clientId));

    this.tabs = response.data;
  }

  async updateTabs(newTab) {
    const foundIndex = this.tabs.findIndex((tab) => tab.id === newTab.id);
    this.tabs[foundIndex] = newTab;
  }

  async deleteTab(tabId) {
    this.tabs = this.tabs.filter((tab) => tab.id !== tabId);
  }

  async add(tabInfo) {
    this.tabs.push(tabInfo);
  }
}
