import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, Input, Space } from 'antd';
import { observer } from 'mobx-react-lite';

export const SendMessageForm = observer(({ store }) => {
  const [focused, setFocus] = useState(false);
  const inputRef = useRef(null);
  const handleCancel = () => {
    setFocus(false);
    store.updateMessage(null);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  });

  if (!focused) {
    return (
      <div className='chat-send-message-container chat-send-message-container-sm'>
        <Input
          onFocus={() => setFocus(true)}
          placeholder='Enter your message'
        />
      </div>
    );
  }

  return (
    <div className='chat-send-message-container'>
      <Form>
        <Form.Item>
          <Input.TextArea
            ref={inputRef}
            value={store.message}
            onChange={(e) => store.updateMessage(e.target.value)}
            placeholder='Enter your message'
          />
        </Form.Item>
        <Form.Item>
          <Space>
            <Button type='primary' onClick={store.createMessage}>Add</Button>
            <Button onClick={handleCancel}>Cancel</Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
});
