import axios from 'axios';
import toastr from "toastr";

import { updateQboDataClientPath } from 'helpers/routes.js.erb';

window.addEventListener('turbolinks:load', () => {
  const clientIdField = $('#qbo-status-client-id');

  if (clientIdField.length) {
    const clientId = clientIdField.val();

    document.getElementById('update-client-qbo-data-btn').onclick = () => {
      $('#update-client-qbo-data-btn').prop('disabled', true);
      axios.post(updateQboDataClientPath(clientId));
      toastr.info('Update process has been started. Please wait for 5 minutes.');
    };
  }
});
