import React from 'react';
import Select from 'react-select';

const FieldSelect = (props) => {
  let values;

  if (props.value) {
    if (props.value.length > 0) {
      values = props.value.map((element) => ({ value: element, label: element }));
    } else {
      values = props.value;
    }
  }

  const styles = {
    valueContainer: (provided) => ({
      ...provided,
      padding: 8,
    }),
  };

  return (
    <div
      className={`pb-2 d-flex flex-column flex-fill ${props.className ? props.className : ''}`}
      data-test-form-field={props.name}
    >
      <span className="text bold" data-test-form-field-name>{props.name}</span>
      <div data-test-input="select">
        <Select
          value={values}
          styles={styles}
          onChange={(e) => props.handleChange({ values: e, key: props.columnName })}
          isMulti={props.options?.multi}
          isClearable={props.isClearable}
          options={props.options.options}
        />
      </div>
    </div>
  );
};

export default FieldSelect;
