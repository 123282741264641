import { Tag } from 'antd';
import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, SyncOutlined } from '@ant-design/icons';
import React from 'react';

export const PacketStatus = ({ value }) => {
  if (value === 'In Progress') {
    return (
      <Tag icon={<SyncOutlined spin/>} color="processing">
        {value}
      </Tag>
    );
  }

  if (value === 'Pending') {
    return (
      <Tag icon={<ClockCircleOutlined/>} color="default">
        {value}
      </Tag>
    );
  }

  if (value === 'Failed') {
    return (
      <Tag icon={<CloseCircleOutlined/>} color="error">
        {value}
      </Tag>
    );
  }

  return (<Tag icon={<CheckCircleOutlined/>} color="success">{value}</Tag>);
};
