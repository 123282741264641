import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

export const NavLink = ({ children, to }) => {
  const matched = useRouteMatch({
    path: to,
    exact: true,
  });

  return (
    <div className='nav-item'>
      <Link to={to} className={`nav-link  ${matched ? 'active' : ''}`}>
        {children}
      </Link>
    </div>
  );
};
