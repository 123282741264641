import React from 'react';
import { inject, observer } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

export default inject('chartsStore')(observer(({ chartsStore, validateForm, redirect }) => {
  const nextStep = async () => {
    if (validateForm()) {
      const saveStep = await chartsStore.nextEditStep();

      if (saveStep) {
        redirect();
      }
    }
  };

  const previousStep = () => {
    chartsStore.previousEditStep();
  };

  const stackedChange = (event) => {
    chartsStore.chartObject.options.stacked = event.target.checked;
  };

  return (
    <div>
      <div className="row justify-content-between align-items-center">
        <div className="col-6">
          <button type="button" className="btn button-primary"
                  data-toggle="modal" data-target="#view-types-modal">
            Choose data View
          </button>
          <div className="form-check form-check-inline mx-3">
            <input className="form-check-input" type="checkbox" id="stackCheckbox"
                   value="stack" onChange={stackedChange}
                   checked={chartsStore.chartObject.options.stacked}/>
            <label className="form-check-label" htmlFor="stackCheckbox">Stack</label>
          </div>
        </div>
        <div className="col-4">
          <div className="row justify-content-end">
            <div className="col-auto">
              <button type="button" className="btn button-danger" onClick={previousStep}>
                {chartsStore.isFirstEditStep ? (
                  <Link to={chartsStore.mainUrl}><span>Cancel</span></Link>
                ) : (
                  <span>Back</span>
                )}
              </button>
              <button type="button" className="btn button-success mx-3" onClick={nextStep}>
                {chartsStore.isLastEditStep ? (
                  <>
                    Save
                    <FontAwesomeIcon icon={['far', 'save']} className="mx-2"/>
                  </>
                ) : (
                  <>
                    Next
                    <FontAwesomeIcon icon={['fas', 'arrow-right']} className="mx-2"/>
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}));
