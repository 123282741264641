import toastr from 'toastr';

window.addEventListener('turbolinks:load', () => {
  $('[data-copy]').click((event) => {
    event.preventDefault();
    const copyText = $(event.currentTarget).data('copy');
    const textArea = document.createElement('textarea');
    textArea.value = copyText;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);

    toastr.info('Text was copied');
  });
});
