import { Button, DatePicker, Space, Checkbox } from 'antd';
import React from 'react';
import { observer } from 'mobx-react-lite';

const { RangePicker } = DatePicker;
const dateFormat = 'MM-DD-YYYY';

export const FeeIncreaseReportsHeader = observer(({ store }) => {
  const canUserCreate = store.userRoles['fee_increase_report']?.includes('create');
  const packetGeneratePanel = (
    <>
    <Space>
      <RangePicker
        format={dateFormat}
        defaultValue={[store.startDate, store.endDate]}
        onChange={(dates) => store.setDates(dates)}
      />
      <Button
        type='primary'
        disabled={!store.isAllowedToGenerate}
        loading={store.isGenerationInProgress}
        onClick={store.generate}
      >
        Generate Report
      </Button>
    </Space>
  </>
  )
  if (canUserCreate) {
    return packetGeneratePanel;
  }
  return <></>;
});
