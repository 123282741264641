import React from 'react';
import { Timeline, Button, Popconfirm, Card, Badge, Space } from 'antd';
import { TimelineContext } from './timeline-context';

export const McpTimelines = ({ item, status, store, last }) => {
  const title = (
    <Space data-test='mcp-timeline-planned-title'>
      <span>By: {item.user}.</span>
      <span>At: {item.scheduled_date}.</span>
      <Popconfirm
        title='Are you sure you want to discard scheduled rate?'
        onConfirm={() => store.declineSchedule(item.id)}
        okText="Yes"
        cancelText="No">
        <Button type="link" danger>
          <i className='fas fa-trash mx-2'/> Discard scheduled change
        </Button>
      </Popconfirm>
    </Space>
  );

  const changedShowing = (text, amount) => (amount ? `${text}: ${amount}` : '');

  const showMcpBudgetText = () => {
    return `MCP budget: ${changedShowing('from', item.old_amount)} ${changedShowing('to', item.new_amount)}`;
  };

  const showMcpHoursText = () => {
    return (
      `MCP budgeted hours: ${changedShowing('from', item.old_hours)} ${changedShowing('to', item.new_hours)}`
    );
  };

  switch (status) {
    case 'planned':
      return (
        <Timeline.Item
          key={item.id}
          color='green'
          className={last ? 'ant-timeline-item-last' : ''}
          data-test='mcp-timeline-item'
        >
          <Badge.Ribbon text="Planned" color="green">
            <Card title={title} size="small">
              <p data-test='mcp-timeline-text'>
                <p>{changedShowing('New MCP budget', item.new_amount)}</p>
                <p>{changedShowing('New MCP budgeted hours', item.new_hours)}</p>
              </p>
              <p>
                {item.notes ? <TimelineContext notes={item.notes}/> : null}
              </p>
              <p>
                {item.zendesk_link
                  ? <Button
                    type='primary'
                    href={item.zendesk_link}
                    target="_blank">Zendesk ticket</Button>
                  : null}
              </p>
            </Card>
          </Badge.Ribbon>
        </Timeline.Item>
      );
    case 'error':
    case 'declined':
      return (
        <Timeline.Item
          key={item.id}
          color='red'
          className={last ? 'ant-timeline-item-last' : ''}
          data-test='mcp-timeline-item'
        >
          <Badge.Ribbon text={status === 'declined' ? 'Declined' : 'Error'} color="red">
            <Card
              title={status === 'declined'
                ? `By: ${item.status_changed_by}. Declined at: ${item.status_changed_at}.`
                : `Change not saved` }
              size="small"
            >
              <p data-test='mcp-timeline-text'>
                <p>{changedShowing('MCP budget', item.new_amount)}</p>
                <p>{changedShowing('MCP budgeted hours', item.new_hours)}</p>
              </p>
                {item.notes ? <TimelineContext notes={item.notes}/> : null}
              <p>
                {item.zendesk_link
                  ? <Button
                    type='primary'
                    href={item.zendesk_link}
                    target="_blank">Zendesk ticket</Button>
                  : null}
              </p>
            </Card>
          </Badge.Ribbon>
        </Timeline.Item>
      );
    case 'processed':
      return (
        <Timeline.Item
          key={item.id}
          color='blue'
          className={last ? 'ant-timeline-item-last' : ''}
          data-test='mcp-timeline-item'
        >
          <Badge.Ribbon text="Changed" color="blue">
            <Card
              title={`By: ${item.user}. Changed at: ${item.status_changed_at}.`}
              size="small"
            >
              <p data-test='mcp-timeline-text'>
                <p>{item.new_amount ? showMcpBudgetText() : ''}</p>
                <p>{item.new_hours ? showMcpHoursText() : ''}</p>
              </p>
              <p>
                {item.notes ? <TimelineContext notes={item.notes}/> : null}
              </p>
              <p>
                {item.zendesk_link
                  ? <Button
                    type='primary'
                    href={item.zendesk_link}
                    target="_blank">Zendesk ticket</Button>
                  : null}
              </p>
            </Card>
          </Badge.Ribbon>
        </Timeline.Item>
      );
    default:
      return <></>;
  }
};
