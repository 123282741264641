import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Table } from 'antd';
import { observer } from 'mobx-react-lite';
import { toJS } from 'mobx';
import { RAndDReportStatus } from './components/status';
import { NotificationStore } from '../common-stores/notification.store';
import { Notification } from '../ui-kit/notification';
import { RAndDReportsStore } from './stores/r-and-d-reports.store';
import { RAndDReportHeader } from './components/header';

const { Column } = Table;

export const RAndDReports = observer(({ notificationStore, userRoles }) => {
  const [store] = useState(() => new RAndDReportsStore(notificationStore, userRoles));

  useEffect(() => {
    store.load();

    return store.destroyWsSubscription;
  }, []);

  return (
    <>
      <Notification {...notificationStore.data} onClose={notificationStore.cleanup}/>
      <Table
        rowKey='id'
        loading={store.isLoading}
        pagination={false}
        title={() => <RAndDReportHeader store={store}/>}
        dataSource={toJS(store.reports)}
      >
        <Column className='test-tx-date' title='Year' dataIndex='year'/>
        <Column className='test-tx-type' title='Created At' dataIndex='created_at'/>
        <Column className='test-tx-type' title='Generated By' dataIndex='generated_by'/>
        <Column
          className='test-tx-type'
          title='Status'
          dataIndex='status'
          render={(value) => <RAndDReportStatus value={value}/>}
        />
        <Column
          className='test-tx-vendor'
          render={(url) => (url ? <a href={url}>Download</a> : null)}
          title='Actions'
          dataIndex='url'
        />
      </Table>
    </>
  );
});

window.addEventListener('turbolinks:load', async () => {
  const rootNode = document.getElementById('r_and_d_reports');
  const roles = document.getElementById('user-roles')?.value;

  if (rootNode) {
    const notificationStore = new NotificationStore();
    const userRoles = JSON.parse(roles);

    ReactDOM.render(
      <RAndDReports notificationStore={notificationStore} userRoles={userRoles}/>,
      rootNode,
    );
  }
});
