import React from 'react';
import { Typography, Space } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const { Text, Link } = Typography;
const Icon = ({ className }) => (
  <FontAwesomeIcon icon={['far', 'comment-dots']} className={className}/>
);

export const OpenChatButton = ({ loadChatHandler, lastMessage, messagesCount }) => {
  if (!lastMessage) {
    return (
      <Link
        onClick={loadChatHandler}
        style={{ whiteSpace: 'nowrap' }}
      >
        <Icon className='mr-1'/> Add comment
      </Link>
    );
  }

  return (
    <Space size={1}>
      <Text
        style={{ width: 80 }}
        ellipsis={{ tooltip: lastMessage }}
      >
        {lastMessage}
      </Text>

      <Link
        style={{ whiteSpace: 'nowrap' }}
        onClick={loadChatHandler}
      >
        {messagesCount}<Icon className='ml-1'/>
      </Link>
    </Space>
  );
};
