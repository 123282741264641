import React from 'react';
import MonthPicker from '../../ui-kit/month-picker.jsx';
import { inject, observer } from 'mobx-react';

export default inject('chartsStore')(observer(({ chartsStore, nameInvalid }) => {
  const startMonthChanged = (date) => {
    chartsStore.dateRange.startDate = date;
  };

  const endMonthChanged = (date) => {
    chartsStore.dateRange.endDate = date;
  };

  return (
    <div className="row top-panel mb-2 align-items-center">
      <div className="col-5">
        <div className="row align-items-center">
          <div className="col-auto">
            <label htmlFor="chart-name-field">Chart name:</label>
          </div>
          <div className="col">
            <input type="text" id="chart-name-field"
                   className={`full-width form-control ${nameInvalid ? 'is-invalid' : ''}`}
                   value={chartsStore.chartObject.name}
                   onChange={(e) => chartsStore.chartObject.name = e.target.value}/>
            <div className="invalid-feedback">
              Please enter a chart name.
            </div>
          </div>
        </div>
      </div>
      <div className="col-7">
        <div className="row align-items-center">
          <div className="label">
            <span>Start month:</span>
          </div>
          <div className="">
            <MonthPicker className="month-picker" onMonthChanged={startMonthChanged}
                         defaultDate={chartsStore.dateRange.startDate}/>
          </div>
          <div className="label">
            <span>End month:</span>
          </div>
          <div className="">
            <MonthPicker className="month-picker" onMonthChanged={endMonthChanged}
                         defaultDate={chartsStore.dateRange.endDate}/>
          </div>
        </div>
      </div>
    </div>
  );
}));
