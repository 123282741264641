import Dropzone from 'dropzone';
import { DirectUpload } from '@rails/activestorage';
import { incrementPendingXHRRequest, decrementPendingXHRRequest } from '../../xhr-pending-requests-helper';

export class ActiveStorageDirectUploader {
  constructor(file, source) {
    this.directUpload = new DirectUpload(file, source.url, this);
    this.source = source;
    this.file = file;
    this.file.uploaderCtx = this;
  }

  start() {
    this.hiddenInput = this.createHiddenInput();
    this.directUpload.create((error, attributes) => this.handleResult(error, attributes));
  }

  handleResult(error, attributes) {
    if (error) {
      this.hiddenInput.parentNode.removeChild(this.hiddenInput);
      this.emitDropzoneError(error);
    } else {
      this.hiddenInput.setAttribute('value', attributes.signed_id);
      this.emitDropzoneSuccess();
    }
  }

  createHiddenInput() {
    const input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('name', this.source.fileInput.name);

    this.source.fileInput.parentNode.insertBefore(input, this.source.fileInput.nextSibling);

    return input;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr;

    this.xhr.upload.addEventListener('loadstart', () => incrementPendingXHRRequest());
    this.xhr.upload.addEventListener('loadend', () => decrementPendingXHRRequest());
    this.xhr.upload.addEventListener('progress', (event) => this.uploadRequestDidProgress(event));
  }

  uploadRequestDidProgress(event) {
    const progress = (event.loaded / event.total) * 100;
    const element = this.file.previewTemplate.querySelector('.dz-upload');

    element.style.width = `${progress}%`;
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropZone.emit('processing', this.file);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.source.dropZone.emit('error', this.file, error);
    this.source.dropZone.emit('complete', this.file);
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    this.source.dropZone.emit('success', this.file);
    this.source.dropZone.emit('complete', this.file);
  }
}
