import React from 'react';
import { Col, DatePicker, Input, Modal, Row, Alert } from 'antd';

export const McpModal = ({ store, TextArea, isModalVisible }) => (
  <Modal
    title="Schedule MCP Budget Change"
    visible={isModalVisible}
    onOk={store.scheduleMcp}
    onCancel={store.modalCancel}
  >
    <Row gutter={[16, 16]} justify="space-between" data-test='mcp-scheduled-form'>
      {(store.nextPlanned === null || store.nextPlanned.length === 0)
        ? <></>
        : <Alert
          message={`Planned schedule at ${store.nextPlanned[0].scheduled_date} created by ${store.nextPlanned[0].user}
         will be declined!`}
          type="warning"
          data-test='mcp-scheduled-warning-alert'
          closable
        />
      }
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <Input
            addonBefore="MCP Budget"
            defaultValue={store.mcpBudget}
            onChange={(e) => store.setScheduledMcpBudget(e.target.value)}
            data-test='mcp-scheduled-mcp-budget-input'
          />
        </Col>
        <Col span={24}>
          <Input
            addonBefore="MCP Budgeted Hours"
            defaultValue={store.mcpHours}
            onChange={(e) => store.setScheduledMcpHours(e.target.value)}
            data-test='mcp-scheduled-mcp-hours-input'
          />
        </Col>
        <Col span={24}>
          <TextArea
            placeholder="Context for MCP Change"
            allowClear
            onChange={(e) => store.setScheduleNotes(e.target.value)}
            data-test='mcp-scheduled-mcp-content'
          />
        </Col>
        <Col span={24}>
          <Input
            placeholder="Link to Zendesk ticket (url)"
            allowClear
            onChange={(e) => store.setScheduleZendeskLink(e.target.value)}
            data-test='mcp-scheduled-mcp-link-input'
          />
        </Col>
        <DatePicker
          format={'MM/DD/YYYY'}
          placeholder="Select Effective Date"
          disabledDate={store.disabledDate}
          onChange={(date) => store.setScheduleDate(date)}
          data-test='mcp-scheduled-mcp-datepicker'
          style={ { with: 400 } }
        />
      </Row>
    </Row>
  </Modal>
);
