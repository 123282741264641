import React from 'react';
import ReactDOM from 'react-dom';
import ChartEdit from './pages/chart-edit.jsx';
import './charts.scss';
import { inject, observer, Provider } from 'mobx-react';
import ChartsStore from './stores/chart-store';
import IndexPage from './pages/index-page.jsx';
import ShowChart from './pages/show-chart.jsx';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

@inject('chartsStore')
@observer
class ChartsPage extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const mainLocation = this.props.chartsStore.mainUrl;
    this.props.chartsStore.userRoles = this.props.userRoles;
    return (
      <div className="charts-page">
        <Switch>
          <Route exact path={mainLocation} component={IndexPage}></Route>
          <Route path={`${mainLocation}/:id/edit`} component={ChartEdit}></Route>
          <Route path={`${mainLocation}/new`} component={ChartEdit}></Route>
          <Route path={`${mainLocation}/:id`} component={ShowChart}></Route>
        </Switch>
      </div>
    );
  }
}

window.addEventListener('turbolinks:load', () => {
  const rootElement = document.getElementById('charts-app');

  if (!rootElement) {
    return;
  }

  const clientData = document.getElementById('client').value;
  const client = JSON.parse(clientData);
  const chartStore = new ChartsStore(client);
  const roles = document.getElementById('user-roles')?.value;
  const userRoles = JSON.parse(roles);
  ReactDOM.render(
    <Provider chartsStore={chartStore}>
      <Router>
        <ChartsPage client={client} userRoles={ userRoles }/>
      </Router>
    </Provider>,
    rootElement,
  );
});
