import ReactDOM from 'react-dom';
import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Space, Card, Row, Col, Typography } from 'antd';
import { ApolloClient, ApolloProvider, InMemoryCache, ApolloLink, HttpLink, useSubscription } from '@apollo/client';
import { createConsumer } from '@rails/actioncable';
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink';
import gql from 'graphql-tag';
import { graphqlPath } from 'helpers/routes.js.erb';

const GraphqlSubscriptionsIndex = () => {
  const { TextArea } = Input;
  const { Text } = Typography;
  const gridStyle = {
    width: '50%',
  };

  const DEFAULT = gql`
    subscription {
      vendorReportStatus {
        id,
        status
      }
    }
  `;
  const [form] = Form.useForm();
  const [query, setQuery] = useState(DEFAULT);

  const { data, loading } = useSubscription(query);

  const onFinish = (text) => {
    setQuery(gql`${text.query}`);
  };

  useEffect(() => {
  }, [query, data, loading]);

  return (
    <>
      <Row gutter={16}>
        <Col span={8}>
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item
              name="query"
              label="Graphql Query"
            >
              <TextArea rows={10} placeholder="Put your subscription query" />
            </Form.Item>
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit">
                  Send
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </Col>
        <Col span={16}>
          <Card title="Subscription">
            <Card.Grid style={gridStyle}>
              <Text type="warning">Graphql query data:</Text>
              <pre>{JSON.stringify(query, null, 2)}</pre>
            </Card.Grid>
            <Card.Grid style={gridStyle}>
              <Text type="success">Result:</Text>
              {data && <pre>{JSON.stringify(data, null, 2)}</pre>}
            </Card.Grid>
          </Card>
        </Col>
      </Row>
    </>
  );
};

window.addEventListener('turbolinks:load', async () => {
  const root = document.getElementById('graphql-subscription-dev');

  if (root) {
    const cable = createConsumer();

    const httpLink = new HttpLink({
      uri: graphqlPath,
      credentials: 'include',
    });

    const hasSubscriptionOperation = ({ query: { definitions } }) => definitions.some(
      ({ kind, operation }) => kind === 'OperationDefinition' && operation === 'subscription',
    );

    const link = ApolloLink.split(
      hasSubscriptionOperation,
      new ActionCableLink({ cable }),
      httpLink,
    );

    const client = new ApolloClient({
      link,
      cache: new InMemoryCache(),
    });

    ReactDOM.render(
      <ApolloProvider client={client}>
        <GraphqlSubscriptionsIndex />
      </ApolloProvider>,
      root,
    );
  }
});
