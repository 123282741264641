import React, { useState, useContext } from 'react';
import ReactDOM from 'react-dom';
import { Row, Col, Button, DatePicker, Space, Divider, Select, Modal } from 'antd';
import { SchedulesStore } from './stores/schedules.store';
import { observer } from 'mobx-react-lite';
import { SchedulesTable } from './components/schedules-table';
import { TypeSelect } from './components/type-select';
import { ScheduleForm } from './components/schedule-form';

export const SchedulesStoreContext = React.createContext({});

const SchedulesIndex = observer(() => {
  const schedulesStore = useContext(SchedulesStoreContext);

  const [editModalVisible, setEditModalVisible] = useState(false);
  const [formItem, setFormItem] = useState(null);

  const formSubmit = async (values) => {
    if (formItem) {
      await schedulesStore.updateItem(formItem, values);
    } else {
      await schedulesStore.createItem(values);
    }
    setFormItem(null);
    setEditModalVisible(false);
  };

  const newItem = () => {
    setFormItem(null);
    setEditModalVisible(true);
  };

  const itemEdit = (item) => {
    setFormItem(item);
    setEditModalVisible(true);
  };

  return (
    <Row justify="center">
      <Col span={24}>
        <Row>
          <Col>
            <Space>
              <span>Schedule type: </span>
              <TypeSelect
                onChange={(path) => schedulesStore.selectType(path[path.length - 1])}
                defaultValue={schedulesStore.currentScheduleType}
              />
              <Divider type="vertical" />
              <DatePicker.RangePicker
                onChange={([start, end]) => schedulesStore.setDates(start, end)}
                defaultValue={
                  [schedulesStore.dates.startDate, schedulesStore.dates.endDate]
                }
              />
              <Button type="primary" onClick={newItem}>Create</Button>
            </Space>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <SchedulesTable onEdit={itemEdit}/>
          </Col>
        </Row>
      </Col>
      <Modal
        visible={editModalVisible}
        title="Create Schedule item"
        footer={null}
        onCancel={() => setEditModalVisible(false)}
      >
        <ScheduleForm scheduleType={schedulesStore.currentScheduleType} onSubmit={formSubmit} item={formItem}/>
      </Modal>
    </Row>
  );
});

window.addEventListener('turbolinks:load', async () => {
  const rootNode = document.getElementById('schedule-items');
  const clientId = document.getElementById('schedule-items-client-id')?.value;

  if (rootNode && clientId) {
    const schedulesStore = new SchedulesStore(clientId);

    ReactDOM.render(
      <SchedulesStoreContext.Provider value={schedulesStore}>
        <SchedulesIndex schedulesStore={schedulesStore}/>
      </SchedulesStoreContext.Provider>,
      rootNode,
    );
  }
});
