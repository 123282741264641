import React from 'react';
import * as chartsHelpers from '../../common/charts-helper';
import { inject, observer } from 'mobx-react';
import ChartLegend from './chart-legend.jsx';

@inject('chartsStore')
@observer
export default class ChartPreview extends React.Component {
  constructor(props) {
    super(props);
    this.chartCanvas = React.createRef();
    this.chartInstance = undefined;
    this.state = {
      datasets: [],
    };
  }

  componentDidMount() {
    this.drawChart();
  }

  drawChart = async () => {
    this.canvasContext = this.chartCanvas.current.getContext('2d');

    if (this.chartInstance) {
      this.chartInstance.destroy();
    }

    if (this.props.data) {
      this.chartInstance = chartsHelpers.createChartJs(
        this.canvasContext,
        this.props.chart.options,
        this.props.data,
      );
      this.setState({ datasets: this.props.data.datasets }, this.props.onRenderFinish);
    } else {
      const result = await chartsHelpers.createChartJsWithData(
        this.props.chart,
        this.props.chartsStore.client.id,
        this.canvasContext,
      );

      this.chartInstance = result.chart;
      this.setState({ datasets: result.data.datasets }, this.props.onRenderFinish);
    }
  };

  render() {
    return (
      <div className="chart">
        <div className="row">
          <div className="col-12">
            <div className="row justify-content-center">
              <div className="col-4 text-center">
                {this.props.showName
                  && <div className="chart-title">
                    {this.props.chart.name}
                  </div>
                }
              </div>
            </div>
            {this.props.showLegend
            && <div className="row justify-content-between my-2 chart-header">
              <div className="col-6 chart-title">
                {this.props.chartsStore.client.name}
              </div>
            </div>
            }
          </div>
        </div>
        <div>
          <canvas ref={this.chartCanvas} className="chart-canvas"/>
        </div>
        {this.props.showLegend
          && <div className="chart-legend mt-3">
            <ChartLegend items={this.state.datasets}/>
          </div>
        }
      </div>
    );
  }
}
