import { makeObservable, action, observable } from 'mobx';
import {
  apiV1ClientMcpBudgetsPath,
  apiV1ClientMcpBudgetDeclinePath,
  scheduleAPIV1ClientMcpBudgetsPath,
} from 'helpers/routes.js.erb';
import axios from 'axios';
import moment from 'moment';

export class McpStore {
  historyItems = [];
  isModalVisible = false;
  loading = false;
  mcpBudget = null;
  mcpHours = null;
  latestChange = {};
  scheduledMcpBudget = null;
  scheduledMcpHours = null;
  scheduleDate = null;
  scheduleNotes = null;
  nextPlanned = null;
  scheduleZendeskLink = null;

  constructor(clientId, notificationStore) {
    this.clientId = clientId;
    this.notificationStore = notificationStore;

    makeObservable(this, {
      mcpBudget: observable,
      mcpHours: observable,
      scheduledMcpHours: observable,
      scheduledMcpBudget: observable,
      scheduleDate: observable,
      scheduleNotes: observable,
      scheduleZendeskLink: observable,
      loading: observable,
      latestChange: observable,
      historyItems: observable,
      isModalVisible: observable,
      declineSchedule: action,
      scheduleMcp: action.bound,
      loadMcpData: action,
      showModal: action.bound,
      modalCancel: action.bound,
      setScheduledMcpBudget: action,
      setScheduleDate: action,
      setScheduledMcpHours: action,
      setScheduleNotes: action,
      setScheduleZendeskLink: action,
      disabledDate: action,
    });
  }

  showModal() {
    this.isModalVisible = true;
  }

  modalCancel() {
    this.isModalVisible = false;
  }

  setScheduledMcpBudget(value) {
    this.scheduledMcpBudget = value;
  }

  setScheduledMcpHours(value) {
    this.scheduledMcpHours = value;
  }

  setScheduleDate(date) {
    this.ScheduleDate = date;
  }

  setScheduleNotes(text) {
    this.scheduleNotes = text;
  }

  setScheduleZendeskLink(text) {
    this.scheduleZendeskLink = text;
  }

  disabledDate(current) {
    return current && current < moment().endOf('day');
  }

  async loadMcpData() {
    this.loading = true;
    const response = await axios.get(apiV1ClientMcpBudgetsPath(this.clientId));

    this.historyItems = response.data.all_changes;
    this.mcpBudget = response.data.mcp_budget;
    this.mcpHours = response.data.mcp_hours;
    this.latestChange = this.historyItems[0];
    this.nextPlanned = this.historyItems.filter((item) => item.status === 'planned');
    this.loading = false;
  }

  async declineSchedule(srChangeId) {
    const response = await axios.post(apiV1ClientMcpBudgetDeclinePath(this.clientId, srChangeId));

    const { data } = response;

    if (data.errors) {
      this.notificationStore.create({
        header: 'Something wrong. Please try again!',
        message: data.errors,
        type: 'error',
      });
    } else {
      this.notificationStore.create({
        header: 'Success',
        message: 'Schedule declined!',
        type: 'success',
      });
      await this.loadMcpData();
    }
  }

  async scheduleMcp() {
    this.isModalVisible = false;
    const response = await axios.post(scheduleAPIV1ClientMcpBudgetsPath(this.clientId),
      {
        service_rate: this.scheduledMcpBudget,
        mcp_hours: this.scheduledMcpHours,
        notes: this.scheduleNotes,
        zendesk_link: this.scheduleZendeskLink,
        scheduled_date: this.ScheduleDate ? this.ScheduleDate.format() : null,
      });

    const { data } = response;

    if (data.errors) {
      this.notificationStore.create({
        header: 'Something wrong. Please try again!',
        message: data.errors,
        type: 'error',
      });
    } else {
      this.notificationStore.create({
        header: 'Success',
        message: 'MCP scheduled!',
        type: 'success',
      });
      await this.loadMcpData();
    }
  }
}
