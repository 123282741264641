import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { toJS } from 'mobx';
import { SummaryStore } from '../stores/summary.store';

const { Column } = Table;

export const Summary = observer(({ clientId, selectedYear }) => {
  const [store] = useState(() => new SummaryStore(clientId));

  useEffect(() => {
    async function init() {
      store.setYear(selectedYear);
      await store.loadSummary();
    }

    init();
  }, [selectedYear]);

  useEffect(() => {
    async function init() {
      await store.loadVendors();
    }

    init();
  }, [store.tabId]);

  const expandedTable = (tabId) => {
    const columns = [
      { title: 'Vendor', dataIndex: 'vendor', key: 'vendor', className: 'test-rnd-summary-expanded-vendor' },
      { title: 'Amount', dataIndex: 'amount', key: 'amount', className: 'test-rnd-summary-expanded-amount' },
    ];

    const data = [];
    const vendors = store.allVendors[tabId];

    if (vendors) {
      vendors.forEach((el) => {
        const [vendor, amount] = el;
        data.push({ key: vendor, vendor, amount });
      });
    }

    return <Table
      loading={store.loading}
      columns={columns}
      dataSource={data}
      pagination={false}
      size='small'
      rowClassName='test-rnd-summary-vendors'
    />;
  };

  return (
    <Table
      loading={store.loading}
      rowSelection={false}
      pagination={false}
      dataSource={toJS(store.summary)}
      expandable={{
        onExpand: (expended, record) => store.setTabId(record.id),
        expandedRowRender: (record) => expandedTable(record.id),
      }}
      rowClassName='test-rnd-summary'
    >
      <Column className='test-rnd-summary-tab-name' title='Name' dataIndex='name'/>
      <Column
        className='test-rnd-summary-tab-total'
        title='Total'
        render={(total) => `$${total}`}
        dataIndex='value'
      />
    </Table>
  );
});
