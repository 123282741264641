import { action, computed, makeObservable, observable } from 'mobx';
import axios from 'axios';
import { apiV1ClientFeeIncreaseReportsPath } from 'helpers/routes.js.erb';
import moment from 'moment';

export class FeeIncreaseReportsStore {
  isLoading = false;
  isGenerationInProgress = true;
  reports = [];
  endDate = moment();
  userRoles = null;

  constructor(startDate, clientId, notificationStore, userRoles) {
    this.startDate = moment(startDate).isValid()? moment(startDate) : (moment().subtract(1, 'year').startOf('year'));
    this.clientId = clientId;
    this.notificationStore = notificationStore;
    this.userRoles = userRoles;

    makeObservable(this, {
      reports: observable,
      startDate: observable,
      endDate: observable,
      isLoading: observable,
      isGenerationInProgress: observable,
      load: action,
      setReports: action,
      setDates: action,
      generate: action.bound,
      toggleLoading: action,
      appendReport: action,
      toggleGenerationStatus: action,
      isAllowedToGenerate: computed,
    });
  }

  async load() {
    this.toggleLoading();

    const { data } = await axios.get(apiV1ClientFeeIncreaseReportsPath(this.clientId));

    this.setReports(data);
    this.toggleGenerationStatus();
    this.toggleLoading();
  }



  async generate() {
    const { data } = await axios.post(apiV1ClientFeeIncreaseReportsPath(this.clientId), {
      fee_increase_report: {
        start_date: this.startDate.format(),
        end_date: this.endDate.format(),
      },
    });

    if (!data) {
      this.notificationStore.create({
        header: 'Error',
        message: 'Server communication failed',
        type: 'error',
      });
      return;
    }

    if (data.status === 'Completed') {
      this.notificationStore.create({
        header: 'Success',
        message: 'Report successfully generated',
        type: 'success',
      });

    }

    else if ((data.status === 'In Progress') || (data.status === 'Pending')) {
      this.notificationStore.create({
        header: 'Success',
        message: 'Report queued for generation',
        type: 'success',
      });
    }

    else {
      this.notificationStore.create({
        header: 'Error',
        message: 'Report generation failed',
        type: 'error',
      });

    }

    this.appendReport(data);
  }

  setReports(reports) {
    this.reports = reports;
  }

  toggleGenerationStatus() {
    const lastReport = this.reports[0];

    this.isGenerationInProgress = (lastReport?.status === 'In Progress' || lastReport?.status === 'Pending');
  }

  appendReport(report) {
    const reportExists = this.reports.find((existingReport) => existingReport.id === report.id);

    if (reportExists) {
      this.reports = this.reports.map((existingReport) => {
        if (existingReport.id === report.id) {
          return report;
        }

        return existingReport;
      });
    } else {
      this.reports = [report, ...this.reports];
    }

    this.toggleGenerationStatus();
  }

  setDates(dates) {
    const [startDate, endDate] = dates || [];

    this.startDate = startDate;
    this.endDate = endDate;
  }

  toggleLoading() {
    this.isLoading = !this.isLoading;
  }

  get isAllowedToGenerate() {
    return (this.startDate?.isValid() && this.endDate?.isValid())
      && this.isGenerationInProgress === false
      && this.isLoading === false;
  }
}
