import Axios from 'axios';
import { observable, action, computed, reaction, toJS, makeObservable } from 'mobx';
import * as chartHelpers from 'common/charts-helper';
import { apiV1ClientChartPath, apiV1ClientChartsPath, clientClientChartsPath } from 'helpers/routes.js.erb';

export default class ChartsStore {
  editStep = 0;
  chartObject = null;
  chartData = {
    months: [],
    datasets: [],
  };
  chartsList = [];
  userRoles = {};
  dateRange = {
    startDate: chartHelpers.yearAgoDate(),
    endDate: chartHelpers.previousMonth(),
  };

  constructor(client) {
    this.client = client;
    this.mainUrl = clientClientChartsPath(this.client);

    makeObservable(this, {
      editStep: observable,
      chartObject: observable,
      chartData: observable,
      chartsList: observable,
      dateRange: observable,
      nextEditStep: action,
      previousEditStep: action,
      createNewChart: action,
      openChart: action,
      closeChart: action,
      openEditChart: action,
      isFirstEditStep: computed,
      isLastEditStep: computed,
      userRoles: observable
    });

    reaction(
      () => (this.chartObject ? this.chartObject.selected_categories : []),
      () => {
        this.loadChartData();
        this.processChartOptions();
      },
    );
    reaction(
      () => (this.chartObject ? Object.entries(this.chartObject.options.dataViewTypes) : []),
      () => this.loadChartData(),
    );
    reaction(
      () => (this.chartObject ? this.chartObject.options.stacked : {}),
      () => this.loadChartData(),
    );
    reaction(() => this.dateRange.startDate, () => this.loadChartData());
    reaction(() => this.dateRange.endDate, () => this.loadChartData());
    reaction(() => this.chartObject, () => this.editStep = 0);
  }

  async nextEditStep() {
    this.editStep += 1;

    if (this.editStep >= this.editStepNames.length) {
      const save = await this.saveChart();

      if (save) {
        return true;
      }
    }

    return false;
  }

  previousEditStep() {
    if (this.editStep === 0) {
      this.chartObject = null;
    } else {
      this.editStep -= 1;
    }
  }

  createNewChart() {
    this.chartObject = {
      id: 0,
      name: '',
      selected_categories: [],
      options: {
        stacked: false,
        dataViewTypes: {},
      },
    };
  }

  openChart(chart) {
    this.chartObject = chart;
  }

  closeChart() {
    this.chartObject = null;
  }

  openEditChart(chart) {
    this.chartObject = chart;
  }

  get isFirstEditStep() {
    return this.editStep === 0;
  }

  get isLastEditStep() {
    return this.editStep >= (this.editStepNames.length - 1);
  }

  get editStepNames() {
    const id = this.chartObject ? this.chartObject.id : 0;

    return [
      id > 0 ? 'Edit data' : 'Add data',
      'Preview chart',
      'Save chart',
    ];
  }

  async loadChart(clientId, chartId) {
    const url = apiV1ClientChartPath(clientId, chartId);

    const request = await Axios.get(url);
    const chart = request.data;
    chart.options = JSON.parse(chart.options);
    this.chartObject = chart;
  }

  async saveChart() {
    const chart = toJS(this.chartObject);

    if (chart.id === 0) {
      const url = apiV1ClientChartsPath(this.client.id);

      await Axios.post(url, chart);
    } else {
      const url = apiV1ClientChartPath(this.client.id, chart.id);

      await Axios.patch(url, chart);
    }

    this.chartObject = null;

    return true;
  }

  loadChartData() {
    if (this.chartObject === null) {
      return;
    }

    chartHelpers.generateChartData(this.chartObject, this.dateRange, this.client.id)
      .then(action('set chart data', (data) => {
        this.chartData = data;
      }));
  }

  processChartOptions() {
    if (!this.chartObject) {
      return;
    }

    const selectedCategories = this.chartObject.selected_categories;

    if (selectedCategories.length === 0) {
      this.chartObject.options.dataViewTypes = {};

      return;
    }

    const dataViewTypes = toJS(this.chartObject.options.dataViewTypes);

    selectedCategories.forEach((category) => {
      if (!(category in dataViewTypes)) {
        dataViewTypes[category] = 'bar';
      }
    });

    for (const [key] of Object.entries(dataViewTypes)) {
      if (!selectedCategories.includes(key)) {
        delete dataViewTypes[key];
      }
    }

    this.chartObject.options.dataViewTypes = dataViewTypes;
  }
}
