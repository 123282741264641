import consumer from '../action-cable/consumer';

window.addEventListener('turbolinks:load', () => {
  const clientIdField = $('#financial-model-result-client-id');

  if (clientIdField.length) {
    consumer.subscriptions.create({
      channel: 'FinancialModelResultChannel',
      client_id: clientIdField[0].value,
    },
    {
      received(data) {
        const row = $(`tr[data-id=${data.object.id}]`);

        let statusClass = 'badge badge-';
        let statusLabel = '';

        if (data.object.status === 'in_progress') {
          statusClass += 'secondary';
          statusLabel = 'In progress';
        } else if (data.object.status === 'done') {
          statusClass += 'success';
          statusLabel = 'Done';
          row.find('.fm-download').removeClass('d-none').attr('href', data.object.file_url);
        } else {
          statusClass += 'danger';
          statusLabel += 'Failed';
        }
        row.find('.fm-status span').attr('class', statusClass).text(statusLabel);
      },
    });
  }
});
