import consumer from 'action-cable/consumer';

window.addEventListener('turbolinks:load', () => {
  if (document.getElementById('js-analysis-create')) {
    consumer.subscriptions.create(
      {
        channel: 'TransactionAnalysisChannel',
        client_id: window.location.pathname.split('/')[2],
      },
      {
        received(data) {
          const accountId = window.location.search.substring(12, window.location.search.search('&'));

          window.location.href = `${window.location.pathname}/${accountId}?identifier=${data}`;
        },
      },
    );
  }
});
