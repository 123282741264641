import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ReactTooltip from 'react-tooltip';
import { pluralize } from './text-helpers';

export default class NotificationTooltip extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const word = pluralize(this.props.chart.active_notifications.length, 'notification');

    return (
      <>
        <FontAwesomeIcon data-tip data-for={`notificationTooltip-${this.props.chart.id}`} icon={['far', 'bell']}/>
        <ReactTooltip id={`notificationTooltip-${this.props.chart.id}`} place="top" effect="solid">
          You have {this.props.chart.active_notifications.length} unresolved {word}!
        </ReactTooltip>
      </>
    );
  }
}
