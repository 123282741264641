import React from 'react';
import { Select, Space } from 'antd';
import { observer } from 'mobx-react-lite';

const { Option } = Select;

export const YearSelector = observer(({ yearsSelectionStore }) => (
  <Space>
    <span>Select Year</span>
    <Select
      className='rnd-year-select'
      defaultValue={yearsSelectionStore.selectedYear}
      onSelect={yearsSelectionStore.selectYear}
    >
      {
        yearsSelectionStore.availableYears.map((year) => (
          <Option key={year} value={year} className='rnd-year-select-option'>{year}</Option>
        ))
      }
    </Select>
  </Space>
));
