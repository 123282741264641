import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import GoogleSheetIcon from 'images/google_sheets_icon.svg';
import GoogleDocsIcon from 'images/google_docs_icon.svg';
import CspIcon from 'images/csp_icon.svg';
import QuickbooksIcon from 'images/quickbooks_icon.svg';
import BoxIcon from 'images/box_icon.svg';
import BillIcon from 'images/bill_icon.svg';
import MavenlinkIcon from 'images/mavenlink_icon.svg';
import ZendeskIcon from 'images/zendesk_icon.svg';
import FormField from '../ui-kit/form/field.jsx';
import ColorPicker from "./color-picker";

@inject('store')
@observer
export default class Item extends Component {
  constructor(props) {
    super(props);
    this.store = props.store;
  }

  iconOptions = [
    { value: '0', label: <img src={GoogleSheetIcon}/> },
    { value: '1', label: <img src={GoogleDocsIcon}/> },
    { value: '2', label: <img src={CspIcon}/> },
    { value: '3', label: <img src={QuickbooksIcon}/> },
    { value: '4', label: <img src={BoxIcon}/> },
    { value: '5', label: <img src={BillIcon}/> },
    { value: '6', label: <img src={MavenlinkIcon}/> },
    { value: '7', label: <img src={ZendeskIcon}/> }
  ];

  generateSelectParams = {multi: false, options: this.iconOptions};

  handleChange = (fieldName, value) => {
    this.store.update(this.props.index, fieldName, value);
  };

  handleSelectChange = (e) => {
    let { values } = e;
    const { key } = e;
    this.store.update(this.props.index, key, Number(values.value));
  };

  handleDelete = (e) => {
    e.preventDefault();
    this.store.delete(this.props.index);
  };

  render() {
    const { errors, index } = this.props;
    const valueIcon = this.iconOptions[this.props.icon];

    return (
      <div id={`shortcut-${index}`} className="with-separator py-2 my-2" data-test-shortcut-section>
        <div className="row">
          <div className="col-11">
            <div className="row justify-content-center align-items-center">
              <div className="col-4">
                <FormField
                  columnName="name"
                  name='Name'
                  value={ this.props.name }
                  errors={errors?.name}
                  handleChange={(e) => { this.handleChange('name', e.target.value); }}
                />
              </div>
              <div className="col-4">
                <FormField
                  columnName="link"
                  name='Link'
                  value={this.props.link}
                  errors={errors?.link}
                  handleChange={(e) => { this.handleChange('link', e.target.value); }}
                />
              </div>
              <div className="col-2">
                <FormField
                  valueDefined
                  type="select"
                  isClearable={false}
                  columnName="icon"
                  name='Icon'
                  value={valueIcon}
                  errors={errors?.icon}
                  options={this.generateSelectParams}
                  handleChange={this.handleSelectChange}
                />
              </div>
              <div className="col-2">
                <ColorPicker
                  color={this.props.color}
                  handleChange={(e) => { this.handleChange('color', e.target.value); }}
                  columnName="color"
                  index={this.props.index}
                  errors={errors?.color}
                />
              </div>
            </div>
          </div>
          <div className="col-1 align-self-center">
            <div className="row">
              <a href="#"
                 title="Delete"
                 className="text-muted mx-2"
                 onClick={this.handleDelete}
                 data-confirm="Are you sure you want to delete the shortcut?">
                <i className="fas fa-trash"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
