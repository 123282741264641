import React from 'react';
import './section.scss';

const FormSection = ({ name, children }) => (
  <div className="row with-separator py-2 mx-2" data-test-form-section>
    <div className="col-3">
      <span className="text small-header" data-test-form-section-name>{name}</span>
    </div>
    <div className="col-9">
      {children}
    </div>
  </div>
);

export default FormSection;
