import { makeObservable, observable, action } from 'mobx';
import { ApolloClient, InMemoryCache } from '@apollo/client';
import { graphqlPath } from 'helpers/routes.js.erb';
import { ScheduleTypes } from './schedule-types';
import Operations from '../../../graphql/schedule-items.graphql';
import { QueryData, MutateData } from '../../../graphql/appolo-client';
import moment from 'moment';

export class SchedulesStore {
  currentScheduleType = ScheduleTypes[0];
  schedules = [];

  constructor(clientId) {
    this.clientId = clientId;
    this.appoloClient = new ApolloClient({
      uri: graphqlPath(),
      cache: new InMemoryCache(),
    });
    this.dates = {
      startDate: moment().subtract(1, 'year'),
      endDate: moment(),
    };

    makeObservable(this, {
      loadSchedules: action.bound,
      currentScheduleType: observable,
      schedules: observable,
      dates: observable,
      selectType: action.bound,
      createItem: action.bound,
      updateItem: action.bound,
      deleteItem: action.bound,
    });

    this.loadSchedules();
  }

  async loadSchedules() {
    const params = {
      clientId: this.clientId,
      scheduleType: this.currentScheduleType.name,
      startDate: this.dates.startDate.format('YYYY-MM-DD'),
      endDate: this.dates.endDate.format('YYYY-MM-DD'),
    };

    const response = await QueryData(Operations.GetScheduleItems, params);
    this.schedules = response.data.client.scheduleItems.map((i) => ({ key: i.id, ...i }));

    return response;
  }

  selectType(typeName) {
    this.currentScheduleType = ScheduleTypes.find((t) => t.name === typeName);
    this.loadSchedules();
  }

  async createItem(itemData) {
    const params = {
      attributes: { ...itemData, scheduleType: this.currentScheduleType.name },
      clientId: this.clientId,
    };
    const response = await MutateData(Operations.CreateScheduleItem, params);
    await this.loadSchedules();

    return response;
  }

  async updateItem(item, values) {
    const params = {
      attributes: { ...values, scheduleType: item.scheduleType },
      scheduleId: item.id,
    };
    const response = await MutateData(Operations.UpdateScheduleItem, params);
    await this.loadSchedules();

    return response;
  }

  async setDates(startDate, endDate) {
    this.dates = { startDate, endDate };
    await this.loadSchedules();
  }

  async deleteItem(id) {
    const response = await MutateData(Operations.DeleteScheduleItem, { scheduleId: id });
    await this.loadSchedules();

    return response;
  }
}
