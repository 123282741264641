import axios from 'axios';

class VendorTransactions {
  constructor() {
    this.loadedDataParams = null;
  }

  async init() {
    $('[data-load-txs-btn]').on('click', async (e) => {
      e.preventDefault();

      await this.toggleTransactions($(e.target));
    })
  }

  toggleTransactions = async (btn) => {
    const queryParams = this.queryParamsFromBtn(btn);
    const txsWrapperEl = btn.parents('tr').next('[data-transactions-wrapper]');

    if (JSON.stringify(this.loadedDataParams) !== JSON.stringify(queryParams)) {
      const txsData = await this.loadTransactions(btn.data('url'), queryParams);
      this.loadedDataParams = queryParams;

      txsWrapperEl.html(this.formatData(txsData));
      txsWrapperEl.removeClass('hidden');

    } else {
      this.loadedDataParams = null;
      txsWrapperEl.html('');
      txsWrapperEl.addClass('hidden');
    }
  }

  queryParamsFromBtn = (btn) => {
    return {
      vendor_name: btn.data('vendor-name'),
      account_name: btn.data('account-name'),
      start_date: btn.data('start-date'),
      end_date: btn.data('end-date'),
    }
  }

  loadTransactions = async (url, params) => {
    const response = await axios.get(url, {
      params: params
    })

    return response.data;
  }

  formatData = (data) => {
    const headerRow = `<tr>
      <td>Vendor Name</td>
      <td>Name</td>
      <td>Description</td>
      <td>Account</td>
      <td>Date</td>
      <td>Type</td>
      <td>Amount</td>
    </tr>`;

    const formatted = data.map((tx) => {
      return `<tr>
        <td>${tx.vendor ? tx.vendor: ''}</td>
        <td>${tx.name ? tx.name: ''}</td>
        <td>${tx.description}</td>
        <td>${tx.account}</td>
        <td>${tx.date}</td>
        <td>${tx.transaction_type}</td>
        <td>$${tx.amount}</td>
      </tr>`;
    });

    const formattedTxs = [headerRow, formatted].flat().join(' ');

    return `<td colspan="7">
      <div class="table-responsive">
        <table class="table table-striped">
          ${formattedTxs}
        </table>
      </div>
    </td>`
  }
}

window.addEventListener('turbolinks:load', async () => {
  if ($('#vendor-variance-page').length) {
    const txs = new VendorTransactions();
    await txs.init();
  }
})
