import axios from 'axios'

window.axiosPendingRequestsCount = 0

axios.interceptors.request.use(config => {
    window.axiosPendingRequestsCount++;

    return config;
  },
  Promise.reject
)

axios.interceptors.response.use(
  response => {
    window.axiosPendingRequestsCount--;

    return response
  },
  error => {
    window.axiosPendingRequestsCount--;

    return Promise.reject(error);
  }
)
