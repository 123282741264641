import Axios from 'axios';

import {
  dismissAllNotificationsPath,
  dismissNotificationPath,
} from 'helpers/routes.js.erb';

function leaveNotificationWindowOpen() {
  // Leave dropdown open when clicked to the item
  const dropdown = $('#notification-dropdown');

  $('#notification-window').on('click', (event) => {
    dropdown.slideToggle();
    event.stopPropagation();
  });

  dropdown.on('click', (event) => {
    event.stopPropagation();
  });

  $(window).on('click', () => {
    dropdown.slideUp();
  });
}

function showFullNotification() {
  $('.notification-btn').unbind('click').on('click', function () {
    const parent = $(this).parent('.notification-content-item');

    parent.find('.notification-message').toggleClass('notifications-content-message');
    parent.find('button').toggleClass('d-none');
  });
}

function dismissNotification() {
  $('.dismiss-item').click(function () {
    const notification = $(this);
    const notificationId = notification.data('notification-id');
    const notificationPath = dismissNotificationPath(notificationId);

    Axios.post(notificationPath).then((content) => {
      const block = notification.parents('.notification-item');

      block.html(content.data);
      showFullNotification();
    });
  });
}

function dismissAllNotification() {
  $('#dismiss-all').click(() => {
    Axios.post(dismissAllNotificationsPath())
      .then((content) => {
        $('#notifications-body').html(content.data);
        showFullNotification();
      });
  });
}

window.addEventListener('turbolinks:load', () => {
  leaveNotificationWindowOpen();
  dismissNotification();
  dismissAllNotification();
  showFullNotification();
});
