import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Table } from 'antd';
import { observer } from 'mobx-react-lite';
import { FeeIncreaseReportsStore } from './stores/fee-increase-reports.store';
import { toJS } from 'mobx';
import { FeeIncreaseReportStatus } from './components/status';
import { FeeIncreaseReportsHeader } from './components/header';
import { NotificationStore } from '../common-stores/notification.store';
import { Notification } from '../ui-kit/notification';

const { Column } = Table;

export const FeeIncreaseReportsIndex = observer(({ startDate, clientId, notificationStore, userRoles }) => {
  const [store] = useState(() => new FeeIncreaseReportsStore(startDate, clientId, notificationStore, userRoles));
  useEffect(() => {
    store.load();

  }, []);

  return (
    <>
      <Notification {...notificationStore.data} onClose={notificationStore.cleanup}/>
      <Table
        rowKey='id'
        loading={store.isLoading}
        pagination={false}
        title={() => <FeeIncreaseReportsHeader store={store}/>}
        dataSource={toJS(store.reports)}
      >
        <Column title='Report' dataIndex='dates_range'/>
        <Column
          render={(user) => `${user.email}`}
          title='Generated By'
          dataIndex='generated_by'
        />
        <Column
          title='Status'
          dataIndex='status'
          render={(value) => <FeeIncreaseReportStatus value={value}/>}
        />
        <Column
          render={(url) => (url ? <a href={url}>Download</a> : null)}
          title='Actions'
          dataIndex='url'
        />
      </Table>
    </>
  );
});

window.addEventListener('turbolinks:load', async () => {
  const rootNode = document.getElementById('fee-increase-reports-page');

  const clientId = document.getElementById('client-id')?.value;
  const startDate = document.getElementById('incorporation-date')?.value;
  const roles = document.getElementById('user-roles')?.value;

  if (rootNode && clientId) {
    const notificationStore = new NotificationStore();
    const userRoles = JSON.parse(roles);

    ReactDOM.render(
      <FeeIncreaseReportsIndex startDate={startDate} clientId={clientId} notificationStore={notificationStore} userRoles={userRoles}/>,
      rootNode,
    );
  }
});
