function crateAddLinkHandler(env, tokenUrl, responseLink, webhook, selectAccount) {
  return Plaid.create({
    env,
    selectAccount,
    token: responseLink,
    webhook,
    onSuccess(publicToken, metadata) {
      $.post(tokenUrl, {
        publicToken,
        metadata,
      }).always(() => {
        location.reload();
      });
    },
  });
}

function createLinkToken(env, tokenUrl, linkUrl, webhook, selectAccount) {
  $.ajax({
    url: linkUrl,
    type: 'GET',
    success(responseLink) {
      crateAddLinkHandler(env, tokenUrl, responseLink, webhook, selectAccount).open();
    },
  });
}

function updateItem(url) {
  $.ajax({
    url,
    data: {
      plaid_item: {
        need_update: false,
      },
    },
    method: 'PUT',
  }).done(() => {
    location.reload();
  });
}

function createUpdateLinkHandler(env, token, updateUrl) {
  return Plaid.create({
    env,
    token,
    onSuccess() {
      updateItem(updateUrl);
    },
    onEvent(eventName, metadata) {
      if (eventName === 'EXIT' && metadata.error_code === 'item-no-error') {
        updateItem(updateUrl);
      }
    },
  });
}

function showUpdateDialog(dataset) {
  $.get(dataset.tokenUrl)
    .done((data) => {
      const { token } = data;
      createUpdateLinkHandler(
        dataset.env,
        token,
        dataset.updateUrl,
      ).open();
    });
}

document.addEventListener('turbolinks:load', () => {
  $('.plaid-link-btn').click((event) => {
    event.preventDefault();
    const { dataset } = event.target;
    createLinkToken(
      dataset.env,
      dataset.tokenUrl,
      dataset.linkUrl,
      dataset.webhook,
      dataset.selectAccount === 'true',
    );
  });

  $('.plaid-link-update').click((event) => {
    event.preventDefault();
    showUpdateDialog(event.target.dataset);
  });
});
