import { DropZoneActiveStorage } from '../common/drop-zone/drop-zone-active-storage';
import MonthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';

function initNotesAndQuestionsUploaders() {
  if (document.getElementById('question-attachments-uploader')) {
    new DropZoneActiveStorage('question-attachments-uploader').init();
  }

  if (document.getElementById('note-attachments-uploader')) {
    new DropZoneActiveStorage('note-attachments-uploader').init();
  }
}

function ClearPreviewContainer(previewContainer) {
  if(previewContainer) {
    while(previewContainer.firstChild) {
      previewContainer.removeChild(previewContainer.firstChild);
    }
  }
}

function HandleUploadFiles(inpFile, clearBtn, previewContainer) {
  inpFile.addEventListener('change', function() {
    clearBtn.classList.remove('d-none');
    ClearPreviewContainer(previewContainer);

    this.files.forEach(file => {
      if (file) {
        const fileContainer = document.createElement('div');
        fileContainer.textContent = file.name;
        fileContainer.classList.add('n-and-q-comment-file-container');

        previewContainer.appendChild(fileContainer);
      }
    });
  });
}

function HandleDeleteFiles(inpFile, clearBtn, previewContainer) {
  clearBtn.addEventListener('click', function() {
    inpFile.value = '';
    ClearPreviewContainer(previewContainer);
    clearBtn.classList.add('d-none');
  });
}

function initCommentsUploader() {
  if (document.getElementById('n-and-q-comment-attachments')) {
    const inpFile = document.getElementById('n-and-q-comment-attachments');
    const previewContainer = document.getElementById('n-and-q-comment-files-preview');
    const clearBtn = document.getElementById('n-and-q-comment-files-clear');

    HandleUploadFiles(inpFile, clearBtn, previewContainer);
    HandleDeleteFiles(inpFile, clearBtn, previewContainer);
  }
}

function initToggleFiltersView(id) {
  $(id).on('click', () => {
    $('.n-and-q-simple-search').toggleClass('d-none');
    $('.n-and-q-advanced-search').toggleClass('d-none');
  });
}

function initToggleContentView() {
  $('.table-button').on('click', function () {
    const parent = $(this).parents('.n-and-q-row');

    parent.find('.n-and-q-table-content').toggleClass('n-and-q-table-content-all-text');
    parent.find('.table-button').toggleClass('d-none');
    parent.find('.n-and-q-table-attachments').toggleClass('d-none');
    parent.find('[n-and-q-table-td]').toggleClass('n-and-q-baseline-row');
  });
}

function initToggleAllComments() {
  $('.n-and-q-comments-toggle-btn').on('click', function () {
    $('.comments-and-history').toggleClass('d-none');
    $('.n-and-q-comments-toggle-btn').toggleClass('d-none');
    $('.n-and-q-clients-comment').toggleClass('d-none');
  })
}

window.addEventListener('turbolinks:load', () => {
  $('.n-and-q-datepicker').flatpickr({
    minDate: new Date('January 1, 2000'),
    plugins: [new MonthSelectPlugin({ shorthand: false, dateFormat: 'Y-m-d', altFormat: 'M Y' })],
    altInput: true,
    altFormat: 'F j, Y',
  });

  $('.select-reviewer').select2({
    theme: 'bootstrap',
  });

  $('.n-and-q-select').select2({
    theme: 'bootstrap',
    tags: true,
  });

  $('.tooltip-data').tooltip({
    html: true,
  });

  initToggleFiltersView('#n-and-q-advanced-filters');
  initToggleFiltersView('#n-and-q-simple-filters');
  initToggleContentView();
  initNotesAndQuestionsUploaders();
  initToggleAllComments();
  initCommentsUploader();
});
