import ReactDOM from 'react-dom';
import React, { useContext, useState } from 'react';
import { Row, Col, Modal, Button } from 'antd';
import { MappingsTable } from './components/mapings-table';
import { MappingsStore } from './stores/mappings-store';
import { observer } from 'mobx-react-lite';
import { MappingForm } from './components/mapping-form';

const MappingStoreContext = React.createContext({});

const ScheduleAccountMappings = observer(() => {
  const [editModalVisible, setEditModalVisible] = useState(false);
  const store = useContext(MappingStoreContext);
  const [formItem, setFormItem] = useState(null);

  const newItem = () => {
    setFormItem(null);
    setEditModalVisible(true);
  };

  const formSubmit = (params) => {
    if (formItem) {
      store.updateMapping(params, formItem.id);
    } else {
      store.createMapping(params);
    }

    setFormItem(null);
    setEditModalVisible(false);
  };

  const editItem = (item) => {
    setFormItem(item);
    setEditModalVisible(true);
  };

  const deleteItem = (item) => {
    Modal.confirm({
      title: 'Are you sure?',
      async onOk() {
        store.deleteMapping(item.id);
      },
    });
  };

  return (
    <Row>
      <Row>
        <Col span={24}>
          <Button type="primary" onClick={newItem}>Create</Button>
        </Col>
      </Row>
      <Col span={24}>
        <Row>
          <Col span={24}>
            <MappingsTable items={store.mappings} onEdit={ editItem } onDelete={ deleteItem }/>
          </Col>
          <Modal
            visible={editModalVisible}
            title="Create Schedule Account mapping"
            footer={null}
            onCancel={() => setEditModalVisible(false)}
          >
            <MappingForm onSubmit={ formSubmit } item={formItem}/>
          </Modal>
        </Row>
      </Col>
    </Row>
  );
});

window.addEventListener('turbolinks:load', async () => {
  const rootNode = document.getElementById('schedule-account-mappings');
  const clientId = document.getElementById('schedule-account-mappings-client-id')?.value;

  if (rootNode && clientId) {
    const mappingsStore = new MappingsStore(clientId);
    await mappingsStore.loadMappings();

    ReactDOM.render(
      <MappingStoreContext.Provider value={ mappingsStore }>
        <ScheduleAccountMappings/>
      </MappingStoreContext.Provider>,
      rootNode,
    );
  }
});
