import { makeObservable, observable, action } from 'mobx';
import axios from 'axios';
import {
  apiV1ClientTaxOptOutInfosPath,
  dataForSelectAPIV1ClientTaxOptOutInfosPath,
  apiV1ClientTaxOptOutInfoPath,
  clientPath,
} from 'helpers/routes.js.erb';

export class TaxOptOutStore {
  taxData = {
    year: null,
    do_taxes: null,
    reason: null,
    date_of_opt_out: null,
    zendesk_link: null,
    user_id: null,
  };
  users = [];
  years = [];
  loading = false;
  redirectUrl = null;

  constructor(clientId, notificationStore, taxInfoId, isEdit) {
    this.clientId = clientId;
    this.notificationStore = notificationStore;
    this.taxInfoId = taxInfoId;
    this.isEdit = isEdit;

    makeObservable(this, {
      taxData: observable,
      loading: observable,
      years: observable,
      users: observable,
      redirectUrl: observable,
      loadTaxData: action,
      loadDataForSelect: action,
      saveChanges: action,
    });
  }

  async loadTaxData() {
    const response = await axios.get(apiV1ClientTaxOptOutInfoPath(this.clientId, this.taxInfoId));

    if (response.data) {
      this.taxData = response.data;
    }
  }

  async loadDataForSelect() {
    this.loading = true;
    const response = await axios.get(dataForSelectAPIV1ClientTaxOptOutInfosPath(this.clientId));

    this.users = response.data.users;
    this.years = response.data.years;
    this.loading = false;
  }

  async saveChanges(values) {
    const params = {
      user_id: values.user_id,
      do_taxes: values.do_taxes,
      reason: values.reason,
      date_of_opt_out: values.date_of_opt_out ? values.date_of_opt_out.format() : null,
      zendesk_link: values.zendesk_link,
      year: values.year,
    };

    const response = this.isEdit
      ? await axios.patch(apiV1ClientTaxOptOutInfoPath(this.clientId, this.taxInfoId), params)
      : await axios.post(apiV1ClientTaxOptOutInfosPath(this.clientId), params);

    const { data } = response;

    if (data.errors) {
      this.notificationStore.create({
        header: 'Something wrong. Please try again!',
        message: data.errors,
        type: 'error',
      });
    } else {
      this.notificationStore.create({
        header: 'Success',
        message: 'Tax opt out info saved!',
        type: 'success',
      });
      this.taxData = data;
    }
  }

  async delete() {
    const response = await axios.delete(apiV1ClientTaxOptOutInfoPath(this.clientId, this.taxInfoId));

    const { data } = response;

    if (data.errors) {
      this.notificationStore.create({
        header: 'Something wrong.',
        message: data.errors,
        type: 'error',
      });
    } else {
      this.redirectUrl = clientPath(this.clientId);
    }
  }
}
