import React from 'react';

export default (props) => {
  return <div className="legend">
    {props.items.map((item, index) => {
      return(
        <div className="legend-item" data-test-legend-item key={index}>
          <div className="dot" style={{ backgroundColor: item.backgroundColor }}/>
          <div className="label">{item.label}</div>
        </div>
      );
    })}
  </div>;
};
