import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Nav } from './components/nav/nav';
import { Payroll } from './components/payroll';
import { Tab } from './components/tab';
import { TabForm } from './components/tab-form';
import { TabsNavigationStore } from './stores/tabs-navigation.store';
import { NotificationStore } from '../common-stores/notification.store';
import { YearsSelectionStore } from './stores/years-selection.store';
import { YearSelector } from './components/year-selector';
import { Space } from 'antd';
import { VendorsTotal } from './components/vendors-total';
import { Summary } from './components/summary';
import { observer } from 'mobx-react-lite';
import { clientRAndDPath } from 'helpers/routes.js.erb';
import 'antd/dist/antd.css';

import {
  BrowserRouter as Router,
  Route,
  Switch,
} from 'react-router-dom';

const RAndDShow = observer((
  {
    clientId,
    clientName,
    tabsNavigationStore,
    notificationStore,
    yearsSelectionStore,
    userRoles,
  },
) => {
  const pathName = clientRAndDPath(clientId);

  useEffect(() => {
    tabsNavigationStore.loadTabs();
  }, []);

  return (
    <Router>
      <div className='card'>
        <div className='card-body'>
          <Space>
            <h1 className='m-0'>R&D</h1>
            <YearSelector yearsSelectionStore={yearsSelectionStore}/>
          </Space>
          <Nav pathName={pathName} tabs={[...tabsNavigationStore.tabs]} userRoles={userRoles}/>

          <Switch>
            <Route exact path={pathName}>
              <Payroll
                clientId={clientId}
                notificationStore={notificationStore}
                selectedYear={yearsSelectionStore.selectedYear}
              />
            </Route>
            {tabsNavigationStore.tabs.map((tab) => (
              <Route key={tab.id} path={`${pathName}/tabs/${tab.id}`}>
                <Tab
                  tabData={tab}
                  {...tab}
                  clientId={clientId}
                  clientName={clientName}
                  notificationStore={notificationStore}
                  navigationStore={tabsNavigationStore}
                  selectedYear={yearsSelectionStore.selectedYear}
                  pathName={pathName}
                  userRoles={userRoles}
                />
              </Route>
            ))}

            {tabsNavigationStore.tabs.map((tab) => (
              <Route key={`${tab.name}-${tab.id}`} path={`${pathName}/tabs/edit/${tab.id}`}>
                <TabForm
                  tabInfo={tab}
                  isEditing={true}
                  clientId={clientId}
                  clientName={clientName}
                  navigationStore={tabsNavigationStore}
                  notificationStore={notificationStore}
                  selectedYear={yearsSelectionStore.selectedYear}
                />
              </Route>
            ))}
            <Route path={`${pathName}/tabs/vendors_total`}>
              <VendorsTotal
                clientId={clientId}
                selectedYear={yearsSelectionStore.selectedYear}
                notificationStore={notificationStore}
                navigationStore={tabsNavigationStore}
              />
            </Route>

            <Route path={`${pathName}/tabs/summary`}>
              <Summary clientId={clientId} selectedYear={yearsSelectionStore.selectedYear} />
            </Route>

            <Route path={`${pathName}/tabs/new`}>
              <TabForm
                clientId={clientId}
                clientName={clientName}
                navigationStore={tabsNavigationStore}
                notificationStore={notificationStore}
                selectedYear={yearsSelectionStore.selectedYear}
              />
            </Route>
          </Switch>
        </div>
      </div>
    </Router>
  );
});

window.addEventListener('turbolinks:load', async () => {
  const rootNode = document.getElementById('r-and-d-show-page');
  const clientId = document.getElementById('rnd-client-id')?.value;
  const clientName = document.getElementById('rnd-client-name')?.value;
  const roles = document.getElementById('user-roles')?.value;

  if (rootNode && clientId && clientName) {
    const tabsNavigationStore = new TabsNavigationStore(clientId);
    const notificationStore = new NotificationStore();
    const yearsSelectionStore = new YearsSelectionStore();
    const userRoles = JSON.parse(roles);

    ReactDOM.render(
      <RAndDShow
        clientId={clientId}
        clientName={clientName}
        tabsNavigationStore={tabsNavigationStore}
        notificationStore={notificationStore}
        yearsSelectionStore={yearsSelectionStore}
        userRoles={userRoles}
      />,
      rootNode,
    );
  }
});
