function getRandomColor() {
  var trans = '0.5';
  var color = 'rgba(';
  for (var i = 0; i < 3; i++) {
    color += Math.floor(Math.random() * 255) + ',';
  }
  color += trans + ')';
  return color;
}

window.addEventListener('turbolinks:load', function (){
  results = document.getElementsByClassName('random-colors')
  for (var i=0, len=results.length|0; i<len; i=i+1|0) {
    results[i].style.backgroundColor = getRandomColor();
  }
})
