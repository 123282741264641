import { has } from 'lodash';

import AjaxLoaderImage from 'images/ajax-loader.gif';
import consumer from 'action-cable/consumer';

// TODO: Move the methods to helpers.
const generateVendor1099Review = (text, params = { loader: false }) => {
  const loaderImageTag = `<img src=${AjaxLoaderImage} alt="AJAX Loader"/>`;
  const innerText = params.loader ? `${text} ${loaderImageTag}` : text;

  return `<p id='js-generate-vendor-1099-review'>${innerText}</p>`;
};

const setGenerateVendor1099Review = (text, params = { loader: false }) => {
  const element = document.getElementById('js-generate-vendor-1099-review');

  element.outerHTML = generateVendor1099Review(text, params);
};

const receiveGenerateVendor1099Review = (data) => {
  if (!has(data, 'error') && !has(data, 'processing')) {
    window.location.reload(false);
  }

  if (has(data, 'error')) {
    setGenerateVendor1099Review(
      'The vendor 1099 review generation failed. Please refresh the page and try again.',
    );
  }

  if (has(data, 'processing')) {
    setGenerateVendor1099Review('Generating Vendor 1099 Review', { loader: true });
  }
};

// TODO: Create universal functions for typical operations. DRY!
window.addEventListener('turbolinks:load', () => {
  if (document.getElementById('js-vendor-1099-reviews-index')) {
    // TODO: Move the code of initialize flatpickr to file for initialize libs.
    $('.datepicker').flatpickr({
      minDate: new Date('January 1, 2000'),
      dateFormat: 'm/d/Y',
    });

    consumer.subscriptions.create(
      {
        channel: 'Vendor1099ReviewStatusesChannel',
        client_id: window.location.pathname.split('/')[2],
      },
      {
        received: (data) => receiveGenerateVendor1099Review(data),
      },
    );

    const form = document.getElementById('js-generate-vendor-1099-review-form');

    if (form) {
      form.onsubmit = (e) => {
        e.preventDefault();

        const { srcElement } = e;
        const xhr = new XMLHttpRequest();

        xhr.open('POST', srcElement.action, true);
        xhr.send(new FormData(srcElement));

        setGenerateVendor1099Review('Vendor 1099 Review queued for generation.');
      };
    }
  }
});
