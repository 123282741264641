import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';

import ContactsStore from './contacts-store.jsx';
import List from './list.jsx';

window.addEventListener('turbolinks:load', async () => {
  const rootElement = document.getElementById('person-of-contacts-edit');
  const clientId = document.getElementById('client_id')?.value;

  if (!rootElement){
    return;
  }
  const store = new ContactsStore(clientId);
  await store.initialize();

  ReactDOM.render(
    <Provider store={store}>
      <List/>
    </Provider>,
    rootElement,
  );
});
