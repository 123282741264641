import { ApolloClient, InMemoryCache } from '@apollo/client';
import { graphqlPath } from 'helpers/routes.js.erb';
import { QueryData, MutateData } from '../../../graphql/appolo-client';
import { GetScheduleMappings, CreateScheduleMapping, UpdateScheduleMapping, DeleteScheduleMapping } from '../../../graphql/schedule-mappings.graphql';
import { action, makeObservable, observable } from "mobx";

export class MappingsStore {
  constructor(clientId) {
    this.clientId = clientId;
    this.appoloClient = new ApolloClient({
      uri: graphqlPath(),
      cache: new InMemoryCache(),
    });
    this.mappings = [];

    makeObservable(this, {
      mappings: observable,
      loadMappings: action.bound,
      createMapping: action.bound,
      updateMapping: action.bound,
      deleteMapping: action.bound,
    });
  }

  async loadMappings() {
    const params = {
      clientId: this.clientId,
    };

    const response = await QueryData(GetScheduleMappings, params);
    this.mappings = response.data.client.scheduleAccountMappings;
  }

  async createMapping(data) {
    const params = {
      attributes: data,
      clientId: this.clientId,
    };

    const response = await MutateData(CreateScheduleMapping, params);
    await this.loadMappings();

    return response.data;
  }

  async updateMapping(data, mappingId) {
    const params = {
      attributes: data,
      mappingId,
    };

    const response = await MutateData(UpdateScheduleMapping, params);
    await this.loadMappings();

    return response.data;
  }

  async deleteMapping(mappingId) {
    const response = await MutateData(DeleteScheduleMapping, { mappingId });
    await this.loadMappings();

    return response.data;
  }
}
