import Axios from 'axios';

import { keys } from 'lodash';
import { apiV1ClientFinancialModelPath } from 'helpers/routes.js.erb';

const moneyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency', currency: 'USD',
});

export function updateItemData(clientId, changes) {
  keys(changes).forEach(async (itemId) => {
    const url = apiV1ClientFinancialModelPath(clientId, itemId);

    await Axios.patch(url, { changes: changes[itemId] });
  });
}

export function normalizeValue(value) {
  let res = value;

  res = res.replace('$', '');
  res = res.replace(',', '');

  return res;
}

export function formatMoneyText(text) {
  return moneyFormatter.format(text);
}
