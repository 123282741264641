import consumer from '../action-cable/consumer';

class VersionStatusTracking {
  constructor(clientId) {
    this.clientId = clientId;
    this.newVersionBtn = $('[data-md-and-a-new-version-btn]');
    this.updateTierBtn = $('[data-md-and-a-update-tier-btn]');
    this.actionsWrapper = $('[data-md-and-as-actions-wrapper]');
    this.delayWrapper = this.actionsWrapper.find('[data-md-and-a-delay-wrapper]');
    this.editDelayUrlTemplate = this.actionsWrapper.data('md-and-a-delay-path-template');
    this.updateTierUrlTemplate = this.actionsWrapper.data('md-and-a-edit-tier-path-template');
  }

  init = () => {
    const ctx = this;

    consumer.subscriptions.create({
      channel: 'MdAndAStatusChannel',
      client_id: this.clientId
    },
    {
      received: function (data) {
        const mdAndA = JSON.parse(data.object);
        const mdAndAWrapper = $(`[data-md-and-a-wrapper=${mdAndA.id}]`);
        const statusField = mdAndAWrapper.find('[data-md-and-a-status]');

        if (data.creation_failed) {
          const currentVersion = JSON.parse(data.current_version);

          return ctx.creationFailed(mdAndA, currentVersion);
        }

        if (!mdAndAWrapper.length) {
          const container = $('[data-md-and-as-container]');
          const template = ctx.rowTemplate(mdAndA);
          container.prepend(template);
          ctx.updateMdAndAsActions(mdAndA);
          $(`[data-md-and-a-wrapper=${mdAndA.id}]`).find('[data-md-and-a-actions]').append(ctx.newVersionBtn);
          ctx.newVersionBtn.removeClass('hidden');
          ctx.complete();

          return;
        }

        if (ctx.newVersionBtn.parents(`[data-md-and-a-wrapper=${mdAndA.id}]`).length) {
          ctx.newVersionBtn.addClass('hidden');
        }

        statusField.removeClass('badge-primary').addClass('badge-warning').html(mdAndA.status);
        ctx.inProgress();
      },
    });
  };

  creationFailed = (failedVersion, currentVersionObject) => {
    const failedVersionWrapper = $(`[data-md-and-a-wrapper=${failedVersion.id}]`);
    const alertWrapper = $('.notice');
    const currentVersionWrapper = $(`[data-md-and-a-wrapper=${currentVersionObject.id}]`);
    const statusField = currentVersionWrapper.find('[data-md-and-a-status]');

    statusField.removeClass('badge-warning').addClass('badge-primary').html(currentVersionObject.status);
    this.newVersionBtn.removeClass('hidden');
    failedVersionWrapper.remove();
    alertWrapper.removeClass('alert-info').addClass('alert-danger');
    alertWrapper.html('Something went wrong. Please contact #engineering team in the Slack.');
  }

  complete = () => {
    const alertWrapper = $('.notice');

    alertWrapper.removeClass('alert-info').addClass('alert-success');
    alertWrapper.html('New version successfully generated.');
  }

  inProgress = () => {
    $('.notice').html('The creation of a new MdAndA version is in progress. It may take a few minutes.');
  }

  rowTemplate = (mdAndA) => {
    return `<tr data-md-and-a-wrapper=${mdAndA.id}>
      <td>${mdAndA.name}</td>
      <td>${mdAndA.created_at}</td>
      <td><span class="badge badge-primary">${mdAndA.status}</span></td>
      <td>
        <div data-md-and-a-actions="true" class="btn-group">
          <a class="btn btn-primary" href="${mdAndA.url}" target="_blank">Open Spreadsheet</a>
          <a class="btn btn-primary" href="${window.location.href}/${mdAndA.id}">Settings</a> 
        </div>
      </td>
    </tr>`;
  }

  updateMdAndAsActions = (mdAndA) => {
    const updateTierUrl = this.urlFromTemplate(
      this.updateTierUrlTemplate, { md_and_a_id: mdAndA.id, client_id: this.clientId }
    );

    this.delayWrapper.html(this.delayTemplate(mdAndA));
    this.updateTierBtn.attr('href', updateTierUrl);
  }

  delayTemplate = (mdAndA) => {
    const url = this.urlFromTemplate(this.editDelayUrlTemplate, { md_and_a_id: mdAndA.id });

    return `<div class="alert alert-info d-flex justify-content-between align-items-center">
      <span>New version of MD&A will be created at: ${mdAndA.next_creation_date}</span>
      <a href="${url}" class="btn btn-primary">Delay</a>
    </div>`
  }

  urlFromTemplate = (template, params) => {
    let url = template;

    for (const [key, value] of Object.entries(params)) {
      url = url.replace(key, value);
    }

    return url;
  }
}

window.addEventListener('turbolinks:load', function () {
  const clientField = $('#md_and_a_client_id');

  if (clientField.length) {
    const statusTracking = new VersionStatusTracking(clientField.val());
    statusTracking.init();
  }
});
