import axios from 'axios';
import * as _ from 'lodash';
import { makeObservable, action, observable, computed } from 'mobx';
import {
  generalLedgerAutocompletePath,
  apiV1ClientRAndDTransactionsPath,
  apiV1ClientRAndDTabsPath,
  apiV1ClientRAndDTabPath,
  clientRAndDPath,
  apiV1ClientRAndDTabSaveTransactionsPath,
  apiV1ClientRAndDTabClearRndSelectionsPath,
  apiV1ClientRAndDTabUnmarkRndPath,
  apiV1ClientRAndDTabSaveAllocationsPath,
  apiV1ClientRAndDTabSavedTransactionsPath,
} from 'helpers/routes.js.erb';

export class TabStore {
  tabData = {
    name: null,
    accountsToInclude: null,
    vendorsToInclude: null,
    transactionTypesToInclude: null,
    isPreloadTab: false,
  };
  redirectUrl = null;
  transactions = [];
  selectedRowKeys = [];
  selectedRowToUpdate = [];
  loading = false;
  selectedYear = null;
  updateTabInfo = false;
  allocation = 0;

  constructor(clientId, clientName, notificationStore, userRoles) {
    this.clientId = clientId;
    this.clientName = clientName;
    this.notificationStore = notificationStore;
    this.userRoles = userRoles;

    makeObservable(this, {
      loading: observable,
      tabData: observable,
      allocation: observable,
      transactions: observable,
      selectedRowKeys: observable,
      selectedRowToUpdate: observable,
      selectedYear: observable,
      redirectUrl: observable,
      updateTabInfo: observable,
      total: computed,
      createNew: action,
      delete: action,
      edit: action,
      setYear: action,
      updateTransactionsPreview: action,
      update: action,
      loadTransactions: action,
      loadSavedTransactions: action,
      initTab: action,
      selectTransaction: action.bound,
      saveTransactions: action.bound,
      setTransactions: action,
      setAdditionalDataToTransactions: action,
      unmarkRnd: action,
      updateRndStatus: action,
      setTabData: action,
      formatLoadedTransactions: action,
      initPreloadTabs: action,
      setSelected: action,
      setTransactionsToUnmark: action,
      setAllocation: action,
      saveAllocation: action,
      selectCheckedTransactionsIdsToUnmark: action,
      selectCheckedTransactionsIdsToAllocation: action,
    });
  }

  setYear(year) {
    this.selectedYear = year;
  }

  async createNew(successCallback) {
    const response = await axios.post(
      apiV1ClientRAndDTabsPath(this.clientId), {
        tab: {
          name: this.tabData.name,
          vendors: this.tabData.vendorsToInclude,
          transaction_types: this.tabData.transactionTypesToInclude,
          accounts_to_include: this.tabData.accountsToInclude,
        },
      },
    );

    const { data } = response;

    if (data.errors) {
      this.tabData.errors = data.errors;
      this.notificationStore.create({
        header: 'Validation failed!',
        message: 'Some values incorrect',
        type: 'error',
      });
    } else {
      await this.saveTransactions(data.id);
      await successCallback(data);

      this.redirectUrl = `${clientRAndDPath(this.clientId)}/tabs/${data.id}`;
      this.notificationStore.create({ header: 'Success!', message: 'New Tab successfully created', type: 'success' });
    }
  }

  async delete(successCallback) {
    const tableName = this.tabData.name;
    const response = await axios.delete(apiV1ClientRAndDTabPath(this.clientId, this.tabData.id));

    const { data } = response;

    if (data.errors) {
      this.tabData.errors = data.errors;
      this.notificationStore.create({
        header: 'Validation failed!',
        message: 'Some values incorrect',
        type: 'error',
      });
    } else {
      this.redirectUrl = clientRAndDPath(this.clientId);
      await successCallback(this.tabData.id);

      this.notificationStore.create(
        { header: 'Success!', message: `${tableName} successfully deleted`, type: 'success' },
      );
    }
  }

  async edit(successCallback) {
    const response = await axios.put(
      apiV1ClientRAndDTabPath(this.clientId, this.tabData.id), {
        tab: {
          name: this.tabData.name,
          vendors: this.tabData.vendorsToInclude || [],
          transaction_types: this.tabData.transactionTypesToInclude || [],
          accounts_to_include: this.tabData.accountsToInclude || [],
        },
      },
    );

    const { data } = response;

    if (data.errors) {
      this.tabData.errors = data.errors;
      this.notificationStore.create({
        header: 'Validation failed!',
        message: 'Some values incorrect',
        type: 'error',
      });
    } else {
      await this.saveTransactions(data.id);
      const formatted = await this.formatLoadedTransactions();
      await this.updateRndStatus(formatted);
      await this.setTabData(data);

      this.redirectUrl = `${clientRAndDPath(this.clientId)}/tabs/${data.id}`;
      this.notificationStore.create(
        { header: 'Success!', message: `${data.name} successfully updated`, type: 'success' },
      );
      await successCallback(data);
    }
  }

  async formatLoadedTransactions() {
    const transactions = await this.loadTransactions();
    const formatted = transactions.map((tx) => {
      tx.key = tx.md5_hash;

      return tx;
    });

    return formatted;
  }

  async setTabData(tabInfo) {
    this.tabData = {
      id: tabInfo.id,
      name: tabInfo.name,
      vendorsToInclude: tabInfo.vendors,
      accountsToInclude: tabInfo.accounts_to_include,
      transactionTypesToInclude: tabInfo.transaction_types,
      isPreloadTab: this.checkTabName(tabInfo.name),
    };
  }

  checkTabName(name) {
    const isPreload = (name === 'Legal' || name === 'Contractors');

    return isPreload;
  }

  async initTab(tabInfo) {
    this.loading = true;
    await this.setTabData(tabInfo);

    if (this.tabData.isPreloadTab) {
      await this.initPreloadTabs();
    }

    const transactions = await this.loadTransactions();
    let savedTransactionsInfo = await this.loadSavedTransactions();

    if (transactions.length !== savedTransactionsInfo.length) {
      const txsToSync = this.transactionsToSync(transactions, savedTransactionsInfo);

      await this.saveTransactions(tabInfo.id, txsToSync);
      savedTransactionsInfo = await this.loadSavedTransactions();
    }

    this.setTransactions(this.setAdditionalDataToTransactions(transactions, savedTransactionsInfo), true);

    this.loading = false;
  }

  async update(key, value) {
    this.tabData[key] = value;

    if (key !== 'name') {
      await this.updateTransactionsPreview();
    }
  }

  async updateTransactionsPreview() {
    const { name, ...requiredParams } = this.tabData;

    if (!_.some(requiredParams)) {
      return;
    }

    this.loading = true;

    const formatted = await this.formatLoadedTransactions();

    this.setTransactions(formatted);
    this.loading = false;
  }

  initPreloadTabs() {
    const tabInfo = this.tabData;
    const conditions = !tabInfo.transactionTypesToInclude
      && !tabInfo.vendorsToInclude
      && !tabInfo.accountsToInclude
      && this.tabData.isPreloadTab;

    if (conditions) {
      this.redirectUrl = `${clientRAndDPath(this.clientId)}/tabs/edit/${tabInfo.id}`;
      this.notificationStore.create(
        { header: 'Filters is empty', message: 'Please, select filters.', type: 'warning' },
      );
    }
  }

  async loadTransactions() {
    const { data } = await axios.get(
      apiV1ClientRAndDTransactionsPath(this.clientId),
      {
        params: {
          selected_year: this.selectedYear,
          accounts_to_include: this.tabData.accountsToInclude,
          vendors: this.tabData.vendorsToInclude,
          transaction_types: this.tabData.transactionTypesToInclude,
        },
      },
    );

    return data;
  }

  async selectTransaction(selectedRowToUpdate) {
    this.selectedRowToUpdate = selectedRowToUpdate;
  }

  setAllocation(value) {
    this.allocation = value;
  }

  async saveAllocation() {
    await axios.post(apiV1ClientRAndDTabSaveAllocationsPath(this.clientId, this.tabData.id),
      {
        allocation: this.allocation,
        transaction_ids: this.selectCheckedTransactionsIdsToAllocation(),
      });
    this.updateTabInfo = true;
  }

  async autocomplete(params) {
    const response = await axios.post(
      generalLedgerAutocompletePath(),
      this.autocompleteParams(params),
    );

    return response.data.map((element) => ({ value: element, label: element }));
  }

  async saveTransactions(tabId, txsToSync) {
    await axios.post(
      apiV1ClientRAndDTabSaveTransactionsPath(this.clientId, tabId || this.tabData.id),
      {
        tab: {
          transactions_attributes: this.formattedTransactions(txsToSync),
        },
      },
    );

    if (txsToSync) {
      return;
    }

    this.updateTabInfo = true;
    this.notificationStore.create({ header: 'Success!', message: 'Transactions successfully saved', type: 'success' });
  }

  async updateRndStatus(transactions) {
    await axios.put(
      apiV1ClientRAndDTabClearRndSelectionsPath(this.clientId, this.tabData.id),
      {
        tab: {
          transactions_attributes: this.formattedTransactions(transactions),
        },
      },
    );
  }

  async unmarkRnd() {
    const tabId = this.tabData.id;

    await axios.post(
      apiV1ClientRAndDTabUnmarkRndPath(this.clientId, tabId),
      {
        transaction_ids: this.selectCheckedTransactionsIdsToUnmark(),
      },
    );

    this.updateTabInfo = true;
    this.notificationStore.create({ header: 'Success!', message: 'Transactions unmarked R&D', type: 'success' });
  }

  async setTransactionsToUnmark() {
    this.transactions.forEach((tx) => {
      tx.selected = this.selectedRowToUpdate.includes(tx.md5_hash);
    });
  }

  async loadSavedTransactions() {
    const { data } = await axios.get(
      apiV1ClientRAndDTabSavedTransactionsPath(this.clientId, this.tabData.id),
      {
        params: {
          selected_year: this.selectedYear,
        },
      },
    );

    return data;
  }

  selectCheckedTransactionsIdsToUnmark() {
    const ids = this.transactions.filter((trx) => this.selectedRowToUpdate.includes(trx.md5_hash)).map((trx) => trx.id);

    return ids;
  }

  selectCheckedTransactionsIdsToAllocation() {
    const ids = this.transactions
      .filter((trx) => (this.selectedRowToUpdate.includes(trx.md5_hash) || this.selectedRowKeys.includes(trx.md5_hash)))
      .map((trx) => trx.id);

    return ids;
  }

  autocompleteParams({ fieldName, value }) {
    return {
      query: value,
      field: fieldName,
      client_id: this.clientId,
    };
  }

  setTransactions(transactions, setSelected = false) {
    this.transactions = transactions;

    if (setSelected) {
      this.selectedRowKeys = this.transactions.filter((tx) => tx.selected).map((tx) => tx.md5_hash);
      this.selectedRowToUpdate = [];
    }
  }

  async setSelected() {
    this.transactions.forEach((tx) => {
      tx.selected = ((this.selectedRowToUpdate.includes(tx.md5_hash) || this.selectedRowKeys.includes(tx.md5_hash)));
    });
  }

  setAdditionalDataToTransactions(transactions, savedTransactions) {
    savedTransactions.forEach((savedTx) => {
      const found = transactions.find((tx) => savedTx.md5_hash === tx.md5_hash);

      if (!found) {
        return;
      }

      found.key = savedTx.md5_hash;
      found.id = savedTx.id;
      found.selected = savedTx.selected;
      found.chat = savedTx.chat;
      found.allocation = savedTx.allocation;
    });

    return transactions;
  }

  formattedTransactions(rawTxs) {
    const txsToFormat = rawTxs || this.transactions;

    return txsToFormat.map((tx) => ({
      id: tx.id,
      md5_hash: tx.md5_hash,
      selected: tx.selected,
      year: this.selectedYear,
      amount: tx.amount,
      vendor: tx.vendor || tx.name,
    }));
  }

  transactionsToSync(transactions, savedTransactions) {
    return transactions.filter((tx) => (
      !savedTransactions.find((savedTransaction) => savedTransaction.md5_hash === tx.md5_hash)
    ));
  }

  get total() {
    const selectedTxs = this.transactions.filter((tx) => tx.selected);

    return selectedTxs.reduce((accumulator, tx) => accumulator + parseFloat(tx.amount), 0).toFixed(2);
  }
}
