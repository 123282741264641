window.addEventListener('turbolinks:load', function () {
  if(document.getElementById("js-venture-debt-intro-email-new") != null) {
    $("#intro_email_names").on("keyup", function(event) {
      var to = $("#intro_email_names").val();
      $("#clientsBody").html(to);
      $("#clientsSubject").html(to);
    });

    $("#intro_email_company").on("keyup", function(event) {
      var company = $("#intro_email_company").val();
      $(".companyBody").html(company);
      $("#companySubject").html(company);
    });

    $("#intro_email_copy").on("keyup", function(event) {
      var copy = $("#intro_email_copy").val();
      $("#customerCopyBody").html(copy);
    });
  }
})
