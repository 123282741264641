document.addEventListener('turbolinks:load', function() {
  $('.plaid-show-accounts').click((event) => {
    event.preventDefault();

    $('#accountsModal .modal-body').text('Loading...');
    $('#accountsModal').modal();

    $.get(event.target.dataset.balancesLink)
      .done((data) => {
        let text = '';

        if (data.error_message !== undefined){
          text = data.error_message;
        } else {
          text = '<ul class="list-group">';
          data.forEach((account) => {
            text += '<li class="list-group-item">';
            text += `${account.name} - `;
            text += `<span class="badge badge-secondary">${account.mask}</span>`;
            text += '</li>';
          });
          text += '</ul>';
        }
        $('#accountsModal .modal-body').html(text);
      });
  });
});
