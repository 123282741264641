import React, { PureComponent } from 'react'

export default class CellEditor extends PureComponent {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this._input.focus();
  }

  handleChange(e) {
    this.props.onChange(e.target.value);
  }

  render() {
    const { value, onKeyDown, cell } = this.props;
    return (
      <input
        ref={input => {
          this._input = input
        }}
        type='number'
        min={0}
        defaultValue={cell.value}
        onChange={this.handleChange}
        onKeyDown={onKeyDown}
      />
    )
  }
}
