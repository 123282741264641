import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Table } from 'antd';
import { observer } from 'mobx-react-lite';
import { ExcelPacketsStore } from './stores/excel-packets.store';
import { toJS } from 'mobx';
import { PacketStatus } from './components/status';
import { PacketsHeader } from './components/header';
import { NotificationStore } from '../common-stores/notification.store';
import './styles/bootstrap-override.scss';
import { Notification } from '../ui-kit/notification';

const { Column } = Table;

export const ExcelPacketsIndex = observer(({ clientId, notificationStore, userRoles, isQboOutdated }) => {
  const [store] = useState(() => new ExcelPacketsStore(clientId, notificationStore, userRoles, isQboOutdated));

  useEffect(() => {
    store.load();

    return store.destroyWsSubscription;
  }, []);

  return (
    <>
      <Notification {...notificationStore.data} onClose={notificationStore.cleanup}/>
      <Table
        rowKey='id'
        loading={store.isLoading}
        pagination={false}
        title={() => <PacketsHeader store={store}/>}
        dataSource={toJS(store.packets)}
      >
        <Column className='test-tx-date' title='Packet' dataIndex='dates_range'/>
        <Column
          className='test-excel-packet-generated-by'
          render={(user) => `${user.email}`}
          title='Generated By'
          dataIndex='generated_by'
        />
        <Column className='test-tx-type' title='Created At' dataIndex='created_at'/>
        <Column
          className='test-tx-type'
          title='Alphabetical Order'
          dataIndex='alphabet_order_enabled'
          render={(value) => (value ? 'Yes' : 'No')}
        />
        <Column
          className='test-tx-type'
          title='Status'
          dataIndex='status'
          render={(value) => <PacketStatus value={value}/>}
        />
        <Column
          className='test-tx-vendor'
          render={(url) => (url ? <a href={url}>Download</a> : null)}
          title='Actions'
          dataIndex='url'
        />
      </Table>
    </>
  );
});

window.addEventListener('turbolinks:load', async () => {
  const rootNode = document.getElementById('excel-packets-page');
  const clientId = document.getElementById('client-id')?.value;
  const roles = document.getElementById('user-roles')?.value;
  const isClientQboOutdated = document.getElementById('is-qbo-outdated')?.value;

  if (rootNode && clientId) {
    const notificationStore = new NotificationStore();
    const userRoles = JSON.parse(roles);
    const isQboOutdated = JSON.parse(isClientQboOutdated);

    ReactDOM.render(
      <ExcelPacketsIndex clientId={clientId} notificationStore={notificationStore} userRoles={userRoles} isQboOutdated={isQboOutdated}/>,
      rootNode,
    );
  }
});
