import React, { Component } from 'react';
import Axios from 'axios';
import { inject, observer } from 'mobx-react';

import ListPanel from 'components/charts/list-panel';
import List from 'components/charts/pages/list';
import Cards from 'components/charts/pages/cards';

import { apiV1ClientChartPath, apiV1ClientChartsPath } from 'helpers/routes.js.erb';

@inject('chartsStore')
@observer
export default class IndexPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isCardView: false,
    };
  }

  componentDidMount() {
    this.loadCharts();
  }

  loadCharts = async () => {
    const url = apiV1ClientChartsPath(this.props.chartsStore.client.id);
    const request = await Axios.get(url);
    const charts = request.data;
    charts.forEach((chart) => {
      chart.options = JSON.parse(chart.options);
    });
    this.props.chartsStore.chartsList = charts;
  };

  setView = (view) => {
    this.setState({ isCardView: view === 'cards' });
  };

  deleteChart = async (chart) => {
    if (window.confirm('Are you sure?')) {
      const url = apiV1ClientChartPath(this.props.chartsStore.client.id, chart.id);
      await Axios.delete(url);
      this.loadCharts();
    }
  };

  exportPdf = () => {
    this.props.chartsStore.pdfExportMode = true;
  };

  render() {
    return (
      <>
        <ListPanel isCardView={this.state.isCardView} setView={this.setView} exportPdf={this.exportPdf} roles={this.props.chartsStore.userRoles}/>
        {this.state.isCardView ? (
          <Cards charts={this.props.chartsStore.chartsList} deleteChart={this.deleteChart} roles={this.props.chartsStore.userRoles}/>
        ) : (
          <List charts={this.props.chartsStore.chartsList} deleteChart={this.deleteChart} roles={this.props.chartsStore.userRoles}/>
        )}
      </>
    );
  }
}
