function ToggleSelectAll() {
  const element = document.getElementById('Presets')
  const valueOfSelect = element.value;
  const rolesByPreset = JSON.parse(element.dataset.rolesByPreset);

  uncheckAllCheckboxes()

  if (valueOfSelect == 'Select All') {
    $("input[type=checkbox]").prop('checked', true);
  } else {
    rolesByPreset[valueOfSelect].forEach( right => {
      document.getElementById(right).checked = true
    })
  }
}

function uncheckAllCheckboxes() {
  $("input[type=checkbox]").prop('checked', false);
}

export function initSelectPresets(className) {
  $(className).change(ToggleSelectAll);
};
