import { has } from 'lodash';

import AjaxLoaderImage from 'images/ajax-loader.gif';

const individualClientStatistic = (text, params = { loader: false }) => {
  const loaderImageTag = `<img src=${AjaxLoaderImage} alt="AJAX Loader"/>`;
  const innerText = params.loader ? `${text} ${loaderImageTag}` : text;

  return `<p id='js-generate-individual-client-statistic'>${innerText}</p>`;
};

const setIndividualClientStatistic = (text, params = { loader: false }) => {
  const button = document.getElementById('js-generate-individual-client-statistic');

  button.outerHTML = individualClientStatistic(text, params);
};

const receiveIndividualClientStatistic = (data) => {
  if (!has(data, 'error') && !has(data, 'processing')) window.location.reload();

  if (has(data, 'error')) {
    setIndividualClientStatistic(
      'Individual Client Statistic generation failed. Please refresh the page and try again.',
    );
  }

  if (has(data, 'processing')) {
    setIndividualClientStatistic('Generating Individual Client Statistic', { loader: true });
  }
};

// TODO: Create universal functions for typical operations. DRY!
const initIndividualClientStatistic = () => {
  const form = document.getElementById('js-generate-individual-client-statistic-form');

  App.IndividualClientStatisticStatuses = App.cable.subscriptions.create(
    {
      channel: 'IndividualClientStatisticStatusesChannel',
      client_id: window.location.pathname.split('/')[2],
    },
    {
      received: (data) => receiveIndividualClientStatistic(data),
    },
  );

  form.addEventListener('submit', (e) => {
    e.preventDefault();

    const { srcElement } = e;
    const xhr = new XMLHttpRequest();

    xhr.open('POST', srcElement.action, true);
    xhr.send(new FormData(srcElement));

    setIndividualClientStatistic('Individual Client Statistic queued for generation.');
  });
};

window.addEventListener('turbolinks:load', () => {
  if (document.getElementById('individual-client-statistic')) {
    initIndividualClientStatistic();
  }
});
