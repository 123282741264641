import toastr from 'toastr';

export const showFlash = (message, flashType = 'info') => (
  toastr[flashType](message)
);

export const moneyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});
