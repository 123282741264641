import React from 'react';

export function ModalHeader(props) {
  return (
    <>
      <h5 className="modal-title">{props.title}</h5>
      {props.showCloseButton
        && <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      }
    </>
  );
}
