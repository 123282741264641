import React, { useEffect } from 'react';

const FieldDate = (props) => {
  useEffect(() => {
    $(`#datepicker_input_${props.columnName}`).flatpickr({
      minDate: new Date('January 1, 2000'),
      dateFormat: 'Y-m-d',
      onChange(_, dateStr) {
        props.handleChange(props.columnName, dateStr);
      },
    });
  });

  return (
    <div className={`pb-2 d-flex flex-column ${props.className ? props.className : ''}`} data-test-form-field>
      <span className="text bold" data-test-form-field-name>{props.name}</span>

      <input className="ui-input datepicker" type="text" value={props.value == null ? '' : props.value}
        name={props.columnName}
        data-test-input="input"
        onChange={props.handleChange}
        id={`datepicker_input_${props.columnName}`} />
      {props.errors && <span className="validation-error">{props.errors.join(', ')}</span>}
    </div>
  );
};

export default FieldDate;
