import { Space, Table, Button, Modal } from 'antd';
import React, { useContext } from 'react';
import { TypeFields } from '../stores/schedule-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SchedulesStoreContext } from '../index';
import { observer } from 'mobx-react-lite';

export const SchedulesTable = observer(({ onEdit }) => {
  const defaultColumnWidth = 150;
  const actionsColumnWidth = 100;

  const schedulesStore = useContext(SchedulesStoreContext);

  const fieldInfos = schedulesStore.currentScheduleType.fields.map((field) => TypeFields.find((f) => f.name === field));
  const tableWidth = fieldInfos.reduce((sum, f) => sum + (f.width || defaultColumnWidth), 0) + actionsColumnWidth;

  const deleteItem = (item) => {
    Modal.confirm({
      title: 'Are you sure?',
      async onOk() {
        await schedulesStore.deleteItem(item.id);
      },
    });
  };

  const columns = fieldInfos.map((fieldInfo) => ({
    title: fieldInfo.title,
    dataIndex: fieldInfo.name,
    fixed: fieldInfo?.fixed,
    width: fieldInfo?.width,
    render: fieldInfo?.render,
  }));
  columns.push({
    title: 'Actions',
    fixed: 'right',
    width: actionsColumnWidth,
    render: (_, item) => <Space>
      <Button icon={<FontAwesomeIcon icon={['far', 'edit']}/>} onClick={() => onEdit(item)}/>
      <Button icon={<FontAwesomeIcon icon={['far', 'trash-alt']}/>} onClick={() => deleteItem(item)}/>
    </Space>,
  });

  return (
    <Table columns={columns} dataSource={schedulesStore.schedules} scroll={{ x: tableWidth }}/>
  );
});
