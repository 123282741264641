import React from 'react';

const CardWrapper = ({ children, footer, containerClassNames }) => (
  <div className={`row pb-3 ${containerClassNames}`}>
    <div className="col-12 mb-4">
      <div className="card">
        <div className="card-body">
          {children}
          { footer
            && <nav className="navbar fixed-bottom navbar-light bg-light justify-content-end">
              { footer }
            </nav>
          }
        </div>
      </div>
    </div>
  </div>
);

export default CardWrapper;
