import { has } from 'lodash';

import consumer from 'action-cable/consumer';

import AjaxLoaderImage from 'images/ajax-loader.gif';

import { PacketsChartImageUploadModal } from 'packets/chart_image_upload_modal';
import { PacketsChartImageShowModal } from 'packets/chart_image_show_modal';
import { shareWithEmailClientPacketPath }  from 'helpers/routes.js.erb';

const generatePacket = (text, params = { loader: false }) => {
  const loaderImageTag = `<img src=${AjaxLoaderImage} alt="AJAX Loader"/>`;
  const innerText = params.loader ? `${text} ${loaderImageTag}` : text;

  return `<p id='js-generate-packet'>${innerText}</p>`;
};

const setGeneratePacket = (text, params = { loader: false }) => {
  const element = document.getElementById('js-generate-packet');

  element.outerHTML = generatePacket(text, params);
};

const receiveGeneratePacket = (data) => {
  if (!has(data, 'error') && !has(data, 'processing')) {
    window.location.reload(false);
  }

  if (has(data, 'error')) {
    setGeneratePacket(
      'The packet generation failed. Please refresh the page and try again.',
    );
  }

  if (has(data, 'processing')) {
    setGeneratePacket('Generating Packet', { loader: true });
  }
};

function openShareModal() {
  const clientId = $(this).data('client');
  const packetId = $(this).data('packet');
  const userEmail = $(this).data('email');
  $('#shareEmailModal').modal('toggle');
  $('#email_address').val(userEmail);

  const path = shareWithEmailClientPacketPath(clientId, packetId);
  $('.js-share-form').attr('action', path);
}

function closeShareModal() {
  $('#shareEmailModal').modal('toggle');
}

// TODO: Create universal functions for typical operations. DRY!
window.addEventListener('turbolinks:load', () => {

  $('.js-share-button').on('click', openShareModal);
  $('.js-add-email-button').on('click', closeShareModal);


  if (document.getElementById('js-packets-index')) {
    // TODO: Move the code of initialize flatpickr to file for initialize libs.
    $('.datepicker').flatpickr({
      minDate: new Date('January 1, 2000'),
      dateFormat: 'm/d/Y',
    });

    consumer.subscriptions.create(
      {
        channel: 'PacketStatusesChannel',
        client_id: window.location.pathname.split('/')[2],
      },
      {
        received: (data) => receiveGeneratePacket(data),
      },
    );

    const packetForm = document.getElementById('js-generate-packet-form');

    // TODO: Move to function of listener.
    if (packetForm) {
      packetForm.onsubmit = (e) => {
        e.preventDefault();

        const { srcElement } = e;
        const xhr = new XMLHttpRequest();

        xhr.open('POST', srcElement.action, true);
        xhr.send(new FormData(srcElement));

        setGeneratePacket('Packet queued for generation.');
      };
    }

    new PacketsChartImageUploadModal(AjaxLoaderImage);

    $('[data-chart-image-id]').each((_, element) => {
      new PacketsChartImageShowModal(AjaxLoaderImage, element);
    });
  }
});
