import consumer from '../action-cable/consumer';

export class RuleKeeperStatusTracker {
  constructor() {
    this.progress = $('.current-progress');
    this.form = $('[data-rule-set-form]');
    this.reloadOnSyncStarted = this.form.data('reload-on-sync-process-started');
    this.ruleSetId = this.form.data('rule-set-id');
    this.outOfSync = ($('[data-keeper-status-state=out-of-sync]').length === 1);
    this.titleStatus = $('[data-keeper-status-title]');
    this.channelName = 'RuleSetStatusTrackerChannel';
  }

  init = async () => {
    this.createSubscription();
  }

  createSubscription() {
    const ctx = this;

    consumer.subscriptions.create({
      channel: this.channelName,
      rule_set_id: this.ruleSetId,
    },
    {
      received: this.received,
      connected() {
        if (ctx.outOfSync) {
          this.perform('current_status');
        }
      },
    });
  }

  received = (data) => {
    if ((data.finished === true) || this.reloadOnSyncStarted) {
      window.location.reload();
    } else {
      if (this.outOfSync && data.current_progress !== 0) {
        this.outOfSync = false;
        this.titleStatus.html('Sync In Progress');
      }

      this.progress.html(`${data.current_progress}`);
    }
  }
}

window.addEventListener('turbolinks:load', async () => {
  if ($('[data-rule-set-form]').length) {
    const statusTracker = new RuleKeeperStatusTracker();
    await statusTracker.init();
  }
});
