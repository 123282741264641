import Axios from 'axios';
import { observable, action, toJS, makeObservable } from 'mobx';
import { pullAt } from 'lodash';

import {
  apiV1ClientShortcutsPath,
  apiV1ClientShortcutPath,
  clientPath,
} from 'helpers/routes.js.erb';

class ShortcutsStore {
  shortcuts = [];

  constructor(clientId) {
    this.clientId = clientId;

    makeObservable(this, {
      shortcuts: observable,
      create: action,
      delete: action,
      update: action,
      save: action,
    });
  }

  async initialize() {
    const path = apiV1ClientShortcutsPath(this.clientId);

    const response = await Axios.get(path);
    this.shortcuts = response.data;
  }

  create() {
    this.shortcuts.push({
      id: 0,
      name: '',
      link: '',
      icon: '',
      color: '',
    });
  }

  async delete(index) {
    const shortcut = toJS(this.shortcuts)[index];

    if (shortcut.id !== 0) {
      await Axios.delete(apiV1ClientShortcutPath(this.clientId, shortcut.id));
    }
    const shortcutsList = toJS(this.shortcuts);
    pullAt(shortcutsList, index);
    this.shortcuts.replace(shortcutsList);
  }

  update(index, prop, value) {
    const shortcut = this.shortcuts[index];
    shortcut[prop] = value;
    shortcut.edited = true;
  }

  async save() {
    let successful = true;

    const newShortcuts = toJS(this.shortcuts);

    for (const result of await Promise.all(this.saveShortcut(newShortcuts))) {
      newShortcuts[result.index] = result.shortcut;
      const { errors } = result.shortcut;

      if (Object.keys(errors).length > 0) {
        successful = false;
      }
    }

    this.shortcuts = newShortcuts;

    if (successful) {
      window.location.href = clientPath(this.clientId);
    }
  }

  saveShortcut(shortcuts) {
    const promises = [];

    for (let i = 0; i < shortcuts.length; i += 1) {
      const shortcut = shortcuts[i];

      if (shortcuts[i].id > 0 && !shortcuts[i].edited) {
        continue;
      }
      promises.push(new Promise((resolve) => {
        Axios(this.makeSaveParams(shortcut)).then((result) => {
          resolve({ index: i, shortcut: result.data });
        });
      }));
    }

    return promises;
  }

  makeSaveParams(shortcut) {
    const isNew = shortcut.id === 0;

    if (isNew) {
      return {
        method: 'post',
        url: apiV1ClientShortcutsPath(this.clientId),
        data: shortcut,
      };
    }

    return {
      method: 'patch',
      url: apiV1ClientShortcutPath(this.clientId, shortcut.id),
      data: shortcut,
    };
  }
}

export default ShortcutsStore;
