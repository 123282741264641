import { Button, DatePicker, Space } from 'antd';
import React from 'react';
import { observer } from 'mobx-react-lite';

export const RAndDReportHeader = observer(({ store }) => {
  const canUserCreate = store.userRoles.financial_packets?.includes('create');

  if (!canUserCreate) {
    return <></>;
  }

  return (
    <>
      <Space>
        <DatePicker defaultValue={store.date} onChange={(date) => store.setDate(date)} picker="year" />
        <Button
          type='primary'
          disabled={!store.isAllowedToGenerate}
          loading={store.isGenerationInProgress}
          onClick={store.generate}
        >
          Generate Report
        </Button>
      </Space>
    </>
  );
});
