import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'mobx-react';

import ShortcutsStore from './shortcuts-store';
import List from './list';

window.addEventListener('turbolinks:load', async () => {
  const rootElement = document.getElementById('shortcuts');

  if (!rootElement) {
    return;
  }

  const clientId = document.getElementById('shortcuts').dataset.clientid;

  const store = new ShortcutsStore(clientId);
  await store.initialize();

  ReactDOM.render(
    <Provider store={store}>
      <List/>
    </Provider>,
    rootElement,
  );
});
