import React from 'react';

const UiButton = ({ hero, outline, onClick, type, icon, text }) => (
  <button
    className={`btn
        ${hero ? 'shadow-lg' : ''}
        btn-${outline ? 'outline-' : ''}${type || 'primary'}
        px-4 py-2`}
    onClick={onClick}>
    {icon ? <i className={icon}></i> : null}
    <span className={icon ? 'ml-2' : ''}>{text}</span>
  </button>
);

export default UiButton;
