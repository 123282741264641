import React, { useRef, useEffect } from 'react';
import { Form, Input, Button, Select } from 'antd';
import { ScheduleTypes } from '../../schedule-items/stores/schedule-types';

export const MappingForm = ({ onSubmit, item }) => {
  const formRef = useRef(null);
  useEffect(() => {
    formRef?.current.resetFields();
  }, [item]);

  return (
    <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} onFinish={onSubmit} initialValues={item} ref={formRef}>
      <Form.Item label={'Account name'} name={'accountName'} key={'accountName'}>
        <Input/>
      </Form.Item>
      <Form.Item label={'Schedule type'} name={'scheduleType'} key={'scheduleType'}>
        <Select>
          {ScheduleTypes.map((type) => <Select.Option value={type.name}>{type.title}</Select.Option>)}
        </Select>
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};
