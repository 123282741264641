import React from 'react';
import { inject } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NotificationTooltip from '../notification-tooltip.jsx';
import { Link } from 'react-router-dom';

@inject('chartsStore')
export default class List extends React.Component {
  constructor(props) {
    super(props);
    this.mainLocation = this.props.chartsStore.mainUrl;
  }

  editButton = (chart, userCanUpdate) => {
    if (userCanUpdate) {
      return (
        <>
          <div className="col-auto">
            <button type="button" className="btn btn-primary">
              <FontAwesomeIcon icon={['fas', 'pencil-alt']} className="mr-2"/>
              <Link to={`${this.mainLocation}/${chart.id}/edit`}>Edit</Link>
            </button>
          </div>
        </>
      )
    }
  }

  deleteButton = (chart, userCanDelete) => {
    if (userCanDelete) {
      return (
        <>
          <div className="col-auto">
            <button type="button" className="btn btn-danger"
                    onClick={() => this.props.deleteChart(chart)}>
              <FontAwesomeIcon icon={['fas', 'trash-alt']} className="mr-2"/>
              Delete
            </button>
          </div>
        </>
      )
    }
  }

  editButtons = (chart, userCanUpdate, userCanDelete) => {
    return (
      <>
        {this.editButton(chart, userCanUpdate)}
        {this.deleteButton(chart, userCanDelete)}
      </>
    )
  };

  render() {
    const userRoles = this.props.roles
    const userCanUpdate = userRoles['reporting']?.includes('update')
    const userCanDelete = userRoles['reporting']?.includes('delete')
    return (
      <>
        {this.props.charts.map((chart) => (
          <div className="card shadow-default my-2" key={chart.id}>
              <div className="card-body" data-test-chart-item="true">
                <div className="row">
                  <div className="col-6">
                    <div className="row">
                      <div className="col-6">
                        <h5 className="link" data-test-chart-name="true">
                          <Link to={`${this.mainLocation}/${chart.id}/`}>{chart.name}</Link>
                        </h5>
                      </div>
                      {chart.active_notifications.length > 0
                        && <div className="col-auto h3">
                          <NotificationTooltip chart={chart}/>
                        </div>
                      }
                    </div>
                  </div>
                  <div className="col-6">
                    <div className="row justify-content-end">
                      { chart.default_chart_id === null
                        ? this.editButtons(chart, userCanUpdate, userCanDelete)
                        : <div className="col-auto">
                            <span className="badge badge-secondary">Default chart</span>
                          </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
        ))}
      </>
    );
  }
}
