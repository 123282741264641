import React, { useEffect, useRef } from 'react';
import { Comment, Divider, Empty, Spin } from 'antd';

export const Messages = ({ messages, isLoading }) => {
  const lastMessageRef = useRef(null);

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView();
    }
  }, [messages]);

  if (isLoading) {
    return (
      <div className='chat-messages-container chat-data-centered'>
        <Spin/>
      </div>
    );
  }

  if (!isLoading && !messages.length) {
    return (
      <div className='chat-messages-container chat-data-centered'>
        <Empty/>
      </div>
    );
  }

  return (
    <div className='chat-messages-container'>
      {
        messages.map((message, index) => {
          const isLastMessage = messages.length - 1 === index;

          return (
            <div className='chat-message' key={message.id} ref={isLastMessage ? lastMessageRef : null}>
              <Comment
                actions={[]}
                author={message.author.name}
                avatar={''}
                content={message.body}
                datetime={message.created_at}
              />
              {isLastMessage ? <></> : <Divider style={{ margin: 0 }}/>}
            </div>
          );
        })
      }
    </div>
  );
};
