import React from "react";
import ReactDataSheet from 'react-datasheet';
import { inject } from "mobx-react";
import FinancialModelTableCreator from "../../../common/financial-model-table-creator";
import CellEditor from "../cell-editor";
import * as Helper from "../../../common/financial-model-helper";
import * as _ from "lodash";

@inject("store")
export default class FinancialModelPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: []
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    const service = new FinancialModelTableCreator(this.props.store.client.id);
    const data = await service.generateTable();
    this.initCalculations(data);
    this.setState({ data });
  };

  initCalculations = (data) => {
    data.forEach((row) => {
      if (row[0].data === null || row[0].data['totalsParent'] === undefined){
        return;
      }
      row.forEach((cell, index) => {
        if (index === 0){
          return;
        }
        this.calculateTotal(cell, data, index);
      });
    });
  };

  onCellsChanged = async (changes) => {
    const data = _.cloneDeep(this.state.data);
    changes.forEach(async ({ row, col, value }) => {
      const newValue = Helper.normalizeValue(value);
      data[row][col] = { ...data[row][col], value: newValue };
    });
    this.processCalculations(changes, data);
    await this.sendNewCellValue(changes, data);
    this.setState({ data });
  };

  processCalculations = (changes, data) => {
    changes.forEach((change) => {
      const parentName = change.cell.data['parent_name'];
      const totalsRow = data.find((row) => {
        return row[0].data !== undefined
          && row[0].data !== null
          && row[0].data.totalsParent !== undefined
          && row[0].data.totalsParent === parentName
      });
      this.calculateTotal(totalsRow[change.col], data, change.col);
    });
  };

  calculateTotal = (cell, data, col) => {
    const sourceRows = data.filter((c) => {
      const target = c[col];
      return target.data !== undefined
        && target.data !== null
        && target.data["parent_name"] !== undefined
        && target.data["parent_name"] === cell.exprData.parent
    });
    const values = sourceRows.map((row) => row[col].value);
    let value = 0;
    values.forEach((v) => {
      if (v !== null && v !== ""){
        value += parseFloat(v);
      }
    });
    cell.value = value;
  };

  sendNewCellValue = async (changes, data) => {
    const changesParams = _.groupBy(changes.map(c => {
      return {
        id: data[0][c.col].data.id,
        parent: data[c.row][c.col].data["parent_name"],
        account: data[c.row][c.col].data.title,
        value: Helper.normalizeValue(c.value)
      }
    }), "id");
    await Helper.updateItemData(this.props.store.client.id, changesParams);
  };


  cellValueRenderer = (cell) => {
    const value = cell.value;

    if (cell.readOnly){
      return value;
    } else {
      return value !== null && value !== "" ? Helper.formatMoneyText(cell.value) : cell.value;
    }
  };

  render() {
    return (
      <div className="row">
        <div className="col fm-table-container px-0">
          <ReactDataSheet
            data={this.state.data}
            valueRenderer={this.cellValueRenderer}
            onCellsChanged={this.onCellsChanged}
            dataEditor={CellEditor}
          />
        </div>
      </div>
    );
  }
}
