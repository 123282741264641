function SuggestionsMultiselect(selectId, suggestionUrl, matchedTermCounter) {
  $(selectId).selectize({
    valueField: 'value',
    labelField: 'value',
    searchField: 'value',
    options: [],
    plugins: ['remove_button'],
    create: false,
    delimiter: ';',
    maxItems: null,
    highlight: false,
    render: {
      option: function(item, escape) {
        return "<div class='container-fluid'>" +
        "<div class='row'>" +
            "<div class='col-sm'>" +
                formatSuggestionValue(item, escape) +
             "</div>" +
           "</div>" +
        "</div>"
      }
    },
    load: function(query, callback) {
      if (!query.length) return callback();

      var xhr = new XMLHttpRequest();

      xhr.open('GET', suggestionUrl(encodeURIComponent(query)), true);
      xhr.send();

      xhr.onreadystatechange = function () {
        if (xhr.readyState !== 4) return;

        var suggestions = JSON.parse(xhr.responseText);

        if (xhr.status === 200) {
          callback(suggestionsDataFormatter(suggestions))
        } else {
          callback();
        }
      }
    }
  });

  function suggestionsDataFormatter(suggestions) {
    if (matchedTermCounter) {
      return [{ value: suggestions.query, matchedCount: suggestions.amount}];
    } else {
      return suggestions.map(function(item) { return({ value: item}) });
    }
  }

  function formatSuggestionValue(item, escape) {
    if (matchedTermCounter) {
      return '<p>' + escape(item.value) + ' ( matched ' + item.matchedCount + ' transactions )' + '</p>'
    } else {
      return '<p>' + escape(item.value) + '</p>'
    }
  }
}

export default SuggestionsMultiselect;
