import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Row, Col, Input, Timeline, Button, Card, Spin, Empty } from 'antd';
import 'antd/dist/antd.css';
import { McpStore } from './stores/mcp.store';
import { McpTimelines } from './components/mcp-timelines';
import { observer } from 'mobx-react-lite';
import { McpModal } from './components/mcp-modal';
import { NotificationStore } from '../common-stores/notification.store';
import { Notification } from '../ui-kit/notification';

const McpShow = observer(({ store, notificationStore }) => {
  const { TextArea } = Input;

  useEffect(() => {
    async function init() {
      await store.loadMcpData();
    }

    init();
  }, []);

  return <Card title='Monthly Close Process Budget History' className='calc-card-vh'>
    {store.loading
      ? <Spin size="large" />
      : <Row gutter={[32, 16]}>
        <Col span={24} data-test='mcp-notification'>
          <Notification {...notificationStore.data} onClose={notificationStore.cleanup}/>
        </Col>
        <Col span={8}>
          <Row gutter={[16, 16]} justify="space-between">
            <Col span={24}>
              Current MCP Budget: <b>{store.mcpBudget || '-'}</b>
            </Col>
            <Col span={24}>
              Current MCP Budgeted Hours: <b>{store.mcpHours || '-'}</b>
            </Col>
            <Col span={8}>
              <Button type='primary' onClick={store.showModal}>Schedule Change to MCP Budget</Button>
            </Col>
          </Row>
        </Col>
        <Col span={16}>
          <Row gutter={[16, 16]} >
            <Col span={24} className='mcp-timeline calc-card-body-vh' >
              {store.historyItems.length === 0
                ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span>No changes yet</span>} />
                : <Timeline className='mt-2'>
                  {store.historyItems.map((item, index) => (
                    <McpTimelines
                      key={item.id}
                      item={item}
                      status={item.status}
                      store={store}
                      last={index === (store.historyItems.length - 1)}
                    />
                  ))}
                </Timeline>
              }
            </Col>
          </Row>
        </Col>
      </Row>
    }
    <McpModal store={store} TextArea={TextArea} isModalVisible={store.isModalVisible}/>
  </Card>;
});

window.addEventListener('turbolinks:load', async () => {
  const rootElement = document.getElementById('mcp-budget-show-page');
  const clientId = document.getElementById('mcp-client-id')?.value;

  if (rootElement && clientId) {
    const notificationStore = new NotificationStore();
    const store = new McpStore(clientId, notificationStore);

    ReactDOM.render(
      <McpShow store={store} notificationStore={notificationStore}/>,
      rootElement,
    );
  }
});
