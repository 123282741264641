import React, { useEffect, useState } from 'react';
import { TabStore } from '../stores/tab.store';
import CardWrapper from '../../ui-kit/card-wrapper';
import FormField from '../../ui-kit/form/field';
import UiButton from '../../ui-kit/button';
import { observer } from 'mobx-react-lite';
import { Table, Popover } from 'antd';
import { Redirect } from 'react-router-dom';
import { Notification } from '../../ui-kit/notification';

const { Column } = Table;

export const TabForm = observer((
  {
    navigationStore,
    clientId,
    clientName,
    notificationStore,
    selectedYear,
    tabInfo,
    isEditing,
    userRoles
  },
) => {
  const [store] = useState(() => new TabStore(clientId, clientName, notificationStore, selectedYear, userRoles));
  const { tabData } = store;

  useEffect(() => {
    async function init() {
      await store.setTabData(tabInfo);
    }

    if (isEditing) {
      init();
    }

    store.setYear(selectedYear);
    store.updateTransactionsPreview();
  }, [selectedYear]);

  if (store.redirectUrl) {
    const { redirectUrl } = store;

    return <Redirect to={redirectUrl}/>;
  }

  const saveButton = (
    <UiButton
      onClick={async (e) => {
        e.preventDefault();

        await store.createNew(navigationStore.add);
      }}
      hero={true}
      text={'Save'}
    />
  );

  const editButton = (
    <Popover
      placement='bottom'
      title='Attention!'
      content="If you removed R&D marked transactions, they'll be unmarked after update."
    >
      <button
        onClick={async (e) => {
          e.preventDefault();

          await store.edit(navigationStore.updateTabs);
        }}
        className='btn shadow-lg btn-primary'
      >Update</button>
    </Popover>
  );

  return (
    <>
      <Notification {...notificationStore.data} onClose={notificationStore.cleanup}/>
      <CardWrapper containerClassNames='mt-3'>
        <form>
          <FormField
            columnName='name'
            name='Tab name'
            value={tabData.name}
            errors={tabData.errors?.name}
            handleChange={(e) => store.update('name', e.target.value)}
            disabled = {(tabData.isPreloadTab) ? 'disabled' : ''}
          />

          <FormField
            type='autocomplete'
            columnName='accountsToInclude'
            name='Account to include'
            value={tabData.accountsToInclude}
            errors=''
            handleChange={(values) => store.update('accountsToInclude', values)}
            loadOptions={(value) => store.autocomplete({ fieldName: 'account', value })}
          />

          <FormField
            type='autocomplete'
            columnName='vendorsToInclude'
            name='Vendors to include'
            value={tabData.vendorsToInclude}
            errors=''
            handleChange={(values) => store.update('vendorsToInclude', values)}
            loadOptions={(value) => store.autocomplete({ fieldName: 'vendor', value })}
          />

          <FormField
            type='autocomplete'
            columnName='transactionTypesToInclude'
            name='Transaction types to include'
            value={tabData.transactionTypesToInclude}
            errors=''
            handleChange={(values) => store.update('transactionTypesToInclude', values)}
            loadOptions={(value) => store.autocomplete({ fieldName: 'transaction_type', value })}
          />

          { isEditing ? editButton : saveButton }
        </form>
      </CardWrapper>

      <h3>
        Table preview
      </h3>
      <Table rowClassName='test-tx-data' pagination={false} dataSource={store.transactions} scroll={{ y: 300 }} sticky>
        <Column className='test-tx-date' title='Date' dataIndex='date'/>
        <Column className='test-tx-account' title='Account' dataIndex='account'/>
        <Column className='test-tx-type' title='Transaction Type' dataIndex='transaction_type'/>
        <Column className='test-tx-vendor' title='Vendor name' dataIndex='vendor'/>
        <Column className='test-tx-name' title='Name' dataIndex='name'/>
        <Column className='test-tx-desc' title='Description' dataIndex='description'/>
        <Column className='test-tx-amount' title='Amount' dataIndex='amount'/>
      </Table>
    </>
  );
});
