import React from 'react';
import { Alert } from 'antd';

export const Notification = ({ header, message, type, classNames, onClose }) => {
  if (!message) {
    return <></>;
  }

  return (
    <div className={`row mt-3 ${classNames}`}>
      <div className="col">
        <Alert
          message={header}
          description={message}
          type={type}
          showIcon={true}
          closable
          onClose={onClose}
        />
      </div>
    </div>
  );
};
