import React from 'react';
import AsyncSelect from 'react-select/async';

function formatSelectedOptions(opts) {
  return opts?.map((el) => el.value);
}

export const FieldAutocomplete = (props) => {
  const values = props.value?.map((element) => ({ value: element, label: element }));

  return (<div
    className={`pb-2 d-flex flex-column flex-fill ${props.className ? props.className : ''}`}
    data-test-form-field={props.name}
  >
    <span className="text bold" data-test-form-field-name>{props.name}</span>
    <div data-test={props.columnName}>
      <AsyncSelect
        cacheOptions={true}
        value={values}
        onChange={(selectedElements) => props.handleChange(formatSelectedOptions(selectedElements))}
        isMulti={true}
        isClearable={true}
        noOptionsMessage={() => 'Type to search'}
        loadOptions={props.loadOptions}
        classNamePrefix={props.columnName}
      />
    </div>
  </div>);
};
