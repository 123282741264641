import { moneyFormatter } from '../../../helpers/index';

const ScheduleTypes = [
  {
    name: 'prepaid_expenses',
    title: 'Prepaid Expenses',
    type: 'Current Assets',
    fields: [
      'transactionName', 'notes', 'transactionDate', 'accountName', 'className', 'startDate', 'endDate',
      'amount', 'numberOfMonths', 'amountPerMonth',
    ],
  },
  {
    name: 'accrued_expenses',
    title: 'Accrued Expenses',
    type: 'Current Liabilities',
    fields: ['transactionName', 'notes', 'transactionDate', 'accountName', 'className', 'paidDate', 'amount'],
  },
  {
    name: 'fixed_assets',
    title: 'Fixed Assets',
    type: 'Fixed Assets',
    fields: [
      'transactionName', 'assetType', 'notes', 'transactionDate', 'accountName', 'className', 'salvageValue',
      'usefulLife', 'startDate', 'endDate', 'amount', 'numberOfMonths', 'amountPerMonth',
    ],
  },
  {
    name: 'payroll_receivable',
    title: 'Payroll Receivable',
    type: 'Current Assets',
    fields: ['transactionName', 'notes', 'transactionDate', 'accountName', 'className', 'paidDate', 'amount'],
  },
  {
    name: 'deferred_cost_of_revenue',
    title: 'Deferred Cost of Revenue',
    type: 'Current Assets',
    fields: [
      'transactionName', 'notes', 'invoiceNumber', 'transactionDate', 'accountName', 'className', 'startDate',
      'endDate', 'amount', 'numberOfMonths', 'amountPerMonth',
    ],
  },
  {
    name: 'accrued_revenue',
    title: 'Accrued Revenue',
    type: 'Current Assets',
    fields: [
      'transactionName', 'notes', 'transactionDate', 'accountName', 'className', 'paidDate', 'amount', 'numberOfMonths',
      'amountPerMonth',
    ],
  },
  {
    name: 'deferred_refunds',
    title: 'Deferred Refunds',
    type: 'Current Assets',
    fields: [
      'transactionName', 'notes', 'invoiceNumber', 'transactionDate', 'accountName', 'className', 'startDate',
      'endDate', 'amount', 'numberOfMonths', 'amountPerMonth',
    ],
  },
  {
    name: 'loan_to_shareholder',
    title: 'Loan to Shareholder',
    type: 'Current Assets',
    fields: ['transactionName', 'notes', 'transactionDate', 'paidDate', 'amount'],
  },
  {
    name: 'undeposited_funds',
    title: 'Undeposited Funds',
    type: 'Current Assets',
    fields: ['transactionName', 'notes', 'transactionDate', 'paidDate', 'amount'],
  },
  {
    name: 'inventory',
    title: 'Inventory',
    type: 'Current Assets',
    fields: ['transactionName', 'notes', 'transactionDate', 'paidDate', 'amount'],
  },
  {
    name: 'prepaid_rent',
    title: 'Prepaid Rent',
    type: 'Current Assets',
    fields: [
      'transactionName', 'notes', 'transactionDate', 'accountName', 'className', 'startDate',
      'endDate', 'amount', 'numberOfMonths', 'amountPerMonth',
    ],
  },
  {
    name: 'prepaid_benefits',
    title: 'Prepaid Benefits',
    type: 'Current Assets',
    fields: [
      'transactionName', 'notes', 'transactionDate', 'accountName', 'className', 'startDate',
      'endDate', 'amount', 'numberOfMonths', 'amountPerMonth',
    ],
  },
  {
    name: 'stripe_clearing',
    title: 'Stripe Clearing',
    type: 'Current Assets',
    fields: [
      'transactionName', 'notes', 'transactionDate', 'transactionType', 'accountName', 'className', 'amount',
      'feeAmount', 'netAmount',
    ],
  },
  {
    name: 'paypal_clearing',
    title: 'Paypal Clearing',
    type: 'Current Assets',
    fields: ['transactionName', 'notes', 'transactionDate', 'paidDate', 'amount'],
  },
  {
    name: 'other_clearing',
    title: 'Other Clearing',
    type: 'Current Assets',
    fields: ['transactionName', 'notes', 'transactionDate', 'paidDate', 'amount'],
  },
  {
    name: 'intangible_assets',
    title: 'Intangible Assets',
    type: 'Other Assets',
    fields: [
      'transactionName', 'assetType', 'notes', 'transactionDate', 'accountName', 'className', 'salvageValue',
      'usefulLife', 'startDate', 'endDate', 'amount', 'numberOfMonths', 'amountPerMonth',
    ],
  },
  {
    name: 'security_deposits',
    title: 'Security Deposits',
    type: 'Other Assets',
    fields: ['transactionName', 'notes', 'transactionDate', 'paidDate', 'amount'],
  },
  {
    name: 'interest_receivable',
    title: 'Interest Receivable',
    type: 'Other Assets',
    fields: ['transactionName', 'notes', 'transactionDate', 'paidDate', 'amount'],
  },
  {
    name: 'four_zero_one_k_payable',
    title: '401(K) Payable',
    type: 'Current Liabilities',
    fields: ['transactionName', 'notes', 'transactionDate', 'paidDate', 'amount'],
  },
  {
    name: 'loan_from_shareholder',
    title: 'Loan from Shareholder',
    type: 'Current Liabilities',
    fields: ['transactionName', 'notes', 'transactionDate', 'paidDate', 'amount'],
  },
  {
    name: 'payroll_liabilities',
    title: 'Payroll Liabilities',
    type: 'Current Liabilities',
    fields: ['transactionName', 'notes', 'transactionDate', 'paidDate', 'amount'],
  },
  {
    name: 'payroll_tax_liabilities',
    title: 'Payroll Tax Liabilities',
    type: 'Current Liabilities',
    fields: ['transactionName', 'notes', 'transactionDate', 'paidDate', 'amount'],
  },
  {
    name: 'deferred_revenue',
    title: 'Deferred Revenue',
    type: 'Current Liabilities',
    fields: [
      'transactionName', 'notes', 'invoiceNumber', 'transactionDate', 'accountName', 'className', 'startDate',
      'endDate', 'amount', 'numberOfMonths', 'amountPerMonth',
    ],
  },
  {
    name: 'sublease_deposit_prepaid_rent',
    title: 'Sublease Deposit & Prepaid Rent',
    type: 'Current Liabilities',
    fields: [
      'transactionName', 'notes', 'transactionDate', 'accountName', 'className', 'startDate', 'endDate', 'amount',
      'numberOfMonths', 'amountPerMonth',
    ],
  },
  {
    name: 'employee_reimbursement_liability',
    title: 'Employee Reimbursement liability',
    type: 'Current Liabilities',
    fields: ['transactionName', 'notes', 'transactionDate', 'paidDate', 'amount'],
  },
  {
    name: 'accrued_rent',
    title: 'Accrued Rent',
    type: 'Current Liabilities',
    fields: [
      'transactionName', 'notes', 'transactionDate', 'accountName', 'className', 'paidDate', 'amount',
    ],
  },
  {
    name: 'accrued_vacation',
    title: 'Accrued Vacation',
    type: 'Current Liabilities',
    fields: [
      'transactionName', 'notes', 'transactionDate', 'accountName', 'className', 'paidDate', 'amount',
    ],
  },
  {
    name: 'sales_tax_payable',
    title: 'Sales Tax Payable',
    type: 'Current Liabilities',
    fields: [
      'accrualMonth', 'quarter', 'state', 'notes', 'transactionDate', 'paidDate', 'amount',
    ],
  },
  {
    name: 'use_tax_payable',
    title: 'Use Tax Payable',
    type: 'Current Liabilities',
    fields: [
      'accrualMonth', 'quarter', 'state', 'notes', 'transactionDate', 'paidDate', 'amount',
    ],
  },
  {
    name: 'sf_gr_pr_payable',
    title: 'SF GR/PR Payable',
    type: 'Current Liabilities',
    fields: [
      'accrualMonth', 'quarter', 'notes', 'transactionDate', 'paidDate', 'amount',
    ],
  },
  {
    name: 'accrued_interest',
    title: 'Accrued Interest',
    type: 'Long-term Liabilities',
    fields: [
      'transactionName', 'notes', 'transactionDate', 'startDate', 'endDate', 'amount', 'annualInterest', 'dailyAmount',
      'dailyAccruedAmount',
    ],
  },
];

const moneyFormatterFunc = (value) => moneyFormatter.format(value);

const TypeFields = [
  { name: 'accountName', title: 'Account Name', type: 'text' },
  { name: 'amount', title: 'Amount', type: 'money', width: 150, render: moneyFormatterFunc },
  { name: 'annualInterest', title: 'Annual Interest', type: 'number' },
  { name: 'assetType', title: 'Asset Type', type: 'text' },
  { name: 'className', title: 'Class Name', type: 'text' },
  { name: 'endDate', title: 'End Date', type: 'date', width: 130 },
  { name: 'feeAmount', title: 'Fee Amount', type: 'money', render: moneyFormatterFunc },
  { name: 'invoiceNumber', title: 'Invoice Number', type: 'text' },
  { name: 'notes', title: 'Notes/description', type: 'text', width: 200 },
  { name: 'paidDate', title: 'Paid Date', type: 'date', width: 130 },
  { name: 'salvageValue', title: 'Salvage Value', type: 'money', render: moneyFormatterFunc },
  { name: 'startDate', title: 'Start Date', type: 'date', width: 130 },
  { name: 'state', title: 'State', type: 'text' },
  { name: 'transactionDate', title: 'Transaction Date', type: 'date', width: 130 },
  { name: 'transactionName', title: 'Transaction Name', type: 'text', fixed: 'left', width: 250 },
  { name: 'transactionType', title: 'Transaction Type', type: 'text' },
  { name: 'usefulLife', title: 'Useful Life', type: 'number' },
  { name: 'accrualMonth', title: 'Accrual Month', type: 'text', calculated: true },
  { name: 'quarter', title: 'Quarter/year', type: 'text', calculated: true },
  { name: 'netAmount', title: 'Net Amount', type: 'money', calculated: true, render: moneyFormatterFunc },
  { name: 'numberOfMonths', title: 'Number of Months', type: 'number', calculated: true, width: 200 },
  {
    name: 'amountPerMonth',
    title: 'Amount per month',
    type: 'money',
    calculated: true,
    width: 200,
    render: moneyFormatterFunc,
  },
  { name: 'dailyAmount', title: 'Daily Amount', type: 'money', calculated: true, render: moneyFormatterFunc },
  {
    name: 'dailyAccruedAmount',
    title: 'Total Daily Accrued amount',
    type: 'money',
    calculated: true,
    width: 250,
    render: moneyFormatterFunc,
  },
];

export { ScheduleTypes, TypeFields };
