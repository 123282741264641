import Axios from 'axios';
import moment from 'moment';

import { uniq, findIndex } from 'lodash';
import { apiV1ClientFinancialModelsPath } from 'helpers/routes.js.erb';

export default class FinancialModelTableCreator {
  constructor(clientId) {
    this.clientId = clientId;
    this.clientData = [];
    this.months = [];
    this.dataRowStartIndex = 1;
    this.dataColStartIndex = 1;
  }

  generateTable = async () => {
    await this.loadData(this.clientId);

    const data = [this.formatHeadingRow()];

    return data.concat(this.formatData());
  };

  loadData = async (clientId) => {
    const url = apiV1ClientFinancialModelsPath(clientId);
    const response = await Axios.get(url);

    this.clientData = response.data;

    this.months = this.clientData.map((d) => this.createCell(
      moment(d.month).format('MMM YYYY'),
      'sticked-top',
      true,
      {
        id: d.id,
        month: d.month,
      },
    ));
  };

  formatHeadingRow = () => this.createRow(this.createCell('', 'sticked-top', true), this.months);

  formatData = () => {
    const data = this.transpose(this.clientData.map((d) => d.data));
    const cells = this.createCellsFromData(data);

    const parentNames = uniq(this.clientData[0].data.map((d) => d.parent_name));
    parentNames.forEach((parentName) => {
      const index = findIndex(
        cells,
        (c) => c[this.dataRowStartIndex].data !== null && c[this.dataRowStartIndex].data.parent_name === parentName,
      );
      cells.splice(index, 0, this.parentHeading(parentName));
      const childCount = data.filter((d) => d[0].parent_name === parentName).length;
      cells.splice(index + childCount + 1, 0, this.totalsCells(parentName));
    });

    cells.splice(this.dataRowStartIndex - 1, 0, this.parentHeading(
      'Profit and Loss',
      'data-type-divider',
      'data-type-name',
    ));
    const bsDataFirstRowIndex = findIndex(cells, (row) => {
      const cell = row[this.dataColStartIndex];

      return cell.data !== null && cell.data.kind === 'balance_sheet';
    });
    cells.splice(bsDataFirstRowIndex - 1, 0, this.parentHeading(
      'Balance Sheet',
      'data-type-divider',
      'data-type-name',
    ));

    return cells;
  };

  parentHeading = (parentName, cellsClass = 'parent-divider', nameClass = 'parent-name') => {
    const months = this.months.map(() => this.createCell('', cellsClass, true));

    return this.createRow(this.createCell(parentName, nameClass, true), months);
  };

  totalsCells = (parentName) => {
    const totalsCells = this.months.map(
      (m) => this.createFormulaCell(0, { month: m, parent: parentName }),
    );
    const heading = this.createCell(
      `${parentName} Totals`,
      'totals-name',
      true,
      { totalsParent: parentName },
    );

    return this.createRow(heading, totalsCells);
  };

  createCellsFromData = (data) => data.map((row) => this.createRow(
    this.createCell(row[0].title, 'account-name', true),
    row.map((col) => this.createCell(col.value, 'account-value', false, col)),
  ));

  createCell = (value, className, readOnly = false, data = null) => ({
    value, readOnly, data, className,
  });

  createFormulaCell = (value, exprData) => ({
    value,
    readOnly: true,
    exprData,
    data: null,
    className: 'formula-cell',
  });

  createRow = (firstCell, otherCells) => {
    firstCell.className += ' sticked-left';

    return [firstCell].concat(otherCells);
  };

  transpose = (array) => {
    const [row] = array;

    return row.map((value, column) => array.map((row) => row[column]));
  };
}
