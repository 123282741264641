export function PacketsChartImageShowModal(loader_image_tag, divElement) {
  this.loader_image_tag = '<span class="loading">' + loader_image_tag + '</span>';

  this.div = $(divElement);
  this.$chartImageModal = $('#chart-image-show-modal-' + this.div.data('chart-image-id') + ' .modal-content');

  if(this.div.find('.small-chart-image').length < 1) {
    this._toggleButtonLoading();
    this._getChartImage();
  } else {
    this._setOnClickEvent();
  }
};

PacketsChartImageShowModal.prototype._getChartImage = function() {
  var ctx = this;

  var xhr = new XMLHttpRequest();
  var packetId = ctx.div.data('packet-id');
  var chartImageId = ctx.div.data('chart-image-id');

  xhr.open('GET', '/packets/' + packetId + '/chart_images/' + chartImageId, true);
  xhr.send(null);

  xhr.onreadystatechange = function() {
    if (xhr.readyState === 4) {
      if(xhr.status === 200) {
        ctx._onRequestSuccess(xhr.response);
      } else {
        ctx._onRequestFailure();
      }
    }
  }
};

PacketsChartImageShowModal.prototype._openModal = function () {
  $('#chart-image-show-modal-' +  this.div.data('chart-image-id')).modal({ show: true });
};

PacketsChartImageShowModal.prototype._onRequestSuccess = function(response) {
  var base64 = JSON.parse(response)['chart_image']['base64'];

  this.$chartImageModal.append('<img class="chart-image" src="' + base64 + '" />');
  this.div.append('<img class="small-chart-image" src="' + base64 + '" />')

  this._toggleButtonLoading();
  this._setOnClickEvent();
};

PacketsChartImageShowModal.prototype._setOnClickEvent = function() {
  var ctx = this;

  this.image = this.div.find('.small-chart-image').click(function () {
    ctx._openModal();
  });
}

PacketsChartImageShowModal.prototype._onRequestFailure = function() {
  this._toggleButtonLoading();

  alert('Something went wrong. Please reload the page and try again.');
};

PacketsChartImageShowModal.prototype._toggleButtonLoading = function() {
  if(this.div.find('.loading').length === 0) {
    this.div.append(this.loader_image_tag);
  } else {
    this.div.find('.loading').remove();
  }
};
