import { makeObservable, observable, action, computed } from 'mobx';
import axios from 'axios';
import {
  apiV1ClientRAndDPayslipsPath,
  bulkUpdateAPIV1ClientRAndDPayslipsPath,
  unmarkRndAPIV1ClientRAndDPayslipsPath,
} from 'helpers/routes.js.erb';

export class PayrollStore {
  loading = false;
  employees = [];
  selectedEmployeesIds = [];
  selectedEmployeesToUpdate = [];
  updateTabInfo = false;

  constructor(clientId, notificationStore) {
    this.clientId = clientId;
    this.notificationStore = notificationStore;

    makeObservable(this, {
      employees: observable,
      selectedEmployeesIds: observable,
      selectedEmployeesToUpdate: observable,
      loading: observable,
      updateTabInfo: observable,
      loadEmployees: action,
      setEmployees: action,
      formattedAllocations: action,
      selectEmployees: action.bound,
      setAllocation: action,
      setSelected: action,
      saveAllocations: action,
      unmarkRnd: action,
      selectedEmployees: computed,
      formattedSelectedEmployees: computed,
      total: computed,
    });
  }

  async loadEmployees(selectedYear) {
    this.loading = true;

    const url = apiV1ClientRAndDPayslipsPath(this.clientId);
    const result = await axios.get(url, {
      params: {
        selected_year: selectedYear,
      },
    });
    const { data } = result;

    this.setEmployees(data.employees);
    this.loading = false;
  }

  setEmployees(employees) {
    this.employees = employees.map((employee) => (
      {
        id: employee.id,
        salary: employee.payslips_year_total || 0,
        deductions: employee.payslips_deductions_total,
        firstName: employee.first_name,
        lastName: employee.last_name,
        title: employee.job_title,
        selected: employee.allocation_info.selected_for_rnd,
        allocationInfo: employee.allocation_info,
        chat: employee.allocation_info.chat,
      }
    ));

    this.selectedEmployeesIds = this.formattedSelectedEmployees;
    this.selectedEmployeesToUpdate = [];
  }

  selectEmployees(selectedEmployeesToUpdate) {
    this.selectedEmployeesToUpdate = selectedEmployeesToUpdate;
  }

  setAllocation({ index, value }) {
    this.employees[index].allocationInfo.allocation = value;
  }

  async setSelected() {
    this.employees.map((employee) => {
      employee.selected = (
        this.selectedEmployeesToUpdate.includes(employee.id) || this.selectedEmployeesIds.includes(employee.id)
      );

      return employee;
    });
  }

  async saveAllocations() {
    await this.setSelected();
    await axios.post(bulkUpdateAPIV1ClientRAndDPayslipsPath(this.clientId), {
      payslip: {
        allocations: this.formattedAllocations(),
      },
    });

    this.updateTabInfo = true;
    this.notificationStore.create({ header: 'Success', message: 'Successfully saved!', type: 'success' });
  }

  async unmarkRnd() {
    await axios.post(unmarkRndAPIV1ClientRAndDPayslipsPath(this.clientId), {
      employee_ids: this.employees
        .filter((employee) => this.selectedEmployeesToUpdate.includes(employee.id))
        .map((employee) => (employee.id)),
    });

    this.updateTabInfo = true;
    this.notificationStore.create({ header: 'Success!', message: 'Successfully saved!', type: 'success' });
  }

  formattedAllocations() {
    return this.employees.map((employee) => {
      const { selected, allocationInfo } = employee;

      return { id: allocationInfo.id, selected_for_rnd: selected, allocation: allocationInfo.allocation };
    });
  }

  get selectedEmployees() {
    return this.employees.filter((employee) => employee.selected);
  }

  get formattedSelectedEmployees() {
    return this.selectedEmployees.map((employee) => employee.id);
  }

  get total() {
    return this.selectedEmployees.reduce((accumulator, currentEmployee) => (
      (accumulator + ((currentEmployee.allocationInfo.allocation * currentEmployee.salary) / 100))
    ), 0).toFixed(2);
  }
}
