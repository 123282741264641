import React, { Component } from 'react';

import Axios from 'axios';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { inject, observer } from 'mobx-react';
import { Link, Redirect } from 'react-router-dom';

import Chart from 'components/charts/chart';
import DiffModal from 'components/charts/diff-modal';

import { apiV1ClientChartPath } from 'helpers/routes.js.erb';

@inject('chartsStore')
@observer
export default class ShowChart extends Component {
  constructor(props) {
    super(props);

    this.diffModalId = 'diff-modal';
    this.mainLocation = this.props.chartsStore.mainUrl;
    this.state = {
      redirect: false,
    };
  }

  componentDidMount() {
    this.props.chartsStore.loadChart(this.props.chartsStore.client.id, this.props.match.params.id);
  }

  deleteChart = async () => {
    if (window.confirm('Are you sure?')) {
      const { chartsStore } = this.props;
      const url = apiV1ClientChartPath(chartsStore.client.id, chartsStore.chartObject.id);

      await Axios.delete(url);
      this.setState({ redirect: true });
    }
  };

  editButton = (mainLocation, id, userCanUpdate) => {
    if (userCanUpdate) {
      return (
        <>
          <div className="col-auto">
            <button type="button" className="btn btn-primary">
              <FontAwesomeIcon icon={['fas', 'pencil-alt']} className="mr-2"/>
              <Link to={`${mainLocation}/${id}/edit`}>Edit</Link>
            </button>
          </div>
        </>
      )
    }
  }

  deleteButton = (deleteChart, userCanDelete) => {
    if (userCanDelete) {
      return (
        <>
           <div className="col-auto">
            <button type="button" className="btn btn-danger"
                    onClick={deleteChart}>
              <FontAwesomeIcon icon={['fas', 'trash-alt']} className="mr-2"/>
              Delete
            </button>
          </div>
        </>
      )
    }
  }

  editButtons = (mainLocation, id, deleteChart, userCanUpdate, userCanDelete) => {
    return (
       <div className="row">
          {this.editButton(mainLocation, id, userCanUpdate)}
          {this.deleteButton(deleteChart, userCanDelete)}
      </div>
    )
  };

  render() {
    if (this.props.chartsStore.chartObject === null) {
      return (
        <div>Loading...</div>
      );
    }

    const userRoles = this.props.chartsStore.userRoles;
    const userCanUpdate = userRoles['reporting']?.includes('update');
    const userCanDelete = userRoles['reporting']?.includes('delete');
    const notifications = this.props.chartsStore.chartObject.active_notifications || [];

    if (this.state.redirect) {
      return <Redirect to={this.mainLocation}/>;
    }

    return (
      <>
        <div className="row justify-content-center">
          <div className="col-9">
            {notifications.map((notification, index) => {
              const typeClass = notification.level === 'critical' ? 'danger' : 'info';

              return (
                <div className="row" key={index}>
                  <div className="col">
                    <div className={`alert alert-${typeClass}`} role="alert">
                      <FontAwesomeIcon icon={['fas', 'info-circle']} className="mr-2"/>
                      {notification.text}
                      <button type="button" className="btn btn-info mx-3"
                              data-toggle="modal" data-target={`#${this.diffModalId}`}>
                        Show structure difference
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <Chart/>
          </div>
        </div>
        <div className="row justify-content-between mt-3">
          <div className="col-auto">
            <button type="button" className="btn btn-info">
              <FontAwesomeIcon icon={['fas', 'arrow-left']} className="mr-2"/>
              <Link to={this.mainLocation}>Back</Link>
            </button>
          </div>
          <div className="col-auto">
            { this.editButtons(this.mainLocation, this.props.chartsStore.chartObject.id, this.deleteChart, userCanUpdate, userCanDelete) }
          </div>
        </div>
        {notifications.length > 0 ? (
          <DiffModal modalId={this.diffModalId}/>
        ) : null}
      </>
    );
  }
}
