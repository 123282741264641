import React from 'react';
import * as chartsHelpers from '../../common/charts-helper';
import ChartLegend from './chart-legend.jsx';
import { inject, observer } from 'mobx-react';
import { toJS } from 'mobx';

@inject('chartsStore')
@observer
export default class Chart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      legendLabels: [],
    };
    this.chartCanvas = React.createRef();
    this.chartInstance = undefined;
  }

  componentDidMount() {
    this.drawChart();
  }

  componentDidUpdate() {
    this.drawChart();
  }

  drawChart = () => {
    this.canvasContext = this.chartCanvas.current.getContext('2d');

    if (this.chartInstance) {
      this.chartInstance.destroy();
      this.chartCanvas.current.style.height = this.chartHeight;
    }

    this.chartInstance = chartsHelpers.createChartJs(
      this.canvasContext,
      toJS(this.props.chartsStore.chartObject.options),
      toJS(this.props.chartsStore.chartData),
    );
    this.chartHeight = this.chartCanvas.current.style.height;
  };

  render() {
    return (
      <div className="chart shadow-default" data-test-show-chart>
        <div className="row">
          <div className="col-12">
            <div className="row justify-content-center">
              <div className="col-4 text-center">
                <div className="chart-title" data-test-show-chart-name="true">
                  {this.props.chartsStore.chartObject.name}
                </div>
              </div>
            </div>
            <div className="row justify-content-between my-2 chart-header">
              <div className="col-6 chart-title" data-test-client-name="true">
                {this.props.chartsStore.client.name}
              </div>
            </div>
          </div>
        </div>
        <div>
          <canvas ref={this.chartCanvas} className="chart-canvas"/>
        </div>
        <div className="chart-legend mt-3">
          <ChartLegend items={this.props.chartsStore.chartData.datasets}/>
        </div>
      </div>
    );
  }
}
