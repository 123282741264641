import { DropZoneActiveStorage } from 'common/drop-zone/drop-zone-active-storage';
import { initSelectAll } from 'shared/select_all_checkboxes';
import { initSelectPresets } from 'shared/presets_for_user';

function initUserPhotoUploaders() {
  if (document.getElementById('user-photo-uploader')) {
    const maxFiles = 1;
    const acceptedFiles = 'image/png, image/jpg, image/jpeg';

    new DropZoneActiveStorage('user-photo-uploader', maxFiles, acceptedFiles).init();
  }
}

window.addEventListener('turbolinks:load', () => {
  initUserPhotoUploaders();
  initSelectAll('.user-roles-select-all');
  initSelectPresets('.user-preset');
});
