import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { PayrollStore } from '../stores/payroll.store';
import { Notification } from '../../ui-kit/notification';
import { Table, InputNumber, Button, Space, Popconfirm } from 'antd';
import { toJS } from 'mobx';
import { Chat } from './chat/chat';
import { ChatsStore } from '../stores/chats.store';
import { OpenChatButton } from './chat/components/open-chat-button';

const { Column } = Table;

export const Payroll = observer(({ clientId, notificationStore, selectedYear }) => {
  const [store] = useState(() => new PayrollStore(clientId, notificationStore));
  const [chatsStore] = useState(() => new ChatsStore({
    clientId,
    entityType: 'EmployeeAllocation',
    chatableCollectionKey: 'employees',
    nestedCollectionKey: 'allocationInfo',
    entitiesStore: store,
  }));

  useEffect(() => {
    async function init() {
      await store.loadEmployees(selectedYear);
      await chatsStore.initNotifications();
    }

    init();
    store.updateTabInfo = false;

    return chatsStore.unsubscribeChatsUpdates;
  }, [selectedYear, store.updateTabInfo]);

  const saveAllocations = async (e) => {
    e.preventDefault();

    await store.saveAllocations();
  };

  const unmarkRnd = async (e) => {
    e.preventDefault();

    await store.unmarkRnd();
  };

  const rowSelection = {
    selectedRowKeys: store.selectedEmployeesToUpdate,
    onChange: store.selectEmployees,
  };
  const header = (
    <>
      <Space>
        <span data-test='selected-rows-count'>{store.selectedEmployeesToUpdate.length} selected rows</span>
        <Button
          disabled={(store.selectedEmployeesToUpdate.length === 0 && store.selectedEmployeesIds === 0)}
          onClick={saveAllocations}
        >Mark as R&D
        </Button>
        <Popconfirm
            title='All checked rows will be unmarked R&D'
            onConfirm={unmarkRnd}
            okText="Yes"
            cancelText="No"
        >
          <Button disabled={store.selectedEmployeesToUpdate.length === 0} danger>Unmark R&D</Button>
        </Popconfirm>
      </Space>
    </>
  );

  return (
    <>
      <Chat store={chatsStore}/>
      <Notification {...notificationStore.data} onClose={notificationStore.cleanup}/>
      <Table
        rowSelection={rowSelection}
        rowClassName={(record) => (
          record.selected
            ? 'rd-selected-transaction test-payroll-employee' : 'test-payroll-employee'
        )}
        dataSource={toJS(store.employees)}
        pagination={false}
        title={() => header}
        loading={store.loading}
        footer={() => <span data-test='rnd-total-amount'>Total ${store.total}</span>}
        scroll={{ y: 300 }}
        rowKey='id'
      >
        <Column className='test-payroll-employee-first-name' title='First Name' dataIndex='firstName'/>
        <Column className='test-payroll-employee-last-name' title='Last Name' dataIndex='lastName'/>
        <Column className='test-payroll-employee-title' title='Title' dataIndex='title'/>
        <Column
          title='Salary'
          dataIndex='salary'
          render={(salary) => `$${salary}`}
          className='test-payroll-employee-salary'
        />
        <Column
          title='Allocation percent'
          key='allocation'
          render={(employee, _, dataIndex) => (
            <InputNumber
              min={0}
              max={100}
              placeholder='Enter allocation percent'
              disabled={!(store.selectedEmployeesToUpdate.includes(employee.id)
                || employee.selected)}
              formatter={(value) => `${value}%`}
              onFocus={(e) => e.target.select()}
              className='test-payroll-employee-allocation'
              onChange={(value) => store.setAllocation({ index: dataIndex, value })}
              value={employee.allocationInfo.allocation}
              style={{ width: '50%' }}
            />
          )}
        />
        <Column
          title='Comments'
          key='comments'
          render={(employee, _, dataIndex) => {
            const { chat } = toJS(employee.allocationInfo);

            return (
              <OpenChatButton
                lastMessage={chat?.last_message}
                messagesCount={chat?.messages_count}
                loadChatHandler={() => chatsStore.loadChat(dataIndex)}
              />
            );
          }}
        />
      </Table>
    </>
  );
});
