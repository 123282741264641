import React from 'react';

import { inject } from 'mobx-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';

import {
  clientClientDefaultChartsPath,
  pdfClientClientChartsPath,
} from 'helpers/routes.js.erb';

export default inject('chartsStore')(({ chartsStore, isCardView, setView, roles }) => {
  const createNewChart = () => {
    chartsStore.createNewChart();
  };

  const userCanCreate = roles['reporting']?.includes('create');

  const createButton = (chartsStore, userCanCreate) => {
    if (userCanCreate) {
      return (
        <>
          <div className="col-auto">
            <button type="button" className="btn btn-primary" onClick={createNewChart}>
              <FontAwesomeIcon icon={['fas', 'plus-circle']} className="mx-2"/>
              <Link to={`${chartsStore}/new`}>Add new chart</Link>
            </button>
          </div>
        </>
      )
    }
  }

  return (
    <>
      <div className="row">
        <div className="col">
          <h1>Charts</h1>
        </div>
      </div>
      <div className="row mb-4 mt-3 align-items-center">
        <div className="col-8">
          <div className="row">
            { createButton(chartsStore.mainUrl, userCanCreate) }
            <div className="col-auto">
              <a href={pdfClientClientChartsPath(chartsStore.client.id, { format: 'pdf'})}
                 className="btn btn-info">
                <FontAwesomeIcon icon={['fas', 'file-export']} className="mx-2"/>
                Export to PDF
              </a>
            </div>
            <div className="col-auto">
              <a href={clientClientDefaultChartsPath(chartsStore.client.id)}
                 className="btn btn-outline-info">
                Default charts settings
              </a>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="row justify-content-end list-panel-toggle">
            <div className={`col-auto ${!isCardView && 'active'}`} onClick={() => setView('list')}>
              <FontAwesomeIcon icon={['fas', 'list']}/>
              <span className="mx-2">See list view</span>
            </div>
            <div className={`col-auto ${isCardView && 'active'}`} onClick={() => setView('cards')}>
              <FontAwesomeIcon icon={['fas', 'th-large']}/>
              <span className="mx-2">See card view</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
