import { makeObservable, observable, action } from 'mobx';

export class NotificationStore {
  data = {
    message: null,
    type: null,
    header: null,
  }

  constructor(closeTimeout) {
    this.closeTimeout = closeTimeout || 10000;

    makeObservable(this, {
      data: observable,
      create: action.bound,
      cleanup: action.bound,
    });
  }

  create({ type, message, header }) {
    clearTimeout(this.cleanup);

    this.data = { type, message, header };

    setTimeout(this.cleanup, this.closeTimeout);
  }

  cleanup() {
    this.data = {};
  }
}
