import React from 'react';
import * as _ from 'lodash';
import { Modal } from '../../ui-kit/modal/modal.jsx';
import { ModalHeader } from '../../ui-kit/modal/modal-header.jsx';
import { inject, observer } from 'mobx-react';

@inject('chartsStore')
@observer
export default class ViewTypesModal extends React.Component {
  constructor(props) {
    super(props);
  }

  typeChange = (type, event) => {
    this.props.chartsStore.chartObject.options.dataViewTypes[type[0]] = event.target.value;
  };

  render() {
    const types = Object.entries(this.props.chartsStore.chartObject.options.dataViewTypes);
    const modalBody = (
      <div>
        {types.map((type, index) => {
          const nameArray = type[0].split('/');
          const name = nameArray[nameArray.length - 1];

          return (
            <div className="row justify-content-between align-items-center my-2" key={index}>
              <div className="col-auto">
                {name}
              </div>
              <div className="col-6">
                <div className="row align-items-center">
                  <div className="col-auto">
                    Chart type
                  </div>
                  <div className="col">
                    <select className="form-control" value={type[1]} onChange={(event) => this.typeChange(type, event)}>
                      <option value="bar">Bar</option>
                      <option value="line">Line</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    );

    return (
      <Modal
        modalId="view-types-modal"
        header={<ModalHeader title="Choose data view" showCloseButton={true}/>}
        body={modalBody}
      />
    );
  }
}
