import { action, makeObservable, observable } from 'mobx';
import moment from 'moment';
import { getItemsAPIV1VersionsPath, getVersionsAPIV1VersionsPath } from 'helpers/routes.js.erb';
import axios from 'axios';

export class VersionsStore {
  loading = false;
  itemId = null;
  modelName = null;
  startDate = null;
  endDate = moment();
  expandedRowKeys = [];
  versions = {};
  items = [];

  constructor(models, notificationStore) {
    this.models = models;
    this.notificationStore = notificationStore;

    makeObservable(this, {
      loading: observable,
      itemId: observable,
      modelName: observable,
      startDate: observable,
      endDate: observable,
      items: observable,
      versions: observable,
      expandedRowKeys: observable,
      setDates: action,
      setModel: action,
      setItemId: action,
      toggleLoading: action,
      loadItems: action,
      loadVersions: action,
    });
  }

  toggleLoading() {
    this.loading = !this.loading;
  }

  setModel(value) {
    this.modelName = value;
  }

  setDates(dates) {
    const [startDate, endDate] = dates || [];

    this.startDate = startDate;
    this.endDate = endDate;
  }

  setItemId(value) {
    this.itemId = value;
  }

  setData(data, obj) {
    data.push({ key: obj.id, id: obj.id, createdAt: obj.created_at, updatedAt: obj.updated_at });
  }

  noDataNotification() {
    this.notificationStore.create({
      header: 'Sorry.',
      message: 'No data with these filters',
      type: 'warning',
    });
  }

  async loadItems() {
    this.toggleLoading();
    const response = await axios.get(getItemsAPIV1VersionsPath(), {
      params: {
        model: this.modelName,
        item_id: this.itemId,
        start_date: this.startDate,
        end_date: this.endDate,
      },
    });

    const data = [];
    const result = response.data;

    if (result.error) {
      this.noDataNotification();
    } else {
      if (Array.isArray(result)) {
        response.data.forEach((el) => {
          this.setData(data, el);
        });
      } else {
        this.setData(data, result);
      }

      this.items = data;

      if (data.length < 1) {
        this.noDataNotification();
      }
    }

    this.toggleLoading();
  }

  setExpandedRowKeys(id, expanded) {
    if (expanded) {
      this.expandedRowKeys.push(id);
    } else {
      this.expandedRowKeys = this.expandedRowKeys.filter((key) => key !== id);
    }
  }

  async loadVersions(id, expended) {
    this.setExpandedRowKeys(id, expended);

    if (id in this.versions) {
      return;
    }

    this.toggleLoading();

    const response = await axios.get(getVersionsAPIV1VersionsPath(), {
      params: {
        model: this.modelName,
        item_id: id,
        start_date: this.startDate,
        end_date: this.endDate,
      },
    });

    const data = [];
    const result = response.data;

    if (result.error) {
      this.noDataNotification();
    } else {
      result.forEach((el) => {
        data.push(
          {
            key: el.id,
            id: el.id,
            createdAt: el.created_at,
            user: el.user,
            event: el.event,
            changes: JSON.stringify(el.changes),
          },
        );
      });

      this.versions[id] = data;
    }
    this.toggleLoading();
  }
}
