import React from 'react';
import Item from './stepper-item';
import './progress-stepper.scss';
import _ from 'lodash';

export default class ProgressStepper extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="stepper">
        {this.props.steps.map((step, index, array) => (
            <React.Fragment key={index}>
              <Item number={index + 1}
                    label={step}
                    done={index < this.props.activeStep}/>
              {(index + 1 !== array.length)
              && <div className="separator"/>
              }
            </React.Fragment>
        ))}
      </div>
    );
  }
}
