import React from 'react';
import { NavLink } from './nav-link';

export const Nav = ({ pathName, tabs, userRoles }) => {
  const canUserCreate = userRoles['r_and_d']?.includes('create');
  const addTab = (
    <>
      <NavLink to={`${pathName}/tabs/new`}>
        <i className='fas fa-plus-circle mx-2'/> Add tab
      </NavLink>
    </>
  )
  return (
    <div className='col nav nav-tabs' data-test='nav-links'>
      <NavLink to={pathName}>Payroll</NavLink>
      {tabs.map((tab) => <NavLink to={`${pathName}/tabs/${tab.id}`} key={tab.id}>
        {tab.name}
      </NavLink>)}
      <NavLink to={`${pathName}/tabs/vendors_total`}>Vendors Total</NavLink>
      <NavLink to={`${pathName}/tabs/summary`}>Summary</NavLink>
      {(() => {
        if (canUserCreate) {
          return addTab;
        }
      })()}
    </div>
  )
};
