import Axios from 'axios';
import { makeObservable, observable, action, toJS } from 'mobx';
import { pullAt } from 'lodash';

import {
  apiV1ClientPersonOfContactsPath,
  apiV1ClientPersonOfContactPath,
  clientPath,
} from 'helpers/routes.js.erb';

class ContactsStore {
  contacts = [];

  constructor(clientId) {
    this.clientId = clientId;

    makeObservable(this, {
      contacts: observable,
      initialize: action,
      create: action,
      delete: action,
      update: action,
      save: action,
    });
  }

  async initialize() {
    const path = apiV1ClientPersonOfContactsPath(this.clientId);

    const response = await Axios.get(path);
    this.contacts = response.data.person_of_contacts;
  }

  create() {
    this.contacts.push({
      id: 0,
      first_name: '',
      last_name: '',
      position: '',
      email: '',
      phone_number: '',
    });
  }

  async delete(index) {
    const contact = toJS(this.contacts)[index];

    if (contact.id !== 0) {
      await Axios.delete(apiV1ClientPersonOfContactPath(this.clientId, contact.id));
    }
    const contactsList = toJS(this.contacts);
    pullAt(contactsList, index);
    this.contacts.replace(contactsList);
  }

  update(index, prop, value) {
    const contact = this.contacts[index];
    contact[prop] = value;
    contact.edited = true;
  }

  async save() {
    let successful = true;
    const newContacts = toJS(this.contacts);

    for (const result of await Promise.all(this.saveContact(newContacts))) {
      newContacts[result.index] = result.contact;

      const { errors } = result.contact;

      if (Object.keys(errors).length > 0) {
        successful = false;
      }
    }

    this.contacts = newContacts;

    if (successful) {
      window.location.href = clientPath(this.clientId);
    }
  }

  saveContact(contacts) {
    const promises = [];

    for (let i = 0; i < contacts.length; i += 1) {
      const contact = contacts[i];

      if (contacts[i].id > 0 && !contacts[i].edited) {
        continue;
      }
      promises.push(new Promise((resolve) => {
        Axios(this.makeSaveParams(contact)).then((result) => {
          resolve({ index: i, contact: result.data });
        });
      }));
    }

    return promises;
  }

  makeSaveParams(contact) {
    const isNew = contact.id === 0;

    if (isNew) {
      return {
        method: 'post',
        url: apiV1ClientPersonOfContactsPath(this.clientId),
        data: contact,
      };
    }

    return {
      method: 'patch',
      url: apiV1ClientPersonOfContactPath(this.clientId, contact.id),
      data: contact,
    };
  }
}

export default ContactsStore;
