import { DropZoneActiveStorage } from 'common/drop-zone/drop-zone-active-storage';

function initUploader(wrapper, allowsFiles) {
  const maxFiles = 1;
  const acceptedFiles = allowsFiles || '.pdf, application/pdf';

  if (document.getElementById(wrapper)) {
    new DropZoneActiveStorage(wrapper, maxFiles, acceptedFiles).init();
  }
}

function initFinancialBundleUploaders() {
  initUploader('fin-bundle-va-attachments-uploader');
  initUploader('fin-bundle-charts-attachments-uploader');

  const allowsFinPacketsFiles = `.pdf, .xls, .xlsx, application/vnd.ms-excel,
      application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/pdf`;
  initUploader('fin-bundle-fp-attachments-uploader', allowsFinPacketsFiles);
  initUploader('fin-bundle-burn-chart-attachments-uploader', 'image/*');
}

window.addEventListener('turbolinks:load', () => {
  $('.fin-bundle-datepicker').flatpickr({
    minDate: new Date('January 1, 2000'),
    altInput: true,
    altFormat: 'm/d/Y',
  });

  initFinancialBundleUploaders();
});
