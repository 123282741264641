import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { TabStore } from '../stores/tab.store';
import { Table, Button, Space, Popconfirm, Tooltip, InputNumber, Row, Col, Tag } from 'antd';
import { OpenChatButton } from './chat/components/open-chat-button';
import { Notification } from '../../ui-kit/notification';
import { Chat } from './chat/chat';
import { toJS } from 'mobx';
import { ChatsStore } from '../stores/chats.store';
import { Link, Redirect } from 'react-router-dom';

const { Column } = Table;

export const Tab = observer((
  {
    tabData,
    clientId,
    clientName,
    notificationStore,
    navigationStore,
    selectedYear,
    pathName,
    userRoles,
  },
) => {
  const [store] = useState(() => new TabStore(clientId, clientName, notificationStore, userRoles));
  const [chatsStore] = useState(() => new ChatsStore({
    clientId,
    entityType: 'RndTransaction',
    chatableCollectionKey: 'transactions',
    entitiesStore: store,
  }));

  const canUserUpdate = userRoles['r_and_d']?.includes('update');
  const canUserDelete = userRoles['r_and_d']?.includes('delete');

  useEffect(() => {
    async function init() {
      store.setYear(selectedYear);
      await store.initTab(tabData);
      await chatsStore.initNotifications();
    }

    init();
    store.updateTabInfo = false;

    return chatsStore.unsubscribeChatsUpdates;
  }, [tabData, selectedYear, store.updateTabInfo]);

  if (store.redirectUrl) {
    const { redirectUrl } = store;

    return <Redirect to={redirectUrl}/>;
  }

  const saveTransactions = async (e) => {
    e.preventDefault();

    await store.setSelected();
    await store.saveTransactions();
  };

  const unmarkRnd = async (e) => {
    e.preventDefault();

    await store.unmarkRnd();
  };

  const saveAllocation = async (e) => {
    e.preventDefault();

    await store.saveAllocation();
  };

  const confirmDelete = async (e) => {
    e.preventDefault();

    await store.delete(navigationStore.deleteTab);
  };

  const rowSelection = {
    selectedRowKeys: store.selectedRowToUpdate,
    onChange: store.selectTransaction,
  };

  const editButton = (
    <>
      <Tooltip title='Edit' color='blue'>
        <Link to={`${pathName}/tabs/edit/${tabData.id}`} key={`${tabData.name}-${tabData.id}`}>
          <i className='fas fa-edit mx-2' data-test='edit-button'/>
        </Link>
      </Tooltip>
    </>
  );

  const deleteButton = (
    <>
      { store.tabData.isPreloadTab ? ''
        : <Tooltip title='Delete' color='red'>
          <Popconfirm
            title="Are you sure to delete this tab?"
            onConfirm={confirmDelete}
            okText="Yes"
            cancelText="No"
          >
            <Button className='ml-2' danger type='link'>
              <i className='fas fa-trash mx-2' data-test='delete-button'/>
            </Button>
          </Popconfirm>
        </Tooltip>
      }
    </>
  );

  const header = (
    <Row>
      <Col span={12}>
        <Space>
          <span data-test='selected-rows-count'>{store.selectedRowToUpdate.length} selected rows</span>
          <Button disabled={store.selectedRowToUpdate.length === 0} onClick={saveTransactions}>Mark as R&D</Button>
          <Popconfirm
            title='All checked transactions will be unmarked R&D'
            onConfirm={unmarkRnd}
            okText="Yes"
            cancelText="No"
          >
            <Button disabled={store.selectedRowToUpdate.length === 0} danger>Unmark R&D</Button>
          </Popconfirm>
        </Space>
      </Col>
      <Col span={12}>
        <Row>
          <Col flex="auto">
            <Space>
              <InputNumber
                min={0}
                max={100}
                placeholder='Enter allocation percent'
                disabled={(store.selectedRowToUpdate.length === 0 && store.selectedRowKeys.length === 0)}
                formatter={(value) => `${value} %`}
                onFocus={(e) => e.target.select()}
                className='test-tabs-allocation'
                onChange={(value) => store.setAllocation(value)}
                value={store.allocation}
              />
              <Popconfirm
                title='Set allocations for all R&D transactions?'
                onConfirm={saveAllocation}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  disabled={(store.selectedRowToUpdate.length === 0 && store.selectedRowKeys.length === 0)}
                  type="primary"
                >
                  Set allocations
                </Button>
              </Popconfirm>
            </Space>
          </Col>
          <Col flex="20%">
            { canUserUpdate && <>{editButton}</> }
            { canUserDelete && <>{deleteButton}</> }
          </Col>
        </Row>
      </Col>
    </Row>
  );

  return (
    <>
      <Notification {...notificationStore.data} onClose={notificationStore.cleanup}/>
      <Chat store={chatsStore}/>
      <div className='row mb-4'>
        <div className='col'>
          <Table
            loading={store.loading}
            rowSelection={rowSelection}
            rowClassName={(record) => (
              record.selected
                ? 'rd-selected-transaction test-tab-tx-data' : 'test-tab-tx-data'
            )}
            pagination={false}
            dataSource={toJS(store.transactions)}
            title={() => header}
            footer={() => <span data-test='selected-txs-total-amount'>Total ${store.total}</span>}
            scroll={{ x: 1000, y: 300 }}
          >
            <Column className='test-tx-date' title='Date' dataIndex='date'/>
            <Column className='test-tx-account' title='Account' dataIndex='account'/>
            <Column className='test-tx-type' title='Transaction Type' dataIndex='transaction_type'/>
            <Column className='test-tx-vendor' title='Vendor name' dataIndex='vendor'/>
            <Column className='test-tx-name' title='Name' dataIndex='name'/>
            <Column className='test-tx-desc' title='Description' dataIndex='description'/>
            <Column className='test-tx-amount' title='Amount' dataIndex='amount'/>
            <Column
              className='test-tx-allocation'
              title='Allocation'
              render={(transaction) => {
                const allocationAmount = (transaction.allocation === 0)
                  ? 0
                  : ((transaction.allocation * transaction.amount) / 100).toFixed(2);

                const color = transaction.allocation === 0 ? 'volcano' : 'green';

                return (
                  <>
                    <Tag color={color} className='test-tx-allocation-percent'>
                      {transaction.allocation} %
                    </Tag>
                    <Tag color={color} className='test-tx-allocation-amount'>
                      $ {allocationAmount}
                    </Tag>
                  </>
                );
              }}>
            </Column>
            <Column
              title='Comments'
              key='comments'
              fixed='right'
              render={(transaction, _, dataIndex) => {
                const { chat } = transaction;

                return (
                  <OpenChatButton
                    lastMessage={chat?.last_message}
                    messagesCount={chat?.messages_count}
                    loadChatHandler={() => chatsStore.loadChat(dataIndex)}
                  />
                );
              }}
            />
          </Table>
        </div>
      </div>
    </>
  );
});
