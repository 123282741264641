import axios from 'axios';

class MdAndATiers {
  constructor(url) {
    this.url = url;
    this.tiersSelect = $('#client_md_and_a_tier_id');
    this.nextDateWrapper = $('[data-md-and-a-next-date-wrapper]');
    this.nextDateField = this.nextDateWrapper.find('[data-md-and-a-next-date-field]');
  }

  init = async () => {
    await this.getNextCreationDate();
    this.tiersSelect.on('change', this.getNextCreationDate);
  }

  getNextCreationDate = async () => {
    const mdAndAId = $('#md_and_a_id').val();
    const result = await axios.post(this.url, {
      md_and_a_id: mdAndAId,
      tier_id: this.tiersSelect.val()
    });

    if (this.nextDateWrapper.hasClass('hidden')) {
      this.nextDateWrapper.removeClass('hidden');
    }

    this.nextDateField.html(result.data.next_date);
  }
}

window.addEventListener('turbolinks:load', async () => {
  const url = $('#calculate_next_creation_date');

  if (url.length) {
    const mdAndATiers = new MdAndATiers(url.val());
    await mdAndATiers.init();
  }
});
