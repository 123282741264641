import { Button, DatePicker, Space, Checkbox } from 'antd';
import React from 'react';
import { observer } from 'mobx-react-lite';

const { RangePicker } = DatePicker;
const dateFormat = 'MM-DD-YYYY';

export const PacketsHeader = observer(({ store }) => {
  const canUserCreate = store.userRoles.financial_packets?.includes('create');
  const packetGeneratePanel = (
   <>
    <Space>
      <RangePicker
        format={dateFormat}
        defaultValue={[store.startDate, store.endDate]}
        onChange={(dates) => store.setDates(dates)}
      />
      <Checkbox onChange={(event) => store.setOrder(event)}>Alphabetical Accounts Order in Excel Packets</Checkbox>
      <Button
        type='primary'
        disabled={!store.isAllowedToGenerate}
        loading={store.isGenerationInProgress}
        onClick={store.generate}
      >
        Generate Packet
      </Button>
    </Space>
  </>
  );

  if (canUserCreate) {
    return packetGeneratePanel;
  }

  return <></>;
});
