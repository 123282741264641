import React from 'react';
import './input.scss';

const FieldInput = (props) => (
  <div className={`pb-2 d-flex flex-column ${props.className ? props.className : ''}`} data-test-form-field>
    <span className="text bold" data-test-form-field-name>{props.name}</span>
    <input className="ui-input" type="text" value={props.value == null ? '' : props.value}
      name={props.columnName}
      data-test-input="input"
      onChange={props.handleChange}
      disabled={props.disabled}
      maxLength={props.maxLength}
    />
    {props.errors && <span className="validation-error">{props.errors.join(', ')}</span>}
  </div>
);

export default FieldInput;
