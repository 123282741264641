export function PacketsChartImageUploadModal(loader_image_tag) {
  this.loader_image_tag = '<span class="loading">' + loader_image_tag + '</span>';

  var ctx = this;

  $('[data-upload-packet-id]').click(function(event) {
    ctx.button = $(event.target);
    ctx.table = ctx.button.parents('table');

    if(ctx.table.data('client-chart-folder-exists')) {
      ctx._openModal();
    } else {
      ctx._toggleButtonLoading();
      ctx._postBoxFolders();
    }
  })
};

PacketsChartImageUploadModal.prototype._postBoxFolders = function() {
  var ctx = this;

  var xhr = new XMLHttpRequest();
  xhr.open('POST', '/clients/' + ctx.table.data('client-id') + '/box_folders', true);
  xhr.setRequestHeader('Content-Type', 'application/json; charset=utf-8');
  xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));
  xhr.send(null);

  xhr.onreadystatechange = function() {
    if (xhr.readyState === 4) {
      if(xhr.status === 201) {
        ctx._onRequestSuccess();
      } else {
        ctx._onRequestFailure();
      }
    }
  }
};

PacketsChartImageUploadModal.prototype._openModal = function () {
  $('#chart-image-modal-' +  this.button.data('upload-packet-id')).modal({ show: true });
};

PacketsChartImageUploadModal.prototype._onRequestSuccess = function() {
  this.table.data('client-chart-folder-exists', true);
  this._toggleButtonLoading();
  this._openModal();
};

PacketsChartImageUploadModal.prototype._onRequestFailure = function() {
  this._toggleButtonLoading();

  alert('Something went wrong. Please reload the page and try again.');
};

PacketsChartImageUploadModal.prototype._toggleButtonLoading = function() {
  if(this.button.is(':visible')) {
    this.button.hide();
    this.button.parent().append(this.loader_image_tag);
  } else {
    this.button.parent().find('.loading').remove();
    this.button.show();
  }
};
