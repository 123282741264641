import React from 'react';

export function Modal(props) {
  return (
    <div className="modal fade" tabIndex="-1" role="dialog" id={props.modalId}>
      <div className={`modal-dialog ${props.large && 'modal-lg'}`} role="document">
        <div className="modal-content">
          <div className="modal-header">
            {props.header}
          </div>
          <div className="modal-body" data-test-modal-body>
            {props.body}
          </div>
          <div className="modal-footer">
            {props.footer}
          </div>
        </div>
      </div>
    </div>
  );
}
