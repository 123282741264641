import React from 'react';
import { observer } from 'mobx-react-lite';
import './chat.scss';
import { Messages } from './components/messages';
import { toJS } from 'mobx';
import { SendMessageForm } from './components/send-message-form';

export const Chat = observer(({ store }) => {
  if (!store.openedChat) {
    return (<></>);
  }

  return (
    <div className='chat-wrapper'>
      <div className='chat-header'>
        <button onClick={store.closeChat} className='close'>
          <span>&times;</span>
        </button>
      </div>
      <div className='chat-conversation-container'>
        <Messages messages={toJS(store.messages)} isLoading={toJS(store.loadingMessages)}/>
        <SendMessageForm store={store}/>
      </div>
    </div>
  );
});
