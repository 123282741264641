import { action, makeObservable, observable } from 'mobx';
import {
  summaryAPIV1ClientRAndDTabsPath,
  summaryVendorsAPIV1ClientRAndDTabsPath,
} from 'helpers/routes.js.erb';
import axios from 'axios';

export class SummaryStore {
  loading = false;
  summary = [];
  selectedYear = null;
  tabId = null;
  allVendors = {};

  constructor(clientId) {
    this.clientId = clientId;

    makeObservable(this, {
      summary: observable,
      allVendors: observable,
      loading: observable,
      selectedYear: observable,
      tabId: observable,
      setSummary: action,
      loadSummary: action,
      toggleLoading: action,
      setYear: action.bound,
      setTabId: action,
    });
  }

  setYear(year) {
    this.selectedYear = year;
  }

  setTabId(tabId) {
    this.tabId = tabId;
  }

  toggleLoading() {
    this.loading = !this.loading;
  }

  setSummary(data) {
    this.summary = data.map((summaryInfo) => {
      summaryInfo.key = summaryInfo.name;

      return summaryInfo;
    });
  }

  async loadVendors() {
    if (this.tabId in this.allVendors) {
      return;
    }

    this.toggleLoading();

    const { data } = await axios.get(
      summaryVendorsAPIV1ClientRAndDTabsPath(this.clientId),
      {
        params: {
          tab_id: this.tabId,
          selected_year: this.selectedYear,
        },
      },
    );
    this.allVendors[this.tabId] = data;

    this.toggleLoading();
  }

  async loadSummary() {
    this.toggleLoading();

    const { data } = await axios.get(
      summaryAPIV1ClientRAndDTabsPath(this.clientId),
      {
        params: {
          selected_year: this.selectedYear,
        },
      },
    );

    this.setSummary(data);
    this.toggleLoading();
  }
}
