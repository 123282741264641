import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { observer } from 'mobx-react-lite';
import 'antd/dist/antd.css';
import { TaxOptOutStore } from './tax-opt-out.store';
import { NotificationStore } from '../common-stores/notification.store';
import { Card, Row, Col, Form, Input, Button, Select, Checkbox, DatePicker, Spin, Popconfirm, Result } from 'antd';
import moment from 'moment';
import { Notification } from '../ui-kit/notification';

const TaxOptOut = observer(({ store, notificationStore }) => {
  useEffect(() => {
    async function init() {
      if (store.isEdit) {
        await store.loadTaxData();
      }
      await store.loadDataForSelect();
    }

    init();
  }, []);

  if (store.redirectUrl) {
    const { redirectUrl } = store;

    return (
      <Card title='Tax Opt Out Information' data-test='delete-message'>
        <Result
          status="success"
          title="Success!"
          subTitle="Tax Opt Out Info Deleted."
          extra={[
            <Button type="link" href={redirectUrl}>
              Go To Client
            </Button>,
          ]}
        />
      </Card>
    );
  }

  return (
    <Card title='Tax Opt Out Information'>
    { store.loading
      ? <Spin size="large"/>
      : <Row gutter={[32, 16]}>
        <Col span={12} offset={6} data-test='tax-opt-out-info-notification'>
          <Notification {...notificationStore.data} onClose={notificationStore.cleanup}/>
        </Col>
        <Col span={12} offset={6}>
          <Form
            name="tax-opt-out-info"
            initialValues={{
              remember: true,
            }}
            onFinish={(values) => store.saveChanges(values)}
            autoComplete="off"
            layout='vertical'
          >
            <Form.Item
              label='Select Tax Year'
              name='year'
              rules={[
                {
                  required: true,
                  message: 'Please select Tax Year',
                },
              ]}
              initialValue={store.taxData.year}
            >
              <Select data-test='tax-info-select-year'>
                {store.years.map((year) => (
                  <Select.Option key={year} value={year} data-test='tax-info-year-option'>{year}</Select.Option>
                ))
                }
              </Select>
            </Form.Item>
            <Form.Item
              name='do_taxes'
              valuePropName="checked"
              initialValue={store.taxData.do_taxes}
            >
              <Checkbox data-test='tax-info-doing-tax-checkbox'>
                Kruze doing taxes.
              </Checkbox>
            </Form.Item>
            <Form.Item
              label="Reason why Kruze doesn't doing taxes"
              name="reason"
              initialValue={store.taxData.reason}
            >
              <Input.TextArea data-test='tax-info-reason-input'/>
            </Form.Item>
            <Form.Item
              label="Date Of Opt Out"
              name='date_of_opt_out'
              initialValue={store.taxData.date_of_opt_out ? moment(store.taxData.date_of_opt_out) : null}
            >
              <DatePicker format={'MM/DD/YYYY'} data-test='tax-info-datepicker'/>
            </Form.Item>
            <Form.Item
              name="zendesk_link"
              label="URL"
              rules={[
                { type: 'url', warningOnly: true },
                { type: 'string', min: 6 },
              ]}
              initialValue={store.taxData.zendesk_link}
            >
              <Input placeholder="Zendesk link" data-test='tax-info-link-input'/>
            </Form.Item>
            <Form.Item
              label='Who Opted Out On The Company Side'
              name='user_id'
              initialValue={store.taxData.user_id}
            >
              <Select data-test='tax-info-select-user'>
                {store.users.map((user) => (
                  <Select.Option key={user.id} value={user.id} data-test='tax-info-user-option'>
                    {user.name || user.email}
                  </Select.Option>
                ))
                }
              </Select>
            </Form.Item>

            <Form.Item>
              {store.isEdit
                ? <Row justify="space-between">
                  <Button type="primary" htmlType="submit">Save</Button>
                  <Popconfirm
                    title='Are you sure you want to discard scheduled rate?'
                    onConfirm={() => store.delete()}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button type="danger">Delete</Button>
                  </Popconfirm>
                </Row>
                : <Button type="primary" htmlType="submit">Save</Button>
              }
            </Form.Item>
          </Form>
        </Col>
      </Row>
    }
  </Card>
  );
});

window.addEventListener('turbolinks:load', async () => {
  const rootElement = document.getElementById('tax-opt-out-info-page');
  const clientId = document.getElementById('tax-opt-out-client-id')?.value;
  const taxInfoId = document.getElementById('tax-opt-out-id')?.value;
  const isEdit = !!taxInfoId;

  if (rootElement && clientId) {
    const notificationStore = new NotificationStore();
    const store = new TaxOptOutStore(clientId, notificationStore, taxInfoId, isEdit);

    ReactDOM.render(
      <TaxOptOut store={store} notificationStore={notificationStore}/>,
      rootElement,
    );
  }
});
