// TODO: Move action-cable/consumer.js as consumer/index.js and import just 'consumer'.
import consumer from 'action-cable/consumer';

import {
  downloadClientVarianceAnalysisAccountsPath,
  downloadClientVarianceAnalysisVendorsPath,
} from 'helpers/routes.js.erb';

class PdfStatusTracking {
  constructor(clientId, varianceType) {
    this.clientId = clientId;
    this.varianceType = varianceType;
  }

  init = () => {
    const ctx = this;

    consumer.subscriptions.create({
      channel: 'VarianceAnalysisPdfStatusChannel',
      client_id: this.clientId,
    },
    {
      received(data) {
        const pdfObject = JSON.parse(data.object);
        const pdfWrapper = $(`[data-variance-pdf-id=${pdfObject.id}]`);
        const statusField = pdfWrapper.find('[data-variance-pdf-status]');
        const linkWrapper = pdfWrapper.find('[data-variance-pdf-link]');

        let url = '';

        switch (pdfObject.variance_type) {
          case 'accounts':
            url = downloadClientVarianceAnalysisAccountsPath(pdfObject.client_id, { pdf_id: pdfObject.id });
            break;
          case 'vendors':
            url = downloadClientVarianceAnalysisVendorsPath(pdfObject.client_id, { pdf_id: pdfObject.id });
            break;
          default:
            console.error(`Unsupported Variance Analysis type - ${pdfObject.variance_type}.`);
        }

        if (pdfObject.variance_type !== ctx.varianceType) {
          return;
        }

        if (!pdfWrapper.length && url) {
          const container = $('#data-variance-pdf-container');
          const template = `<tr>
            <td>${pdfObject.file_name}</td>
            <td>${pdfObject.status}</td>
            <td>
              <a href="${url}">Download</a>
            </td>
          </tr>`;

          container.prepend(template);

          return;
        }

        if (pdfObject.status === 'done') {
          linkWrapper.html(`<a target="_blank" download="true" href="${url}">Download</a>`);
        }

        statusField.html(pdfObject.status);
      },
    });
  }
}

window.addEventListener('turbolinks:load', () => {
  const clientField = $('#variance_pdf_client_id');
  const varianceType = $('#variance_type');

  if (clientField.length && varianceType.length) {
    const statusTracking = new PdfStatusTracking(clientField.val(), varianceType.val());

    statusTracking.init();
  }
});
