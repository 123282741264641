import { DropZoneActiveStorage } from 'common/drop-zone/drop-zone-active-storage';

function initRewardUploaders() {
  const maxFiles = 1;
  const acceptedFiles = 'image/png, image/jpg, image/jpeg';

  new DropZoneActiveStorage('reward-image-uploader', maxFiles, acceptedFiles).init();
  new DropZoneActiveStorage('reward-small-image-uploader', maxFiles, acceptedFiles).init();
}

window.addEventListener('turbolinks:load', () => {
  if (document.getElementById('reward-image-uploader')) {
    initRewardUploaders();
  }
});
