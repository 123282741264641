import React from "react"
import ReactDOM from "react-dom";
import { Provider } from "mobx-react";
import Store from './stores/financial-model-store';
import FinancialModelPage from './pages/financial-model-page';

const FinancialModel = () => {
  return (
    <FinancialModelPage/>
  )
};

window.addEventListener('turbolinks:load', function () {
  const rootElement = document.getElementById('financial-model-root');
  if (!rootElement) {
    return;
  }

  const client = JSON.parse(document.getElementById('client').value);
  const store = new Store(client);
  ReactDOM.render(
    <Provider store={store}>
      <FinancialModel/>
    </Provider>,
    rootElement
  );
});
