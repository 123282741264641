import MonthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';

window.addEventListener('turbolinks:load', async () => {
  $('.month-picker').each((i, el) => {
    const jElement = $(el);
    const minDate = jElement.data('min-date');
    const maxDate = jElement.data('max-date');
    const defaultDate = jElement.data('default-date');
    jElement.flatpickr({
      minDate: minDate ? new Date(minDate) : null,
      maxDate: maxDate ? new Date(maxDate) : null,
      defaultDate: defaultDate ? new Date(defaultDate) : null,
      altInput: true,
      altFormat: 'F j, Y',
      plugins: [
        new MonthSelectPlugin({
          shorthand: true,
          theme: 'dark',
          dateFormat: 'Y-m-d',
          altFormat: 'M Y',
        }),
      ],
    });
  });
});
