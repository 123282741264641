import Dropzone from 'dropzone';
import { ActiveStorageDirectUploader } from './active-storage-direct-uploader';
import axios from 'axios';

Dropzone.autoDiscover = false;

export class DropZoneActiveStorage {
  constructor(wrapperEl, maxFiles, acceptedFiles) {
    this.dropZoneEl = document.getElementById(wrapperEl);
    this.fileInput = this.dropZoneEl.querySelector('input[type=file]');
    this.url = this.fileInput.getAttribute('data-direct-upload-url');
    this.deleteUrlTemplate = this.fileInput.getAttribute('data-direct-purge-url');
    this.maxFiles = maxFiles;
    this.acceptedFiles = acceptedFiles;
  }

  init() {
    this.dropZone = this.initDropzone(this.dropZoneEl);
    this.attachListeners();
  }

  initDropzone = (wrapperEl) => {
    const ctx = this;

    return new Dropzone(wrapperEl, {
      url: this.url,
      maxFilesize: 5,
      addRemoveLinks: true,
      autoQueue: false,
      maxFiles: this.maxFiles,
      acceptedFiles: this.acceptedFiles,
      init() {
        const inputName = ctx.fileInput.getAttribute('name');
        const attachmentInputs = ctx.dropZoneEl.querySelectorAll(`[name='${inputName}'][type='hidden']`);

        attachmentInputs.forEach((attachmentInput) => {
          const file = {
            name: attachmentInput.getAttribute('data-file-name'),
            size: attachmentInput.getAttribute('data-file-size'),
          };

          this.files.push(file);
          this.emit('addedfile', file);
          this.emit('complete', file);

          this.options.maxFiles = this.maxFiles - this.files.length;

          ctx.uploader(file);
          file.uploaderCtx.hiddenInput = attachmentInput;
        });
      },
    });
  }

  attachListeners() {
    this.dropZone.on('addedfile', (file) => {
      this.uploader(file).start();
      const filesCount = this.dropZone.files.length;

      if (filesCount > this.maxFiles) {
        this.dropZone.options.maxFiles = this.maxFiles - filesCount;
      }
    });

    this.dropZone.on('removedfile', (file) => {
      if (file.uploaderCtx) {
        const inputEl = file.uploaderCtx.hiddenInput;
        const deleteUrl = this.deleteUrlFor(inputEl);
        const token = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content');

        axios.delete(deleteUrl, {
          headers: {
            'X-CSRF-Token': token,
          },
        }).then(() => {
          inputEl.parentNode.removeChild(inputEl);
          this.dropZone.options.maxFiles = this.maxfiles;
        });
      }
    });

    this.dropZone.on('canceled', (file) => {
      if (file.uploaderCtx) {
        file.uploaderCtx.xhr.abort();
      }
    });
  }

  uploader(file) {
    return new ActiveStorageDirectUploader(file, this);
  }

  deleteUrlFor(inputEl) {
    const signedId = inputEl.getAttribute('value');

    return this.deleteUrlTemplate.replace('signed_id', signedId);
  }
}
