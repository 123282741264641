import { makeObservable, observable, action } from 'mobx';
import {
  apiV1ClientRAndDVendorTotalsPath,
  vendorTransactionsAPIV1ClientRAndDVendorTotalsPath,
  createTabAPIV1ClientRAndDVendorTotalsPath,
  clientRAndDPath
} from 'helpers/routes.js.erb';
import axios from 'axios';

export class VendorsTotalStore {
  loading = false;
  vendors = [];
  selectedYear = null;
  transactions = {};
  selectedRowToUpdate = [];
  expandedRowKeys = [];
  redirectUrl = null;

  constructor(clientId, notificationStore, navigationStore) {
    this.clientId = clientId;
    this.notificationStore = notificationStore;
    this.navigationStore = navigationStore;

    makeObservable(this, {
      vendors: observable,
      loading: observable,
      transactions: observable,
      selectedYear: observable,
      selectedRowToUpdate: observable,
      expandedRowKeys: observable,
      redirectUrl: observable,
      selectTransaction: action,
      loadVendors: action,
      setVendors: action,
      toggleLoading: action,
      setYear: action.bound,
      loadTransactions: action,
      formattedTransactions: action,
    });
  }

  setYear(year) {
    this.selectedYear = year;
  }

  setVendors(vendors) {
    this.vendors = vendors;
  }

  toggleLoading() {
    this.loading = !this.loading;
  }

  selectTransaction(selectedRowToUpdate) {
    this.selectedRowToUpdate = selectedRowToUpdate;
  }

  async loadVendors() {
    this.toggleLoading();

    const { data: vendors } = await axios.get(
      apiV1ClientRAndDVendorTotalsPath(this.clientId),
      {
        params: {
          selected_year: this.selectedYear,
        },
      },
    );

    this.setVendors(vendors);
    this.transactions = {};
    this.toggleLoading();
  }

  setExpandedRowKeys(vendor, expanded) {
    if (expanded) {
      this.expandedRowKeys.push(vendor);
    } else {
      this.expandedRowKeys = this.expandedRowKeys.filter((key) => key !== vendor);
    }
  }

  async loadTransactions(vendor, expanded) {
    this.setExpandedRowKeys(vendor, expanded);

    if (vendor in this.transactions) {
      return;
    }

    this.toggleLoading();

    const { data } = await axios.get(vendorTransactionsAPIV1ClientRAndDVendorTotalsPath(this.clientId),
      {
        params: {
          selected_year: this.selectedYear,
          vendor_name: vendor,
        },
      });

    this.transactions[vendor] = data;

    this.toggleLoading();
  }

  formattedTransactions(vendor) {
    return this.transactions[vendor].map((tx) => ({
      md5_hash: tx.md5_hash,
      amount: tx.amount,
    }));
  }

  async fastTabCreate(vendor) {
    const response = await axios.post(createTabAPIV1ClientRAndDVendorTotalsPath(this.clientId),
      {
        data: {
          vendor_name: vendor,
          selected_year: this.selectedYear,
          selected_transactions: this.selectedRowToUpdate,
          all_transactions: this.formattedTransactions(vendor),
        },
      });

    const { data } = response;

    if (data.errors) {
      this.notificationStore.create({
        header: 'Error',
        message: data.errors,
        type: 'error',
      });
    } else {
      await this.navigationStore.add(data);

      this.notificationStore.create(
        { header: 'Success!', message: `${data.name} successfully created`, type: 'success' },
      );
      this.redirectUrl = `${clientRAndDPath(this.clientId)}/tabs/${data.id}`;
    }
  }
}
