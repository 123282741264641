import React from 'react';
import { Tag } from 'antd';

export const TimelineContext = ({ notes }) => (
  <p>
    <Tag className='test-mcp-timeline-context' color="gold" style={{ whiteSpace: 'pre-line' }}>
      <b>Context: </b>{notes}
    </Tag>
  </p>
);
