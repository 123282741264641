import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import FormField from '../ui-kit/form/field';

@inject('store')
@observer
export default class Item extends Component {
  constructor(props) {
    super(props);
    this.store = props.store;
  }

  handleChange = (fieldName, value) => {
    this.store.update(this.props.index, fieldName, value);
  }

  handleDelete = (e) => {
    e.preventDefault();
    this.store.delete(this.props.index);
  }

  render() {
    const { errors, index } = this.props;

    return (
      <div id={`person-of-contact-${index}`} className="with-separator py-2 my-2" data-test-person-section>
        <div className="row">
          <div className="col-5">
            <FormField
              columnName="first_name"
              name='First Name'
              value={this.props.first_name}
              errors={errors?.first_name}
              handleChange={(e) => { this.handleChange('first_name', e.target.value); }}
            />
          </div>
          <div className="col-5">
            <FormField
              columnName="last_name"
              name='Last Name'
              value={this.props.last_name}
              errors={errors?.last_name}
              handleChange={(e) => { this.handleChange('last_name', e.target.value); }}
            />
          </div>
          <div className="col-1 d-flex align-items-center justify-content-between">
            <a href="#"
              title="Delete"
              className="text-muted mx-2"
              onClick={this.handleDelete}
              data-confirm="Are you sure you want to delete the contact?">
              <i className="fas fa-trash"></i>
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col-10">
            <FormField
              columnName="position"
              name='Position'
              value={this.props.position}
              errors={errors?.position}
              handleChange={(e) => { this.handleChange('position', e.target.value); }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-5">
            <FormField
              columnName="phone_number"
              name='Phone number'
              value={this.props.phone_number}
              errors={errors?.phone_number}
              handleChange={(e) => { this.handleChange('phone_number', e.target.value); }}
            />
          </div>
          <div className="col-5">
            <FormField
              columnName="email"
              name='Email'
              value={this.props.email}
              errors={errors?.email}
              handleChange={(e) => { this.handleChange('email', e.target.value); }}
            />
          </div>
        </div>
      </div>
    );
  }
}
