window.addEventListener('turbolinks:load', function () {
  $('.increment-duration-js').click(function(){
    $('#plaid_report_generation_setting_duration').val(function(i, oldValue) {
      return (oldValue >=730 ? oldValue : ++oldValue);
    })
  });

  $('.decrement-duration-js').click(function(){
    $('#plaid_report_generation_setting_duration').val(function(i, oldValue) {
      return (oldValue <= 1 ? oldValue : --oldValue);
    })
  });

});
