import { makeObservable, action, observable, runInAction } from 'mobx';
import consumer from '../../../action-cable/consumer';
import axios from 'axios';
import {
  apiV1UsersMePath,
  apiV1ClientRAndDChatsPath,
  apiV1ClientRAndDChatMessagesPath,
} from 'helpers/routes.js.erb';

export class ChatsStore {
  currentUser = null;
  openedChat = null;
  messages = [];
  message = null;
  loadingMessages = false;
  entitiesStore = null;
  notificationsSubscription = null;

  constructor({ clientId, entityType, chatableCollectionKey, entitiesStore, nestedCollectionKey }) {
    this.clientId = clientId;
    this.entityType = entityType;
    this.chatableCollectionKey = chatableCollectionKey;
    this.entitiesStore = entitiesStore;
    this.nestedCollectionKey = nestedCollectionKey;

    makeObservable(this, {
      message: observable,
      messages: observable,
      openedChat: observable,
      currentUser: observable,
      loadingMessages: observable,
      notificationsSubscription: observable,
      create: action,
      createMessage: action.bound,
      initNotifications: action,
      loadChat: action.bound,
      closeChat: action.bound,
      updateMessage: action.bound,
      setCurrentUser: action,
      loadMessages: action,
      appendNotSavedMessage: action,
      appendSavedMessage: action,
      setOpenedChat: action,
      updateChatInfo: action,
      unsubscribeChatsUpdates: action.bound,
    });
  }

  initNotifications() {
    const ctx = this;

    this.notificationsSubscription = consumer.subscriptions.create({
      channel: 'RndChatsChannel',
      client_id: this.clientId,
    }, {
      received(chat) {
        ctx.updateChatInfo(chat);
      },
    });
  }

  async create(entityId) {
    const { data } = await axios.post(apiV1ClientRAndDChatsPath(this.clientId), {
      chatable_type: this.entityType,
      chatable_id: entityId,
    });

    return data;
  }

  async loadChat(entityIndex) {
    if (!this.currentUser) {
      const { data: currentUser } = await axios.get(apiV1UsersMePath());

      this.setCurrentUser(currentUser);
    }

    const entity = this.nestedCollectionKey
      ? this.entitiesStore[this.chatableCollectionKey][entityIndex][this.nestedCollectionKey]
      : this.entitiesStore[this.chatableCollectionKey][entityIndex];

    if (entity.chat?.id) {
      this.setOpenedChat(entity.chat);
    } else {
      const createdChat = await this.create(entity.id);

      runInAction(() => {
        entity.chat = createdChat;
        this.setOpenedChat(createdChat);
      });
    }

    await this.loadMessages();
  }

  closeChat() {
    consumer.subscriptions.remove(this.openedChat.subscription);
    this.openedChat = null;
    this.messages = [];
    this.message = null;
    this.loadingMessages = false;
  }

  async createMessage() {
    const newMessage = {
      id: new Date().getMilliseconds(),
      body: this.message,
      author: this.currentUser,
      created_at: new Date().toLocaleDateString('en-US'),
    };
    const body = this.message;

    this.appendNotSavedMessage(newMessage);
    await axios.post(apiV1ClientRAndDChatMessagesPath(this.clientId, this.openedChat.id), {
      message: {
        body,
        user_id: this.currentUser.id,
      },
    });
  }

  updateMessage(content) {
    this.message = content;
  }

  setOpenedChat(chat) {
    this.openedChat = chat;
  }

  setCurrentUser(user) {
    this.currentUser = user;
  }

  appendNotSavedMessage(message) {
    this.messages = [...this.messages, message];
    this.message = null;
  }

  appendSavedMessage(message) {
    if (message.author.id === this.currentUser.id) {
      return;
    }

    this.messages = [...this.messages, message];
  }

  updateChatInfo(chat) {
    const entity = this.entitiesStore[this.chatableCollectionKey].find((el) => {
      if (this.nestedCollectionKey) {
        return el[this.nestedCollectionKey].id === chat.chatable_id;
      }

      return el.id === chat.chatable_id;
    });

    if (!entity) {
      return;
    }

    const entityToUpdate = this.nestedCollectionKey ? entity[this.nestedCollectionKey] : entity;

    entityToUpdate.chat = chat;
  }

  unsubscribeChatsUpdates() {
    try {
      consumer.subscriptions.remove(this.notificationsSubscription);

      if (this.openedChat?.subscription) {
        consumer.subscriptions.remove(this.openedChat.subscription);
      }
    } catch (e) {
      console.log(e);
    }
  }

  async loadMessages() {
    this.loadingMessages = true;

    const ctx = this;
    const { data } = await axios.get(apiV1ClientRAndDChatMessagesPath(this.clientId, this.openedChat.id));

    this.openedChat.subscription = consumer.subscriptions.create({
      channel: 'RndChatMessagesChannel',
      chat_id: this.openedChat.id,
    }, {
      connected() {
        runInAction(() => {
          ctx.messages = data;
          ctx.loadingMessages = false;
        });
      },
      received(message) {
        ctx.appendSavedMessage(message);
      },
    });
  }
}
