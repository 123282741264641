import { ApolloClient, InMemoryCache } from '@apollo/client';
import { graphqlPath } from 'helpers/routes.js.erb';

const AppoloClient = new ApolloClient({
  uri: graphqlPath(),
  cache: new InMemoryCache(),
  defaultOptions: {
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
});

async function QueryData(query, variables = null) {
  return AppoloClient.query({ query, variables });
}

async function MutateData(mutation, variables = null) {
  return AppoloClient.mutate({ mutation, variables });
}

export { AppoloClient, QueryData, MutateData };
