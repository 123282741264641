import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';

import CardWrapper from '../ui-kit/card-wrapper.jsx';
import UiButton from '../ui-kit/button.jsx';
import FromSection from '../ui-kit/form/section.jsx';
import Item from './item.jsx';

@inject('store')
@observer
export default class List extends Component {
  constructor(props) {
    super(props);
    this.store = props.store;
  }

  handleSave = (e) => {
    e.preventDefault();
    this.store.save();
  }

  handleCreate = (e) => {
    e.preventDefault();
    this.store.create();
  }

  render() {
    return (
      <CardWrapper>
        <FromSection name='Edit shortcuts'>
          <form className="contact-form" onSubmit={this.handleSubmit}>
            { this.store.shortcuts.map((shortcut, index) => <Item key={index} index={index} {...shortcut} />) }
          </form>
          <UiButton
            onClick={ this.handleCreate }
            outline={true}
            hero={true}
            icon={'fas fa-plus-circle'}
            text={'Add new shortcut'} />
        </FromSection>
        <div className="row mt-3">
          <div className="col-2 offset-9">
            <UiButton onClick={ this.handleSave } hero={true} text={'Save'} />
          </div>
        </div>
      </CardWrapper>
    );
  }
}
