import { Cascader } from 'antd';
import React from 'react';
import { ScheduleTypes } from '../stores/schedule-types';
import _ from 'lodash';

export const TypeSelect = ({ onChange, defaultValue }) => {
  const types = _.uniqBy(ScheduleTypes, 'type').map((t) => t.type);
  const options = types.map((type) => (
    {
      value: type,
      label: type,
      children: ScheduleTypes.filter((t) => t.type === type).map((t) => ({ label: t.title, value: t.name })),
    }));
  const displayRender = (label) => (label[label.length - 1]);

  return (
    <Cascader
      options={options}
      expandTrigger="hover"
      displayRender={displayRender}
      onChange={onChange}
      defaultValue={[defaultValue.type, defaultValue.name]}
    />
  );
};
