import { has } from 'lodash';

import AjaxLoaderImage from 'images/ajax-loader.gif';
import consumer from 'action-cable/consumer';

// TODO: Move the methods to helpers.
const generateTaxPapers = (text, params = { loader: false }) => {
  const loaderImageTag = `<img src=${AjaxLoaderImage} alt="AJAX Loader"/>`;
  const innerText = params.loader ? `${text} ${loaderImageTag}` : text;

  return `<p id='js-generate-tax-papers'>${innerText}</p>`;
};

const setGenerateTaxPapers = (text, params = { loader: false }) => {
  const element = document.getElementById('js-generate-tax-papers');

  element.outerHTML = generateTaxPapers(text, params);
};

const receiveGenerateTaxPapers = (data) => {
  if (!has(data, 'error') && !has(data, 'processing')) {
    window.location.reload(false);
  }

  if (has(data, 'error')) {
    setGenerateTaxPapers(
      'The Tax Paper generation failed. Please refresh the page and try again.',
    );
  }

  if (has(data, 'processing')) {
    setGenerateTaxPapers('Generating Tax Papers', { loader: true });
  }
};

// TODO: Create universal functions for typical operations. DRY!
window.addEventListener('turbolinks:load', () => {
  if (document.getElementById('js-tax-papers-index')) {
    // TODO: Move the code of initialize flatpickr to file for initialize libs.
    $('.datepicker').flatpickr({
      minDate: new Date('January 1, 2000'),
      dateFormat: 'm/d/Y',
    });

    consumer.subscriptions.create(
      {
        channel: 'TaxPaperStatusesChannel',
        client_id: window.location.pathname.split('/')[2],
      },
      {
        received: (data) => receiveGenerateTaxPapers(data),
      },
    );

    const taxPaperForm = document.getElementById('js-generate-tax-papers-form');

    if (taxPaperForm) {
      taxPaperForm.onsubmit = (e) => {
        e.preventDefault();

        const { srcElement } = e;
        const xhr = new XMLHttpRequest();

        xhr.open('POST', srcElement.action, true);
        xhr.send(new FormData(srcElement));

        setGenerateTaxPapers('Tax Papers queued for generation.');
      };
    }
  }
});
