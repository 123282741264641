import React from 'react';
import FieldInput from './fields/input';
import FieldArea from './fields/area';
import FieldSelect from './fields/select';
import FieldToggle from './fields/toggle';
import FieldDate from './fields/date';
import { FieldAutocomplete } from './fields/autocomplete-select';
import './field.scss';

const FormField = (props) => {
  switch (props?.type) {
    case 'toggle':
      return <FieldToggle {...props} />;
    case 'area':
      return <FieldArea {...props} />;
    case 'select':
      return <FieldSelect {...props} />;
    case 'autocomplete':
      return <FieldAutocomplete {...props} />;
    case 'date':
      return <FieldDate {...props} />;
    default:
      return <FieldInput {...props} />;
  }
};

export default FormField;
